import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';



const initialState = {
    dailyTourCard: [],
}

export const getDtCard = createAsyncThunk('getDtCard', async() =>{
    const {data} = await axios.get('http://localhost:3001/dailyTourCards')
    return data
})

export const dailyTourCardsSlice = createSlice({
    name: "dailyTourCard",
    initialState,
    reducers :{

    },
    extraReducers: (builder) => {
        builder.addCase(getDtCard.fulfilled, (state, action) => {
            state.dailyTourCard = action.payload;
        })
    }
})


export default dailyTourCardsSlice.reducer