import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LocationOptionsSection = (props) => {
  const { t } = useTranslation();
  const allData = props.props
  const data = props.props.tour_activities;

  const decodedData = atob(allData.cancel_rules);
  const textDecoder = new TextDecoder('utf-8');
  const decodedCancelRules = textDecoder.decode(new Uint8Array(decodedData.split('').map(char => char.charCodeAt(0))));

  const [openDays, setOpenDays] = useState([0]);

  const handleToggle = (index) => {
    setOpenDays((prevOpenDays) => {
      if (prevOpenDays.includes(index)) {
        return prevOpenDays.filter((day) => day !== index);
      } else {
        return [...prevOpenDays, index];
      }
    });
  };




  return (
    <div className='mediaQueryPadding locationOptions' style={{ padding: "80px 100px 280px 100px" }}>
      <div className='row'>
        <div className='col-md-6 col-xs-12 col-sm-12 changeLi'>
          <div className='baslik1 borderbottomdashed pb-2'><span>{t('tourDetail.tourDetails')}</span></div>
          <div>
            {data.map((day, index) => (
              day?.points?.length > 0  ? (
                <div key={index}>
                  <div
                    className='bgorange text-white pt-3 pb-3 mt-2 ps-3 d-flex justify-content-between align-items-center cpointer'
                    onClick={() => handleToggle(index)}
                  >
                    <div>{day.title ? day.title : `${index+1}. Day`}</div>
                    <div className='text-dark fnt08rem pe-2 '><span>{t('tourDetail.clickDetails')}</span> <i className="fas fa-caret-down"></i></div>
                  </div>
                  <div className='mt-3'>
                    <Collapse in={openDays.includes(index)} >
                      <ul>
                        {day.points.map((location, locIndex) => (
                          <li className='mt-4' key={locIndex}>
                            <div className={`${locIndex !== day.points.length - 1 ? 'borderlefdashedAbsoulete' : ''}`}></div>
                            <div className='fnt12rem'>
                              <strong>{location.title}</strong>
                            </div>
                            <div className='mt-2 mb-2'>{location.description}</div>
                            <div className='mt-2 mb-2'>{location.prices}</div>
                            <div className='text-muted'>
                              <small>{location.time}</small>&nbsp;&nbsp; &nbsp;&nbsp;<small>{location.ticketPrice}</small>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Collapse>
                  </div>
                </div>
              ) : null
            ))}
          </div>
          <div>

          </div>



        </div>
        <div className='col-md-6 col-xs-12 col-sm-12'>
          <div className='baslik1 borderbottomdashed pb-2'>{t('tourDetail.cancellationPolicy')}</div>
          <div style={{ marginTop: '3.75rem' }}>
            <div dangerouslySetInnerHTML={{ __html: decodedCancelRules }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationOptionsSection