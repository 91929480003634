import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import FeaturedCard from './featuredcard';
import { Pagination } from 'swiper/modules';
import { useSelector, useDispatch } from 'react-redux'
import { getCommentApi } from './api/comment/commentApiSlice';
import commentAvatar from '../components/images/c1.png';
import LoadingPage from './loadingPage';

import 'swiper/css';
import 'swiper/css/pagination';

const Comment = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.commentApi.commentApi);
  const isLoading = useSelector(state => state.commentApi.isLoading);

  useEffect(() => {
    dispatch(getCommentApi())
  }, []);



  const breakpoints = {
    0: {
      slidesPerView: 1,
    },
    600: {
      slidesPerView: 2,
    },
    1150: {
      slidesPerView: 3,
    },
  };


  if (isLoading) {
    return (<LoadingPage />);
  };

  return (
    <div className='bg-white mediaQueryPadding commentDiv' style={{ padding: "80px 100px 80px 100px" }}>
      <div className="borderlight p-3">
        <div align="center">
          <div className='bg-white coverBorderBgWhite' style={{ width: "12vw" }}>
            <div className='minilogo' style={{ marginTop: "-4vh" }}></div>
          </div>
        </div>
        <div className='row'>

          <div className='col-12'>
            <div className='d-flex align-items-center justify-content-center h-100'>
              <div className='w-100 align-items-center 4.5'>
                <Swiper
                  slidesPerView={3}
                  spaceBetween={0}
                  breakpoints={breakpoints}
                  pagination={true}
                  modules={[Pagination]}
                  className="featuredswiper d-flex align-items-center justify-content-center"
                >
                  {(data.data).map((item) => (
                    <SwiperSlide key={item.id}>
                      <FeaturedCard
                        commentUser={item.full_name}
                        content={item.comment}
                        commentName={item.item_name}
                        commentType={item.type}
                        image={item.featured_image}
                        star={item.rate}
                        type="comment"
                      />
                      <div>{item.star}</div>

                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Comment