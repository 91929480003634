import React, { useRef,useEffect } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../components/header';
import BannerTourDetail from '../components/bannerTourDetail';
import { useTranslation } from 'react-i18next';
import Personalizeyourtour from '../components/personalizeyourtour';
import Localhotels from '../components/localhotelsection';
import Footer from '../components/footer';
import Comment from '../components/comment';
import TourDetailSection from '../components/tourDetailSection';
import TourDetailManipulateSwiper from '../components/tourDetailManipulateSwiper';
import LocationOptionsSection from '../components/locationOptionsSection';
import { useSelector, useDispatch } from 'react-redux'
import { getTourDetailApi } from './api/tourdetail/tourdetailSlice';
import { useParams } from 'react-router-dom';
import LoadingPage from './loadingPage';



function Tourdetail() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {slug} = useParams();
    const data = useSelector(state => state.tourDetailApi.tourDetailApi.data);
    const isLoading = useSelector(state => state.tourDetailApi.isLoading);
    useEffect(()=>{
        dispatch(getTourDetailApi(slug))
     },[dispatch]);
    const targetRef = useRef(null);

    const handleScrollToTarget = () => {
      if (targetRef.current) {
        targetRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start' 
        });
      }
    };
    if(!isLoading){
      document.title =`${(data.featured_title)} | Cappadocia Visitor`
    }

    if(isLoading) {
      return (<LoadingPage/>);
    };
    return (
        <div className="container-fluid w-100 h-100 p-0">
            <Header/>
            <BannerTourDetail onTargetRef={()=> handleScrollToTarget()} propsData={data}/>
            <TourDetailSection props={data}/>
            <TourDetailManipulateSwiper props={data}/>
            <LocationOptionsSection props={data} /> 
            <div className='baslik1 mediaQueryPadding text-white bgblue py-2' style={{ padding: "80px 100px 80px 100px" }} ref={targetRef}>{t('tourDetail.personalize')}</div>
            <Personalizeyourtour props={data}/>
            {/* <BecomeMember/> */}
            {/* <div className='mediaQueryPadding' style={{ padding: "80px 100px 80px 100px" }}>
            <Localhotels />
            </div> */}
            <Comment/>
            <Footer/>
        </div>
    );
}

export default Tourdetail;
