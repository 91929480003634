import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import authRequest from './api/requests';
import LoadingPage from './loadingPage';

const AuthWithGoogle = () => {

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState()

    localStorage.setItem("token", token);
    useEffect(() => {
 
            authRequest("/api/v1/my-accounts", "GET", null, setData, setIsLoading);
        
    }, []);
    useEffect(() => {
 
        if(data){
        const userInfo = data.data;
        delete userInfo.password;
        localStorage.setItem("user", JSON.stringify(userInfo));
        window.location.href = '/';
        }
            

    
}, [data]);

    if (isLoading) {
        return (<LoadingPage />)
    };
    return (
        <LoadingPage />
    )
}

export default AuthWithGoogle