import React, { useEffect, useState } from 'react'
import { getSettingsApi } from './api/settings/settingsApiSlice';
import { useSelector, useDispatch } from 'react-redux'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { useLocation } from 'react-router-dom';
import SearchResultsCollapse from './searchResults';
import { useNavigate } from 'react-router-dom';
import { getSearchApi } from './api/search/searchApiSlice';
import { useParams } from 'react-router-dom';
import HeaderImg from './headerImg';

function Header({ currentDate, setCurrentTerm }) {
  const dispatch = useDispatch()
  const location = useLocation();
  const { slug } = useParams();
  const isSearchPage = location.pathname !== `/search/${slug}`;
  const count = useSelector((state) => state.counter.value)
  const token = localStorage.getItem("token");
  const logOutToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }

  // const [cartLength, setCartLength] = useState(JSON.parse(localStorage.getItem('allCartList')) || []);
  // useEffect(() => {

  //   const updatedCartList = JSON.parse(localStorage.getItem('allCartList')) || [];
  //   setCartLength(updatedCartList.length);

  // }, []);

  useEffect(() => {
    dispatch(getSettingsApi())
  }, [dispatch, slug]);

  const scrollContact = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const [isLoading, setIsLoading] = useState(true);

  const generalSettingsData = useSelector(state => state.settingsApi.generalSettingsApi.data)

  const socialsData = useSelector(state => state.settingsApi.socialsSettingsApi.data)

  useEffect(() => {
    if (generalSettingsData && socialsData) {
      setIsLoading(false);
    }
  }, [generalSettingsData, socialsData]);
  const getValue = (key) => {
    if (!generalSettingsData) {
      return 'Loading...';
    }
    const foundItem = generalSettingsData.find((item) => item.key === key);
    return foundItem ? foundItem.value : 'Not found';
  };

  // *******Search****************





  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [isCollapseOpen, setIsCollapseOpen] = useState(false); // Durum değişkeni collapse'ın açılıp kapanmasını belirler
  const handleChange = (event) => {
    setSearchTerm(event.target.value);

    if (isSearchPage) {
      if (event.target.value !== '') {
        setIsCollapseOpen(true); // Arama alanına bir şey yazıldığında collapse'ı aç
      } else {
        setIsCollapseOpen(false); // Arama alanı boş ise collapse'ı kapat
      }
    } 

  };


  const handleSearch = (event) => {
    event.preventDefault();
    navigate(`/search/${searchTerm}`);
    // Arama işlemini gerçekleştir ve sonuçları güncelle
  };


  const searchResults = useSelector(state => state.searchApi.searchApi.data);
  useEffect(() => {
    if (!isSearchPage) {
      if (searchTerm.length > 1) {
        dispatch(getSearchApi({ slug: `${searchTerm}`, selectDate: currentDate }))
        setCurrentTerm(searchTerm)
        
      }
    } else {
      if (searchTerm.length > 1) {
        dispatch(getSearchApi({ slug: `${searchTerm}&limit=5` }))
      }
    }


  }, [dispatch, searchTerm]);

  // **********************



  if (isLoading) {
    return ""
  };
  return (
    <header>
      <Navbar expand="xl" className='p-md-5 p-3 pb-3 ps-4 pb-md-4 ' >
        <Navbar.Brand style={{ paddingLeft: '5rem' }}>
          <Nav.Link href="/">
            <img className='headerLogo' src={getValue("logo")} alt="logo" />
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNavDropdown" />
        <Navbar.Collapse id="navbarNavDropdown" className='justify-content-end' >
          <Nav className="ml-auto d-flex justify-content-evenly ps-md-5 me-4" >
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/tourlist">Tours</Nav.Link>
            <Nav.Link href="/activities">Activities</Nav.Link>
            <Nav.Link href="/balloontours">Balloon Tours</Nav.Link>
            <Nav.Link href="/transfer">Transfers</Nav.Link>
            <Nav.Link href="/blog">Blogs</Nav.Link>
            <Nav.Link href="/pages/about">About</Nav.Link>
            <div className='headerIcon d-flex  align-items-start'>
            {token ?
              <Dropdown data-bs-theme="dark">
                <Dropdown.Toggle className='navbarDropdown pt-2 nav-link'>
                <HeaderImg type="profile"/>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='/profile'  >
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item href="/" onClick={logOutToken}>
                    Log Out
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
              :
              <Nav.Link href="/login">Join Us / Sign In</Nav.Link>}
            <Nav.Link href="/cart"><div className="position-relative " ><HeaderImg type="cart"/>{count > 0 && <div className='cartCount'><span>{count}</span></div>}</div></Nav.Link>

            <Nav.Link onClick={scrollContact}><HeaderImg type="contact"/></Nav.Link>
            </div>

          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <form onSubmit={handleSearch} className='ps-md-5 pe-md-5 ps-3 pe-3'>
        <div className='w-100 d-flex bannerSearchDiv'>
          <input
            className='bannerSearch'
            type="text"
            onChange={handleChange}
          />
          <button type="submit"><HeaderImg type="search"/></button>
        </div>
        {/* Duruma göre collapse'ı aç veya kapat */}
        {isCollapseOpen && (searchTerm.length) > 1 && <SearchResultsCollapse results={searchResults} className='blueCollapse' />}
      </form>
    </header>
  );
}

export default Header;




// <!DOCTYPE html>
// <html lang="en">
// <head>
//     <?php require_once("import_head.php");?>

// </head>


// <body>
//     <?php require_once("importviews/header.php");?>
//     <div class="maincontainer">
//         <div class="row">
//             <div class="col-3 mb-3" align="left">
//                 <select class="form-control" name="selectedFolder" id="statusecim">
//                     <option value='' selected>Aktif Dosyalar</option>
//                     <option>İhalesi Biten Dosyalar</option>
//                 </select>
//             </div>
//             <div class="col-9 mb-3" align="right">
//                 <button class="btn btn-sm btn-success" onclick="fnExcelReport()"><strong><i class="fas fa-file-excel"></i></strong>&nbsp;&nbsp;Excel'e Dök</button>
//             </div>
//             <div class="col-12">
//                 <table class="table table-sm" >
//                     <thead class="bggreen">
//                         <tr>
//                             <th scope="col">Periyod</th>
//                             <th scope="col">Ana Statü</th>
//                             <th scope="col">Alt Statü</th>
//                             <th scope="col">Sigorta Ş.</th>
//                             <th scope="col">Branş</th>
//                             <th scope="col">Dosya Sorumlusu</th>
//                             <th scope="col">Bölge Sorumlusu</th>
//                             <th scope="col">Bayi Seçimi</th>
//                             <th scope="col">İhale Türü</th>
//                             <th scope="col">Dosya Ara</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td>
//                                 <select class="form-control" name="period">
//                                     <option value='' selected>Yıllık</option>
//                                     <option value='month'>Aylık</option>
//                                     <option value='dayly'>Günlük</option>
//                                 </select>
//                             </td>
//                             <td>
//                                 <select class="form-control" name="parentStatus">
//                                     <option value=''  selected>Tümü</option>

//                                 </select>
//                             </td>
//                             <td>
//                                 <select class="form-control" name="parentSubStatus">
//                                     <option value='' selected>Tümü</option>

//                                 </select>
//                             </td>
//                             <td>
//                                 <select class="form-control" name="insurance">
//                                 <option value='' selected>Tümü</option>

//                                 </select>
//                             </td>
//                             <td>
//                                 <select class="form-control">
//                                     <option value='' selected>Tümü</option>
//                                     <option>Trafik</option>
//                                     <option>Kasko</option>
//                                 </select>
//                             </td>
//                             <td>
//                                 <select class="form-control" name="fileAdministrator">
//                                     <option value='' selected>Tümü</option>

//                                 </select>
//                             </td>
//                             <td>
//                                 <select class="form-control" name="fieldManagers">
//                                     <option value='' selected>Tümü</option>
//                                 </select>
//                             </td>
//                             <td>
//                                 <select class="form-control">
//                                     <option value='' selected>Tümü</option>
//                                     <option>Galeri Park</option>
//                                     <option>Yusuf Oto</option>
//                                 </select>
//                             </td>
//                             <td>
//                                 <select class="form-control" name='tenderTypes'>
//                                     <option value='' selected>Tümü</option>
//                                 </select>
//                             </td>
//                             <td><input type="text" class="form-control" name='search' placeholder="Arama Yapın"/></td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//             <div class="col-12">

//                 <table class="table table-sm table-bordered table-striped" id="yonetimlist">
//                     <thead class="bggreen">
//                         <tr>
//                             <th scope="col">İhale No</th>
//                             <th scope="col">Dosya No</th>
//                             <th scope="col">Ana Statü</th>
//                             <th scope="col">Alt Statü</th>
//                             <th scope="col">İhale Başlangıç T.</th>
//                             <th scope="col">İhale Bitiş T.</th>
//                             <th scope="col">TGS</th>
//                             <th scope="col">Sigorta Ş.</th>
//                             <th scope="col">Branş</th>
//                             <th scope="col">Marka</th>
//                             <th scope="col">Model</th>
//                             <th scope="col">Model Yılı</th>
//                             <th scope="col">Teklif</th>
//                             <th scope="col">Kazanan</th>
//                             <th scope="col">Oran</th>
//                             <th scope="col">Mutabakat</th>
//                             <th scope="col">Belge Türü</th>
//                             <th scope="col">Plaka</th>
//                             <th scope="col">Araç Detayı</th>
//                             <th scope="col">Dosya D.</th>
//                         </tr>
//                     </thead>
//                     <tbody id=operationBody>
//                        <tr>
//                             <td>1553</td>
//                             <td>3186k1248</td>
//                             <td>Satış Engeli</td>
//                             <td>Rehin Borcu</td>
//                             <td>02/01/2024</td>
//                             <td>02/05/2024</td>
//                             <td>18/04/2024</td>
//                             <td>ALLIANZ</td>
//                             <td>Kasko</td>
//                             <td>VOLKSWAGEN</td>
//                             <td>TIGUAN 1.4 TSI ACT BMT 150 DSG HIGHLINE</td>
//                             <td>2018</td>
//                             <td>858.689,00₺</td>
//                             <td>Yusuf Oto</td>
//                             <td>52%</td>
//                             <td>1.518,00₺</td>
//                             <td>Çekme Belgeli</td>
//                             <td>34KCD18</td>
//                             <td>
//                                 <button class="btn btn-sm btn-light text-success"><i class="fas fa-search"></i></button>
//                             </td>
//                             <td>
//                                 <button class="btn btn-sm btn-secondary"><i class="fas fa-cog"></i></button>
//                             </td>
//                        </tr>
//                        <!-- <tr>
//                             <td>2221</td>
//                             <td>4100926482-1</td>
//                             <td>Sigortalı Evrakları Bekleniyor</td>
//                             <td>-</td>
//                             <td>02/01/2024</td>
//                             <td>02/05/2024</td>
//                             <td>18/04/2024</td>
//                             <td>ANADOLU SİGORTA</td>
//                             <td>Kasko</td>
//                             <td>FORD</td>
//                             <td>FIESTA TITANIUM 5K 1.4 TDCi</td>
//                             <td>2012</td>
//                             <td>215.689,00₺</td>
//                             <td>Galeri Park</td>
//                             <td>52%</td>
//                             <td>365.000,00₺</td>
//                             <td>Çekme Belgeli</td>
//                             <td>34KCD18</td>
//                             <td>
//                                 <button class="btn btn-sm btn-light text-success"><i class="fas fa-search"></i></button>
//                             </td>
//                             <td>
//                                 <button class="btn btn-sm btn-secondary"><i class="fas fa-cog"></i></button>
//                             </td>
//                        </tr>
//                        <tr>
//                             <td>12123</td>
//                             <td>110489</td>
//                             <td>Onay Bekleyenler</td>
//                             <td>-</td>
//                             <td>02/01/2024</td>
//                             <td>02/05/2024</td>
//                             <td>18/04/2024</td>
//                             <td>ANADOLU</td>
//                             <td>Kasko</td>
//                             <td>RENAULT</td>
//                             <td>TALIANT JOY 1.0 TURBO X-TRONIC 90</td>
//                             <td>2022</td>
//                             <td>390.000,00₺</td>
//                             <td>Carsell Otomotiv</td>
//                             <td>52%</td>
//                             <td>418.689,00₺</td>
//                             <td>Plakalı</td>
//                             <td>34KCD18</td>
//                             <td>
//                                 <button class="btn btn-sm btn-light text-success"><i class="fas fa-search"></i></button>
//                             </td>
//                             <td>
//                                 <button class="btn btn-sm btn-secondary"><i class="fas fa-cog"></i></button>
//                             </td>
//                        </tr>
//                        <tr>
//                             <td>33321</td>
//                             <td>323423</td>
//                             <td>Sigortalı Evrakları Bekleniyor</td>
//                             <td>-</td>
//                             <td>02/01/2024</td>
//                             <td>02/05/2024</td>
//                             <td>18/04/2024</td>
//                             <td>ALLIANZ</td>
//                             <td>Trafik</td>
//                             <td>FORD</td>
//                             <td>FIESTA TITANIUM 5K 1.4 TDCi</td>
//                             <td>2011</td>
//                             <td>215.689,00₺</td>
//                             <td>Galeri Park</td>
//                             <td>52%</td>
//                             <td>365.000,00₺</td>
//                             <td>Hurda Belgeli</td>
//                             <td>34KCD18</td>
//                             <td>
//                                 <button class="btn btn-sm btn-light text-success"><i class="fas fa-search"></i></button>
//                             </td>
//                             <td>
//                                 <button class="btn btn-sm btn-secondary"><i class="fas fa-cog"></i></button>
//                             </td>
//                        </tr> -->
//                     </tbody>
//                 </table>

//             </div>
//         </div>
//     </div>

//     <?php require_once("import_scripts.php");?>
//     <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/1.6.8/axios.min.js" integrity="sha512-PJa3oQSLWRB7wHZ7GQ/g+qyv6r4mbuhmiDb8BjSFZ8NZ2a42oTtAq5n0ucWAwcQDlikAtkub+tPVCw4np27WCg==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
//     <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js" integrity="sha512-v2CJ7UaYy4JwqLDIrZUI/4hqeoQieOmAZNXBeQyjo21dadnwR+8ZaIJVT8EE2iyI61OV8e6M8PP2/4hpQINQ/g==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
//    <script>

//         $(document).ready(function(){

//             $(".headermenu").removeClass("active");
//             $(".operasyonmenu").addClass("active");


//             getCategories();
//             sendRequest();

 

//         });
//         const bearerToken = localStorage.token;
//         const headers = {
//             'Authorization': `Bearer ${bearerToken}`
//         };
//         const mainUrl = 'https://api.autobuy.com.tr'
//         async function getCategories(){
//             try {
//                 const [
//                     responseInsurance,
//                     responseFilterField,
//                     responseFieldManagers,
//                     responseFieldAdminisrator,
//                     responseUsers
//                 ] = await axios.all([
//                     axios.get("https://api.autobuy.com.tr/insurance", { headers }),
//                     axios.get("https://api.autobuy.com.tr/auction/tender/get-filter-field", { headers }),
//                     axios.get("https://api.autobuy.com.tr/user/get-field-managers", { headers }),
//                     axios.get("https://api.autobuy.com.tr/user/get-file-administrator", { headers }),
//                     axios.get("https://api.autobuy.com.tr/user", { headers })
//                 ]);

//                 const optionsList = [
//                     { name: 'insurance', values: responseInsurance.data.data.insuranceList },
//                     { name: 'parentStatus', values: responseFilterField.data.data.parentStatus },
//                     { name: 'parentSubStatus', values: responseFilterField.data.data.parentSubStatus },
//                     { name: 'fileAdministrator', values: responseFieldAdminisrator.data.data },
//                     { name: 'tenderTypes', values: responseFilterField.data.data.tenderTypes },
//                     { name: 'fieldManagers', values: responseFieldManagers.data.data },
//                     { name: 'users', values: responseUsers.data.data.docs }

//                 ];


//                 $(".form-control").each(function() {
//                     var thisName = $(this).attr("name");
//                     optionsList.forEach(item => {
//                         if (item.name === thisName) {

//                             item.values.forEach(option => {
//                                 if(option.fullName){
//                                     $(this).append(`<option value="${option.id}">${option.fullName}</option>`);
//                                 }else{
//                                     $(this).append(`<option value="${option._id}">${option.name}</option>`);
//                                 }
//                             });
//                         }
//                     });
//                 });
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//          }
        
//         async function sendRequest(){

//         $('#operationBody').empty();
//         var query = "";
//         var isFirst = true;
//         $(".form-control").each(function(){
//             var name = $(this).attr("name");
//             var selected = $(this).val();
//             if(selected !== "tümü" && selected !== "undefined" && selected !== "" && name!== "undefined" ) {
//                 if (!isFirst) {
//                     query += "&";
//                 } else {
//                     isFirst = false;
//                 }
//                 query += name + "=" + selected;
//                 console.log(name + "=" + selected)
//             }
//         });
//         // window.location.href = 'https://panel.autobuy.com.tr/panel/operasyon.php' + query;
//         const apiUrlTenders = 'https://api.autobuy.com.tr/auction/tender/all-tenders?' + query;

//         try {
//                 const response = await axios.get(apiUrlTenders, { headers });
//                 const tenders = response.data.data.tenders;
//                 const categories = response.data.data.categories;



//                 tenders.forEach(item => {
//                     $('#operationBody').append(
//                         `
//                         <tr>
//                           <td>${item.tenderNo}</td>
//                           <td>${item.vehicle.fileNumber}</td>
//                           <td>${item.tenderStatus.name}</td>
//                           <td>Alt Statü</td>
//                           <td>${item.startDate}</td>
//                           <td>${item.endDate}</td>
//                           <td>TGS</td>
//                           <td>Sigorta Şirketi</td>
//                           <td>${item.vehicle.insuranceType}</td>
//                           <td>${item.vehicle.brand}</td>
//                           <td>${item.vehicle.model}</td>
//                           <td>${item.vehicle.modelYear}</td>
//                           <td>${item.winnerOffer}</td>
//                           <td>kazanan</td>
//                           <td>oran</td>
//                           <td>${item.consensusPrice}</td>
//                           <td>${item.tenderKind.name}</td>
//                           <td>${item.vehicle.plate}</td>
//                           <td>
//                             <button class="btn btn-sm btn-light text-success"><i class="fas fa-search"></i></button>
//                           </td>
//                           <td>
//                             <button 
//                               class="btn btn-sm btn-secondary"
//                               onclick="goToPage('https://panel.autobuy.com.tr/panel/vehicle_detail.php?ihaleno=${item.tenderNo}')"
//                             >
//                               <i class="fas fa-cog"></i>
//                             </button>
//                           </td>
//                         </tr>
//                         `
//                     );
//                 });
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }

//        }

//         $(".form-control").change(sendRequest);

//         function goToPage(url) {
//             window.location.href = url;
//         }


//         function fnExcelReport() {
//             var tab_text = "<table border='2px'><tr bgcolor='#87AFC6'>";
//             var j = 0;
//             var tab = document.getElementById('yonetimlist'); // id of table

//             for (j = 0; j < tab.rows.length; j++) {
//                 tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
//                 //tab_text=tab_text+"</tr>";
//             }

//             tab_text = tab_text + "</table>";
//             tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, "");//remove if u want links in your table
//             tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
//             tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

//             var msie = window.navigator.userAgent.indexOf("MSIE ");

//             // If Internet Explorer
//             if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
//                 txtArea1.document.open("txt/html", "replace");
//                 txtArea1.document.write(tab_text);
//                 txtArea1.document.close();
//                 txtArea1.focus();

//                 sa = txtArea1.document.execCommand("SaveAs", true, "Say Thanks to Sumit.xls");
//             } else {
//                 // other browser not tested on IE 11
//                 sa = window.open('data:application/vnd.ms-excel,' + encodeURIComponent(tab_text));
//             }

//             return sa;
//         }
                

//     </script>

// </body>
// </html>