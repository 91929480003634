import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Pagination } from 'swiper/modules';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const TourDetailManipulateSwiper = (props) => {
    const data = props.props.gallery_contents
    return (
        <div className=' swiperVisible swiperManipulateWrapper'>
            <div className="swiperManipulateWrapperBg"></div>
            <div className="swiperManipulate mediaQueryPadding">
                <Swiper
                    className='overflow-visible'
                    slidesPerView={1}
                    pagination={true}
                    modules={[Pagination]}
                    spaceBetween={200}
                >
                    {data.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className='detailsFlexToBlockJoyImg' style={{ display: 'flex' }}>
                                    <LazyLoadImage src={item.image} alt={item.name} className=' bgjoyimage w-100' />
                                <div className='text-white ml-4'>
                                    <div className='d-flex align-items-center h-100'>
                                        <div className='w-100'>
                                            <div style={{ paddingLeft: "40px", width: "80%" }}>
                                                <div className='joybaslik'>{item.title}</div>
                                                <div className='joytext fnt08rem pt-3'>{item.description}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default TourDetailManipulateSwiper