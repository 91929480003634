import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';



const initialState = {
    tourCategories: [],
    isLoading: true
}

export const getTourCategories = createAsyncThunk('getTourCategories', async() =>{
    const {data} = await axios.get(process.env.REACT_APP_API_URL + "/tours/categories")
    return data
})

export const tourCategories = createSlice({
    name: "blogCategoriesTitle",
    initialState,
    reducers :{

    },
    extraReducers: (builder) => {
        builder.addCase(getTourCategories.fulfilled, (state, action) => {
            state.tourCategories = action.payload;
            state.isLoading = false
        }) 
    }
})


export default tourCategories.reducer