import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { Swiper, SwiperSlide } from 'swiper/react';
import FeaturedCard from './featuredcard';
import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';
import { useDispatch } from 'react-redux'

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
const videoBG = "https://cappadociavisitor.com/a.mp4";




function Banner({ type, onShowModal}) {
   
    const dispatch = useDispatch()
  

    //  ***** Home  Banner  Slider ****

    const [selectedLocations, setSelectedLocations] = useState([]);

    const handleCheckboxChange = (location) => {
      const updatedLocations = selectedLocations.includes(location)
        ? selectedLocations.filter((loc) => loc !== location)
        : [...selectedLocations, location];
      setSelectedLocations(updatedLocations);
    };
    const handleListItemClick = (location) => {
        const updatedLocations = selectedLocations.filter((loc) => loc !== location);
        setSelectedLocations(updatedLocations);
      };

    const cards = [
        { id: 1, title: "Don't miss the opportunity to get a 20 % discount on your first holiday !", content: "Torem ipsum dolor sit amet, consectetur adipiscing elit.Torem ipsum dolor sit amet, consectetur adipiscing elit.Torem ipsum dolor sit amet, consectetur adipiscing elit.Torem ipsum dolor sit amet, consectetur adipiscing elit.Torem ipsum dolor sit amet, consectetur adipiscing elit.", addclass: "productbg" },
        { id: 2, title: "Don't miss the opportunity to get a 20 % discount on your first holiday !", content: "Torem ipsum dolor sit amet, consectetur adipiscing elit.Torem ipsum dolor sit amet, consectetur adipiscing elit.Torem ipsum dolor sit amet, consectetur adipiscing elit.Torem ipsum dolor sit amet, consectetur adipiscing elit.Torem ipsum dolor sit amet, consectetur adipiscing elit.", addclass: "productbg" },
        { id: 3, title: "Don't miss the opportunity to get a 20 % discount on your first holiday !", content: "Torem ipsum dolor sit amet, consectetur adipiscing elit.Torem ipsum dolor sit amet, consectetur adipiscing elit.Torem ipsum dolor sit amet, consectetur adipiscing elit.Torem ipsum dolor sit amet, consectetur adipiscing elit.Torem ipsum dolor sit amet, consectetur adipiscing elit.", addclass: "productbg" },
    ];
// ********* HomeBannerSlider *********



    // ****** Dummy Data ******
      const  dummyData = [
            {categories:'Horse Riding Tour1'},
            {categories:'Horse Riding Tour2'},
            {categories:'Horse Riding Tour3'},
            {categories:'Horse Riding Tour4'},
        ]
    // *********************
    const [startDate, setStartDate] = useState(new Date());

    // ***** set tourDetail ****



 if (type === 'product') {
        return (
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                pagination={true}
                navigation={true}
                modules={[Pagination,Navigation]}
                className='productBanner'
            >
                {cards.map((card) => (
                    <SwiperSlide key={card.id}>
                        <FeaturedCard content={card.content} title={card.title} addclass={card.addclass} type="productBanner" />
                    </SwiperSlide>
                ))}

            </Swiper>
        )

    }  else if (type === 'about') {
        return (
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                pagination={true}
                navigation={true}
                modules={[Pagination,Navigation]}
                className='aboutBanner'
            >
                {cards.map((card) => (
                    <SwiperSlide key={card.id}>
                        <FeaturedCard content={card.content} title={card.title} addclass={card.addclass} type="aboutBanner" />
                    </SwiperSlide>
                ))}

            </Swiper>
        )

    }
}


export default Banner;
