import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import authRequest from './api/requests';
import ShareModal from './shareModal';
import LoadingPage from './loadingPage';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';


const TourDetailSection = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { slug } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const personalizeData = useSelector(state => state.tourDetailInfo)
    const [showShare, setShowShare] = useState(false);
    const dataTd = props.props
    const [dataCalcPrice, setDataCalcPrice] = useState()
    const { tour_id } = useSelector((state) => state.tourDetailInfo);
    const toggleModal = () => setShowShare(!showShare);
    function b64DecodeUnicode(str) {
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }
    useEffect(() => {
        setIsLoading(true);
        if (tour_id) {
            authRequest("/api/v1/tours/calc", "POST", personalizeData, setDataCalcPrice, setIsLoading);
        }
    }, [personalizeData]);
    if (isLoading) {
        return (<LoadingPage />)
    };
    return (
        <div className='tourDetailSection'>
            <div style={{ paddingTop: "15%" }}>
                <div className='d-flex blueAndOrangeDiv'>
                    <div className='bgblue ' style={{ flexGrow: "1", padding: "10px 100px" }}>
                        <div className='text-white baslik1'>{dataTd.title}</div>
                    </div>
                    {dataCalcPrice.data.disabled ? "" :
                        <div className='bgorange position-relative' style={{ padding: '0 180px 0 3rem' }}>
                            <div className='d-flex h-100 mqStartAlign' style={{ alignItems: 'center' }}>
                                <div className='w-100 text-white'>
                                    {dataCalcPrice.data.avaible_date ? 
                                    <div>
                                    <span className='fnt10rem underlinetext'>{dataCalcPrice.data.avaible_date.price} Eur</span>
                                    <span className='baslik1 text-white px-2'>{dataCalcPrice.data.one_person_price} EUR</span>
                                    <span className='fnt07rem'>{dataTd.per_type.per_text}</span>
                                    </div>
                                    : <span className='fnt10rem underlinetext'>{t('tourDetail.notAvailable')}</span>}
                                    
                                </div>

                                <div className='removeimg'></div>
                            </div>

                        </div>}

                </div>
            </div>

            <div className='mediaQueryPadding carbonMq' style={{ padding: "80px 100px 80px 100px" }}>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <span className='cllightblue'><i className="fas fa-clock fnt12rem"></i><span className='px-1 text-dark'>{dataTd.total_event_time}</span></span>
                        {dataTd.total_transport_time &&
                            <span className='cllightblue px-4'><i className="fas fa-car fnt12rem"></i><span className='px-1 text-dark'>{dataTd.total_transport_time}</span></span>
                        }
                    </div>
                    <div className='pe-md-3'>
                        <button className='customButton' onClick={() => toggleModal()} >
                            {
                                !showShare &&
                                <div className='colorBlue'>
                                    <i className="fas fa-share  pe-2" />
                                    <span className='fw-bold'>{t('tourDetail.shareTour')}</span>
                                </div>
                            }
                        </button>
                    </div>

                </div>
                <div className='mt-4'><small>{t('tourDetail.overview')}</small></div>
                <div className='mt-4'>{dataTd.description}</div>
                <div className='mt-5'>
                    <div className='row detailsFlexToBlock'>
                        <div className='col-md-6 col-sm-12 col-xs-12 '>
                            <div className='baslik1 borderbottomdashed pb-2'>{t('tourDetail.details')}</div>
                            <div className='mt-5' dangerouslySetInnerHTML={{ __html: b64DecodeUnicode(dataTd.tour_details) }} />
                        </div>
                        <div className='col-md-6 col-sm-12 col-xs-12'>
                            <div id='knowBaslik1' className='baslik1 borderbottomdashed pb-2'>{t('tourDetail.KnowAbout')} {dataTd.title}</div>
                            <div className='mt-5' dangerouslySetInnerHTML={{ __html: b64DecodeUnicode(dataTd.tour_about) }} />

                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showShare} onHide={toggleModal} centered>
                <Modal.Body className='pb-5' closeButton>
                    <ShareModal slug={slug}  closeButton={toggleModal} title={dataTd.title}/>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default TourDetailSection