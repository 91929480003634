import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationCN from './locales/cn/translation.json';


i18n

  .use(initReactI18next) // react-i18next ile entegrasyon için
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: translationEN
      },
      es: {
        translation: translationES
      },
      cn: {
        translation: translationCN
      }
    },
    //lng eğer var ise onu al yoksa en al
    lng: localStorage.getItem('language') || 'en',
    fallbackLng: 'en', // geçerli dil bulunamazsa yedek dil
    interpolation: {
      escapeValue: false // React zaten xss koruması sağlar
    }
  });

export default i18n;
