import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  pickupLocations: [],
  returnLocations: [],
  avaibleDatesCalendar: [],
  avaiblesTimes: [],
  total_data: [],
  selectedType:[],
  prices:[],
  isLoading: true,
};

export const getTransferApi = createAsyncThunk('getTransferApi', async (postData) => {
    const {data} = await axios.post(process.env.REACT_APP_API_URL + '/transfers/info', postData);
  const pickup_locations = data.infos.pickup_locations;
  const return_locations = data.infos.return_locations;
  const avaibles_times = data.infos.avaibles_times;
  const avaible_dates_calendar = data.infos.avaible_dates_calendar;
  const prices = data.infos.prices;
  const total_data = data.infos.total_data;
  const transferCars = data.infos.cars;
  const selectedType = data.infos.selected_transfer_group;
  const products = data.infos.products;
  return { pickup_locations, return_locations, avaibles_times, avaible_dates_calendar, total_data, transferCars, products, selectedType, prices };
});

export const transferApiSlice = createSlice({
  name: 'transferApi',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
builder.addCase(getTransferApi.fulfilled, (state, action) => {
  state.prices = action.payload.prices;
  state.pickupLocations = action.payload.pickup_locations;
  state.returnLocations = action.payload.return_locations; 
  state.avaibleDatesCalendar = action.payload.avaible_dates_calendar; 
  state.avaiblesTimes = action.payload.avaibles_times; 
  state.total_data = action.payload.total_data;
  state.transferCars = action.payload.transferCars;  
  state.products = action.payload.products;  
  state.selectedType = action.payload.selectedType; 
  state.isLoading = false;
  
});
  },
});

export default transferApiSlice.reducer;
