import React from 'react'
import Header from './header'
import Comment from './comment'
import Footer from './footer'
import ReviewSection from './reviewSection';

const Review = () => {
    document.title ="Review | Cappadocia Visitor";
    return (
      <div className="container-fluid w-100 h-100 p-0">
      <Header/>
      <ReviewSection/>
      <Footer/>
      </div>
  
    )
}

export default Review