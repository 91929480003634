import React, { useEffect } from 'react'
import Header from './header'
import BlogDetail from './blogDetail'
import Comment from './comment'
import Footer from './footer'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import LoadingPage from './loadingPage';
import { getBlogDetailApi } from './api/blogdetail/blogdetailSlice'
import { getBlogCategoriesTitle } from './api/blogdetail/blogCategoriesTitleSlice';


const BlogDetailPage = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.blogDetailApi.blogDetailApi.data);
  const categoriesTitleList = useSelector(state => state.blogCategoriesTitle.blogCategoriesTitle.data);
  const {slug} = useParams();
  document.title =`${data ?.title} | Cappadocia Visitor`;
  const isLoading = useSelector(state => state.blogDetailApi.isLoading);
  useEffect(()=>{
      dispatch(getBlogDetailApi(slug))
      dispatch(getBlogCategoriesTitle())
   },[dispatch, slug]);
   

  if(isLoading) {
    return (<LoadingPage/>);
  };
  return (
    <div className="container-fluid w-100 h-100 p-0">
    <Header/>
    <BlogDetail props={data} propsCategories={categoriesTitleList}/>
    <Comment/>
    <Footer/>
    </div>

  )
}

export default BlogDetailPage