import React from 'react'
import Header from './header'
import BlogSection from './blogSection'
import Comment from './comment'
import Footer from './footer'



const BlogPage = () => {
  
  document.title ="Blogs | Cappadocia Visitor";
  return (
    <div className="container-fluid w-100 h-100 p-0">
    <Header/>
    <BlogSection/>
    <Comment/>
    <Footer/>
    </div>

  )
}

export default BlogPage