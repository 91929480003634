import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HomeSlider from './homeSlider';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';




const BannerHome = (propsData) => {
    const { t } = useTranslation();

    const [startDate, setStartDate] = useState(new Date());

    const [isLoading, setIsLoading] = useState(true);

    // **********HomeBanner*********
    const data = propsData.propsData;
    const sliderData = data.datas[0].sub_medias
    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);


    // *******Search******
    const [searchTerm, setSearchTerm] = useState();
    const [searchDate, setSearchDate] = useState();
    const navigate = useNavigate();
    
    const changeValue = (event) => {
        setSearchTerm(event.target.value)
    }

    const searchForm = (event) => {
        event.preventDefault();
        navigate(`/search/${searchTerm}`, { state: { propsDate: searchDate } });
    }

    const formatedDate = (date) => {
        const fixedDate = date.toLocaleDateString('en-CA'); // y-m-d formatında tarih dönüşümü
        setSearchDate(fixedDate)
    };

    // ************


    // *******decode******
    const decodedData = atob(data.description);
    const textDecoder = new TextDecoder('utf-8');
    const decodedDescription = textDecoder.decode(new Uint8Array(decodedData.split('').map(char => char.charCodeAt(0))));
    // ************

    //  ***** Home  Banner  Slider ****

    // ********* HomeBannerSlider *********


    if (isLoading) {
        return ""
    };
    return (

        <div className="bannerbg mqIndexBannerBg" style={{ backgroundImage: `url(${data.datas[0].image})` }}>
            <div className='bannerbglightbg'>
                <div className="d-flex mqChangeCenter" style={{ alignItems: 'center' }}>
                    <div className="w-100">
                        <div className='row'>
                            <div className='col-md-6 col-xs-12 col-sm-12 mediaHeaderW50'>
                                <div style={{ paddingBottom: "7vh" }}>
                                    <div className="bannerbaslik colorblue">{data.title}</div>
                                    <div className='mt-3 fnt18px colorblue' style={{ maxWidth: "800px", lineHeight: '25px', fontWeight: '600' }}>{decodedDescription}</div>
                                </div>
                                <div className="col-md-8  bannersearch pt-3 pb-3 px-5">
                                    <form onSubmit={searchForm}>
                                        <div className="row">
                                            <div className="col-md-12 col-xs-12 col-sm-12 pb-2 position-relative">
                                                <div className="mb-1 mt-2 text-white"><i className="fas fa-map-marker-alt"></i><span className="px-2 fnt14px">{t('anywhere')}</span></div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="bannerinput"
                                                        placeholder={t('takeYou')}
                                                        onChange={changeValue}
                                                    />
                                                </div>

                                            </div>

                                            <div className="col-md-12 col-xs-12 col-sm-12 pb-3">
                                                <div className="mb-1 mt-2 text-white"><i className="fas fa-calendar"></i><span className="px-2 fnt14px">{t('date')}</span></div>
                                                <div>
                                                    <DatePicker
                                                        selected={searchDate ? new Date(searchDate) : null}
                                                        className="bannerinput"
                                                        onChange={(date) => formatedDate(date)}
                                                        minDate={new Date()}
                                                        onFocus={(e) => e.target.blur()}
                                                        timeIntervals={15}
                                                        timeCaption="time"
                                                        dateFormat="MMMM d, yyyy"
                                                        placeholderText={t('validation.selectDate')}
 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-xs-12 col-sm-12">
                                                <div className='d-flex align-items-center h-100'>
                                                    <div className='w-100' align="center">
                                                        <button type="submit" className='btn bg-white homeBannerBtn ' style={{ borderRadius: "60px", fontWeight: "600", width: '50%' }}>{t('search')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='col-md-8 col-xs-12 col-sm-12 mediaHeaderW50' align="right">
                                <HomeSlider data = {sliderData}/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}


export default BannerHome