import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';



const initialState = {
    blogCategoryApi: [],
    isLoading: true
}

export const getBlogCategoryApi = createAsyncThunk('getBlogCategoryApi', async ({ slug, currentPage }) => {
    const { data } = await axios.get(process.env.REACT_APP_API_URL + `/blogs?category=${slug}&page=${currentPage}`);
    
    return data;
  });

export const blogCategoryApiSlice = createSlice({
    name: "blogCategoryApi",
    initialState,
    reducers :{

    },
    extraReducers: (builder) => {
        builder.addCase(getBlogCategoryApi.fulfilled, (state, action) => {
            state.blogCategoryApi = action.payload;
            state.isLoading = false
        }) 
    }
})


export default blogCategoryApiSlice.reducer