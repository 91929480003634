import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


const RegisterOptionModal = ({ show, handleClose }) => {
    const { t } = useTranslation();
    return (
        <Modal show={show} onHide={handleClose} centered  >
            <Modal.Header closeButton>
                <Modal.Title>{t('signUp.register')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='pb-3'>
                    <div className='p-2'>
                        <button type="button" class="btn btn-danger w-100">
                        <Link to={process.env.REACT_APP_API_URL + '/auth/google'} className='custom-link'>
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='borderright1'>
                                        <i class="fa-solid fa-g"></i>
                                    </div>
                                </div>
                                <div className='col-10'>
                                {t('signUp.registerGoogle')}
                                </div>
                            </div>
                            </Link>
                        </button>
                    </div>
                    <div className='p-2'>
                        <button type="button" class="btn btn-secondary w-100">
                            <Link to={'/register'} className='custom-link'>
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='borderright1'>
                                        <i class="fa-regular fa-envelope"></i>
                                    </div>
                                </div>
                                <div className='col-10'>
                                {t('signUp.registerEmail')}
                                </div>
                            </div>
                            </Link>

                        </button>
                    </div>
                </div>

            </Modal.Body>

        </Modal>
    )
}

export default RegisterOptionModal