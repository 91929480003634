import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';


const token = localStorage.getItem("token") || "";
const reqHeader = token ? { Authorization: `Bearer ${token}` } : {};

const options = {
  headers: reqHeader
};




const initialState = {
    searchApi: [],
    isLoading: true
}

export const getSearchApi = createAsyncThunk('getSearchApi', async({slug, selectDate=null}) =>{
    if (selectDate){
        const {data} = await axios.get(process.env.REACT_APP_API_URL + `/tours/list?term=${slug}?date=${selectDate}`, options)
        return data
    }else {
        const {data} = await axios.get(process.env.REACT_APP_API_URL + `/tours/list?term=${slug}`, options)
        return data
    }

})

export const searchApiSlice = createSlice({
    name: "searchApi",
    initialState,
    reducers :{

    },
    extraReducers: (builder) => {
        builder.addCase(getSearchApi.fulfilled, (state, action) => {
            state.searchApi = action.payload;
            state.isLoading = false
        }) 
    }
})


export default searchApiSlice.reducer