import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';



const initialState = {
    generalSettingsApi: [],
    isLoading: true

}

export const getGeneralSettingsApi = createAsyncThunk('getGeneralSettingsApi', async() =>{
    const {data} = await axios.get(process.env.REACT_APP_API_URL + '/settings')
    const  filteredData = (data.data).find(item => item.key === 'generals');
    return filteredData
})

export const generalSettingsApiSlice = createSlice({
    name: "generalSettingsApi",
    initialState,
    reducers :{

        
          
    },
    extraReducers: (builder) => {
        builder.addCase(getGeneralSettingsApi.fulfilled, (state, action) => {
            state.generalSettingsApi = action.payload;
            state.isLoading = false

        })
    }
})


export default generalSettingsApiSlice.reducer