import React from 'react'
import imagesWarning from './images/warning.png'
import { useTranslation } from 'react-i18next';

const WarningModal = () => {
  const { t } = useTranslation();

  return (
       
                <div className=' h-100 pt-5'>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src={imagesWarning} alt='failedImg' className='imgPaymentResponse' />
                </div>
                <div className='text-center'>
                    <h1 className='text-danger fw-bold pt-5'>{t('paymentStatus.warning')}</h1>
                    <p className='fw-bold pt-4 fnt18rem' style={{color:'#eb8079'}}>{t('paymentStatus.paymentFailed')}</p>
                    <span className='fnt07rem'>{t('paymentStatus.supportInfo')}</span>
                </div>
                </div>
   



  )
}

export default WarningModal