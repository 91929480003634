import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ShareModal from "./shareModal"
import authRequest from './api/requests';
import Featuresection from './featuredsection';
import { Swiper, SwiperSlide } from 'swiper/react';
import FeaturedCard from './featuredcard';
import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';
import LoadingPage from './loadingPage';
import Col from 'react-bootstrap/Col';
import NavbarImg from "./navbarImg";
import TokenModal from './alerts/tokenModal';
import { useTranslation } from 'react-i18next';


const FavSection = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [errorData, setErrorData] = useState();
    const [showError, setShowError] = useState(false)
    const [favList, setFavList] = useState();
    const breakpoints = {
        0: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        600: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        1150: {
            slidesPerView: 4,
            spaceBetween: 10,
        },
    };
    const closeModal = () => {
        localStorage.removeItem("token");
        setShowError(false);
        window.location.href = '/login';
      }
    useEffect(() => {

        authRequest("/api/v1/my-accounts/favorites", "GET", null, setFavList, setIsLoading, setErrorData);

    }, []);
    useEffect(() => {
        if (errorData) {
          setShowError(true);
        } else {
          setShowError(false)
        }
      }, [errorData]);

    function numberToWords(number) {
        const units = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
        const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
        const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

        if (number < 10) return units[number];
        if (number < 20) return teens[number - 10];
        if (number < 100) return tens[Math.floor(number / 10)] + (number % 10 !== 0 ? '-' + units[number % 10] : '');
        if (number < 1000) return units[Math.floor(number / 100)] + ' hundred' + (number % 100 !== 0 ? ' and ' + numberToWords(number % 100) : '');

        return 'Number is out of range'; // Bu basit fonksiyon sadece 0 ile 999 arasındaki sayıları işler
    }

    if (isLoading) {
        return <LoadingPage />
    };
    return (
        <div>
            <div className="bgblue w-100">

                <div className="row">
                    <Col md={9} sm={12} xs={12}>
                        <div className="text-white productNavDivL pe-md-5 me-md-5" style={{ display: 'flex', height: '64px', justifyContent: 'space-between' }}>
                            <div className='cart'>
                                <div className="productBtn padProduct overflow-hidden d-flex align-items-center h-100" >

                                    <div className="ms-2 h1" style={{ color: 'white' }}>{t('favorite.favoriteList')}</div>
                                </div>
                            </div>


                        </div>
                    </Col>


                </div>
            </div>
            <div className='proCartDiv d-flex align-items-center padProduct mb-md-5' style={{ width: '500px', marginTop: '3rem' }}>
                <div className='mx-4 clrGray p-3'>{t('cart.thereAre')} {numberToWords(favList?.data?.length)} {t('favorite.itemsIn')}</div>
            </div>
            <div className='homeFeaturesection mediaQueryPadding' style={{ padding: "0px 100px 80px 100px" }}>
                {favList?.data?.length > 0 ? 
                                <Swiper
                                slidesPerView={4}
                                spaceBetween={0}
                                breakpoints={breakpoints}
                                className="featuredswiper"
                                pagination={true}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                            >
                                {(favList?.data).map((card, index) => (
                                    <SwiperSlide key={index}>
                                        <FeaturedCard
                                            title={card.tour.title}
                                            content={card.tour.get_type.title}
                                            price={card.tour.price}
                                            saleprice={card.tour.total_price}
                                            star={card.tour.star}
                                            image={card.tour.featured_file}
                                            slug={card.tour.slug}
                                            tourId={card.tour.id}
                                            isFavorited={card.tour.favorited}
            
                                            type="feature"
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                : 
                <div>
            {errorData && <TokenModal props={errorData} status={showError} closeModal={closeModal} />}

                <h2 className="text-center">{t('favorite.empty')}</h2>
                </div>
                }

            </div>

        </div>

    )
}

export default FavSection
