import React from 'react'
const BannerPages = (props) => {
const data=props.props
  return (
    <div className='pagesBanner'>
        <img className='pagesImg' src={data.cover_image} />
        <div className="d-flex align-items-center h-100">
            <div className="w-100">
            <div className='becomeDiv'>
                <div className='bannerbaslik'>{data.featured_title}<br/></div>
                <div className='mt-4 text-white fnt12px' style={{maxWidth:"800px"}}>{data.featured_desc}</div>
            </div>
            </div>
        </div>
    </div>
    )
}

export default BannerPages


