import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import authRequest from '../api/requests';
import Select from 'react-select';
import LoadingPage from '../loadingPage';
import UpdateModal from '../alerts/updateModal';
import { useTranslation } from 'react-i18next';


const PersonalInfoModal = (props) => {
    const { t } = useTranslation();
    const data = props.props;
    const [startDate, setStartDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [geoCodes, setGeoCodes] = useState(null);
    const [countries, setCountries] = useState(null);
    const [modalStatus, setModalStatus] = useState(false);

    const closeModal = () => {
        setModalStatus(false);
        window.location.reload();
    }

    const [personalInfo, setPersonalInfo] = useState({
        name: data?.name,
        surname: data?.surname,
        fullName: `${data?.name} ${data?.surname}`,
        country_id: data?.country_id,
        gender: data?.gender,
        birthday: data?.birthday,
        email: data?.email,
        geo_code: data?.geo_code,
        phone: data?.phone,
        passaport_no: data?.passaport_no,
    })

    const [responseData, setResponseData] = useState();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setPersonalInfo({
            ...personalInfo,
            [name]: value,
        });
    };

    const handleBirthDate = (date) => {
        const formattedDate = date.toLocaleDateString('en-CA'); // y-m-d formatında tarih dönüşümü
        setPersonalInfo({
            ...personalInfo,
            birthday: formattedDate,
        });
    };
    
    const handleSelectChange = (selectedOption, selectName) => {
        if (selectName === "country_id") {
            setPersonalInfo({
                ...personalInfo,
                country_id: selectedOption.value,
            });
        } else if (selectName === "geo_code") {
            setPersonalInfo({
                ...personalInfo,
                geo_code: selectedOption.value,
            });
        }
    };

    useEffect(() => {
        authRequest("/api/v1/definations/countries", "GET", null, setCountries, setIsLoading);
        authRequest("/api/v1/definations/geo-codes", "GET", null, setGeoCodes, setIsLoading);
    }, []);

    const postData = () => {
        authRequest("/api/v1/my-accounts/update", "POST", personalInfo, setResponseData, setIsLoading);

    }

    useEffect(() => {
        if (responseData) {
            setModalStatus(true)
        }
    }, [responseData]);
    
    const current_geo_value = geoCodes?.data?.find(item => item.code === data?.geo_code);
    if (!countries) {
        return (
            <LoadingPage />
        )
    }
    if (!geoCodes) {
        return (
            <LoadingPage />
        )
    }
    return (
        <div className='personalInfo'>
            <div className='proCartDiv d-flex align-items-center padProduct' style={{ width: '700px', marginTop: '3rem' }}>
                <div className='h1 mqFs14rem'>{t('profilePage.personalInfo')}</div>
                <div className='mx-4 clrGray'>{t('profilePage.enterPersonalInfo')}</div>
            </div>

{
    modalStatus && <UpdateModal status={modalStatus} close={closeModal} props={responseData} />
}       

            <div className='row '>
                <Col md={9} sm={12} xs={12}>
                    <div className='padProduct pe-3'>
                        <div className='row'>
                            <div className='bordertopdashed mt-1'>
                                <Col md={10} sm={12} xs={12}>
                                    <div className='row'>
                                        <div className='col-md-6 col-sm-12 col-xs-12 mt-4'>
                                            <div className='mt-1'>
                                                <div>{t('registerForm.name')}</div>
                                                <div className='mt-2'>
                                                    <input type='text' className='rezervationinput w-100' placeholder='Thomas Edison' value={data && personalInfo?.name} name='name' onChange={handleChange} />
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <div>{t('gender')}</div>
                                                <div className='mt-2'>
                                                    <select className='w-100 h-100 modalselect'
                                                        value={personalInfo?.gender}
                                                        name='gender'
                                                        onChange={handleChange}
                                                        required>
                                                        <option disabled hidden>{data.gender && data.gender}</option>
                                                        <option value='WOMAN'>{t('profilePage.capitalFemale')}</option>
                                                        <option value={"MALE"}>{t('profilePage.capitalMale')}</option>
                                                        <option value={"OTHER"}>{t('profilePage.capitalOther')}</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-md-6 col-sm-12 col-xs-12 mt-4'>
                                            <div className='mt-1'>
                                                <div>{t('registerForm.surname')}</div>
                                                <div className='mt-2'>
                                                    <input type='text' className='rezervationinput w-100' placeholder='Thomas Edison' value={data && personalInfo?.surname} name='surname' onChange={handleChange} />
                                                </div>
                                            </div>
                                            <div className='mt-3'>{t('registerForm.birthDate')}</div>
                                            <div className='mt-2'>
                                                <DatePicker
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={100}
                                                    maxDate={new Date()}

                                                    selected={startDate}
                                                    className="modalSelectDate"
                                                    onChange={(date) => {
                                                        setStartDate(date);
                                                        handleBirthDate(date);

                                                    }}
                                                    timeIntervals={15}
                                                    timeCaption="time"
                                                    dateFormat="MMMM d, yyyy"
                                                    value={personalInfo.birthday ? personalInfo.birthday : "Please Select Date"}

                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className='mt-3'>{t('registerForm.nationality')}</div>
                                    <Select
                                        className='w-100 mt-2 fnt08rem '
                                        onChange={(selectedOption) => handleSelectChange(selectedOption, "country_id")}
                                        defaultValue={{ label: data?.country?.name, value: personalInfo?.country_id }}
                                        options={countries.data.map((country, index) => ({
                                            value: country.id,
                                            label: country.country,
                                        }))}
                                    />
                                    <div className='mt-3'>
                                        <div>{t('registerForm.email')}</div>
                                        <div className='mt-2'>
                                            <input
                                                type='text'
                                                className='rezervationinput w-100'
                                                placeholder='Thomasedison@gmail.com'
                                                name="email"
                                                onChange={handleChange}
                                                value={data && personalInfo?.email}
                                            />
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-12 mb-2'>{t('profilePage.phoneNumber')}</div>
                                        <div className='col-4'>

                                            <Select
                                                onChange={(selectedOption) => handleSelectChange(selectedOption, "geo_code")}
                                                defaultValue={{ label: current_geo_value?.value, value: current_geo_value?.code }}
                                                options={geoCodes.data.map((item, index) => ({
                                                    value: item.code,
                                                    label: item.value,
                                                }))}
                                            />

                                        </div>
                                        <div className='col-8 ps-0'>
                                            <input
                                                type='text'
                                                placeholder='Country Code'
                                                className='w-100 rezervationinput'
                                                value={data?.phone && personalInfo?.phone}
                                                name='phone'
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className='mt-3'>
                                        <div>{t('transfer.passportOrTCNo')}</div>
                                        <div className='mt-2'>
                                            <input
                                                type='text'
                                                className='rezervationinput w-100'
                                                placeholder='ABA9875413'
                                                value={data && personalInfo?.passaport_no}
                                                name='passaport_no'
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '3rem', paddingBottom: '10rem' }}><button className='btn bgblue personalizeBtn text-white w-100 p-3' style={{ borderRadius: "60px", fontWeight: "600", fontSize: "18px" }} onClick={postData}>{t('registerForm.save')}</button></div>
                                </Col>
                            </div>
                        </div>
                    </div>

                </Col>
            </div>

        </div>
    );
}

export default PersonalInfoModal