// src/api/formValueState/formValueSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  guests: []
};

const formValueSlice = createSlice({
  name: 'formValueInfo',
  initialState,
  reducers: {
    setFormValue: (state, action) => {
      state.guests = action.payload;
    },
    updateGuest: (state, action) => {
      const { index, field, value } = action.payload;
      state.guests[index][field] = value;
    },
    initializeGuests: (state, action) => {
      state.guests = action.payload;
    }
  }
});

export const { setFormValue, updateGuest, initializeGuests } = formValueSlice.actions;
export default formValueSlice.reducer;
