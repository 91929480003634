import Header from '../components/header';
import HomeBanner from '../components/bannerHome';
import Featuresection from '../components/featuredsection';
import Nowopentours from '../components/nowopentours';
import Localfinds from '../components/localfindssection';
import Localhotels from '../components/localhotelsection';
import Footer from '../components/footer';
import Comment from '../components/comment';
import TakeYouSection from '../components/takeYouSection';
import BecomeMember from '../components/becomeMember';
import { useSelector, useDispatch } from 'react-redux'
import { getPageApi } from './api/pageApi/pageApiSlice';
import { useEffect } from 'react';
import LoadingPage from './loadingPage';


function Home() {
  const dispatch = useDispatch();
  const data = useSelector(state => state.pageApi.pageApi.data);
  const isLoading = useSelector(state => state.pageApi.isLoading);
  const token = localStorage.getItem("token") || "";
  console.log(data);
  useEffect(() => {
    dispatch(getPageApi("home"))
  }, [dispatch]);

  const renderSection = (section) => {
    switch (section.section_type.value) {
      case 'FEATURED_BANNER':
        return <HomeBanner propsData={section} />;
      case 'CARDS_REACTANGLE':
        return <div className='homeFeaturesection mediaQueryPadding' style={{ padding: "0px 100px 80px 100px" }}><Featuresection propsData={section} /></div>;
      case 'CARDS_SQUARE':
        return <div className='mediaQueryPadding' style={{ padding: "0px 100px 80px 100px" }}><Nowopentours propsData={section} /></div>;
      case 'BANNER_SECTION':
        return <TakeYouSection propsData={section} />;
      case 'CARDS_BIG_REACTANGLE':
        return section && section.datas && section.datas.length > 0 ? (
          <div className='mediaQueryPadding' style={{ padding: "150px 100px 150px 100px" }}>
            <Localfinds propsData={section} />
          </div>
        ) : null;
      case 'REGISTER_SECTION':
        return token ? null : <BecomeMember propsData={section} />;
      case 'CARDS_BIG_BG_REACTANGLE':
        return <div className='mediaQueryPadding' style={{ padding: "120px 100px 120px 100px" }}><Localhotels propsData={section} /></div>;
      default:
        return null;
    }
  };


  if (isLoading) {
    return (<LoadingPage />);
  };
  return (
    <div className="container-fluid w-100 h-100 p-0">

      <Header />
      {data.map((section, index) => (
        <div key={index} style={{ paddingTop: index === 1 ? '150px' : '' }}>
          {renderSection(section)}
        </div>
      ))}
      <Comment />
      <Footer />

    </div>
  );
}

export default Home;
