import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const token = localStorage.getItem("token") || "";
const reqHeader = token ? { Authorization: `Bearer ${token}` } : {};

const options = {
  headers: reqHeader
};



const initialState = {
    homeApi: [],
    isLoading: true

}

export const getHomeApi = createAsyncThunk('getHomeApi', async() =>{
    const {data} = await axios.get(process.env.REACT_APP_API_URL + '/template/home', options)
    return data
})

export const homeApiSlice = createSlice({
    name: "homeApi",
    initialState,
    reducers :{

    },
    extraReducers: (builder) => {
        builder.addCase(getHomeApi.fulfilled, (state, action) => {
            state.homeApi = action.payload;
            state.isLoading = false

        })
    }
})


export default homeApiSlice.reducer