import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RegisterForBuy from './registerForBuy';
import authRequest from './api/requests';
import LoadingPage from './loadingPage';
import { setProducts } from './api/tourDetailInfo/tourDetailInfoSlice';
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';


const PaymentModal = (props) => {
    const dispatch = useDispatch()

    const { t } = useTranslation();
    const data = props.props
    const personalizeData = useSelector(state => state.tourDetailInfo)
    const [selectedProducts, setSelectedProductes] = useState(personalizeData.products)
    const [isLoading, setIsLoading] = useState(true);
    const { tour_id } = useSelector((state) => state.tourDetailInfo);
    const [dataCalcPrice, setDataCalcPrice] = useState()
    useEffect(() => {
        setIsLoading(true);
        if (tour_id) {
            authRequest("/api/v1/tours/calc", "POST", personalizeData, setDataCalcPrice, setIsLoading);
        }

    }, [personalizeData]);

    const handleDelete = (id) => {
        const newState = selectedProducts.filter((item) => item !== id);
        setSelectedProductes(newState);
    };


    useEffect(() => {
        dispatch(setProducts(selectedProducts));
    }, [selectedProducts])
    if (isLoading) {
        return (<LoadingPage />)
    };
    return (
        <div className='row'>
            <div className='col-md-4 col-sm-12 col-xs-12 paymentModalMQCol'>
                <div className='bannersearch text-white bgblue p-5 h-100'>
                    <div className='fnt14rem'>{t('paymentModal.resDetail')}</div>
                    <div className='mt-3 borderdashed pb-4'>
                        <div className='fnt10rem mqModalFsize'>{data.featured_title}</div>
                        <div className='fnt08rem text-grey mt-1'>{data.featured_description}</div>
                    </div>

                    <div className='mt-4 borderdashed pb-4'>
                        <div className='d-flex align-items-center'>
                            <div className='mqModalFsize' style={{ flexGrow: "1" }}>{t('date')}</div>
                            <div><div className='fnt08rem mqModalFsizeSecond'>{personalizeData.tour_date}</div></div>
                        </div>
                    </div>
                    <div className='mt-3 pb-5'>
                        <div className='d-flex align-items-center'>
                            <div className='mqModalFsize' style={{ flexGrow: "1" }}>{t('numPassenger')}</div>
                            <div><div className='fnt08rem mqModalFsizeSecond'>{personalizeData.person}</div></div>
                        </div>
                    </div>
                    {selectedProducts.length > 0 ?
                        <div className='bg-white borderrad12 p-3 text-muted fnt08rem mb-5'>
                            <ul>
                                {(data.extra_products).map((item, index) => {
                                    if (selectedProducts.includes(item.id)) {
                                        return (
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <li key={item.id}>{item.name}</li>
                                                <button className='customButton text-warning' onClick={() => handleDelete(item.id)}>
                                                    <i className="fas fa-trash-alt "></i>
                                                </button>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </ul>
                        </div>
                        :
                        ""}

                    <div className='mt-3 pb-4'>
                        <div className='d-flex align-items-center'>
                            <div className='mqModalFsize fnt08rem' style={{ flexGrow: "1" }}>{t('paymentModal.prodAmount')}</div>
                            <div><div className=' mqModalFsizeSecond'>{dataCalcPrice.data.total_product_price} EUR</div></div>
                        </div>
                    </div>
                    <div className='pb-4'>
                        <div className='d-flex align-items-center'>
                            <div className='mqModalFsize fnt08rem' style={{ flexGrow: "1" }}>{t('paymentModal.personAmount')}</div>
                            <div><div className=' mqModalFsizeSecond'>{dataCalcPrice.data.total_person_price} EUR</div></div>
                        </div>
                    </div>
                    <div className='pb-4'>
                        <div className='d-flex align-items-center'>
                            <div className='mqModalFsize fnt12rem' style={{ flexGrow: "1" }}>{t('paymentModal.totalAmount')}</div>
                            <div><div className='fnt16rem mqModalFsizeSecond'>{dataCalcPrice.data.total_price} EUR</div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-8 col-sm-12 col-xs-12 paymentModalForm paymentModalMQCol'>
                <div className='w-100 h-100 borderlight borderrad12 p-5'>

                    <RegisterForBuy props={data} />

                </div>
            </div>
        </div>
    )
}

export default PaymentModal