import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { Link } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap';
import LoadingPage from './loadingPage';
import { useTranslation } from 'react-i18next';


const Register = () => {
  const { t } = useTranslation();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [geoCodes, setGeoCodes] = useState(null);
  const [countries, setCountries] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    password_confirmation: '',
    country_id: '',
    gender: '',
    dual_code: '',
    phone: '',
  });
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [errors, setErrors] = useState({
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/definations/geo-codes');
        const responseCountries = await axios.get(process.env.REACT_APP_API_URL + '/definations/countries');
        setGeoCodes(response.data);
        setCountries(responseCountries.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFocus = (name) => {
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleOptionChange = (e) => {
    const { value } = e.target;

    setFormData({
      ...formData,
      gender: value,
    });

    setErrors({
      ...errors,
      gender: '',
    });
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;


    if (type === 'checkbox') {
      setCheckboxChecked(checked);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setErrors({
      ...errors,
      [name]: '',
      checkbox: '',
    });
  };

  const handleSelectChange = (selectedOption, selectName) => {
    if (selectName === "country") {
      setFormData({
        ...formData,
        country_id: selectedOption.value,
      });
    } else if (selectName === "dual_code") {
      setFormData({
        ...formData,
        dual_code: selectedOption.value,
      });
    }
  };




  const handleSubmit = (e) => {
    e.preventDefault();

    const fields = [
      { name: "password", message: t('validation.invalidPassword') },
      { name: "name", message: t('validation.invalidName') },
      { name: "surname", message: t('validation.invalidSurname') },
      { name: "email", message: t('validation.invalidEmail') },
      { name: "password_confirmation", message: t('validation.invalidPasswordConfirmation') },
      { name: "gender", message: t('validation.invalidGender') },
      { name: "country_id", message: t('validation.invalidCountry') },
      { name: "dual_code", message: t('validation.invalidDualCode') },
      { name: "phone", message: t('validation.invalidPhoneCode') },
    ];

    const newErrors = {};

    fields.forEach((field) => {
      if (!formData[field.name]) {
        newErrors[field.name] = field.message;
      }
    });

    if (formData.email) {
      if (!isEmailValid(formData.email)) {
        newErrors.email = t('validation.invalidEmail');
      }
    }

    if (!checkboxChecked) {
      newErrors.checkbox = t('validation.invalidCheckbox');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    axios.post(process.env.REACT_APP_API_URL + '/auth/register', formData)
      .then(response => {
        console.log('Başarıyla gönderildi:', response.data);
        setShowSuccessModal(true);
      })
      .catch(error => {
        console.error('Hata oluştu:', error);
        setErrorMessage(error.response.data.errorMessage)
        setShowFailureModal(true);

      });
    return;

  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    window.location.href = '/login';
  };

  const handleCloseFailureModal = () => {
    setShowFailureModal(false);
  };


  if (loading) {
    return (<LoadingPage/>);
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <div className='bgLogin'>
      <div className='bgLoginLight'>
        <div className='d-flex align-items-center h-100'>
          <div className='w-100'>
            <Link to='/'><button className='customButton exitIcon'><i className="fas fa-arrow-left"></i></button></Link>
            <div className='registerPageDiv mediaQueryPadding'>
              <div className='fnt44px fw-bold'>{t('signUp.welcome')}</div>
              <div className='fnt14px mt-4'>
              {t('signUp.consultantTeam')} <br />{t('signUp.satisfaction')}
              </div>
              <div className='row mt-4'>
                <div className='col-md-6 col-xs-12 col-sm-12'>
                  <div>{t('registerForm.name')}</div>
                  <div><input type='text' placeholder={t('registerForm.enterName')} className='w-100 whiteInput mt-1' name="name" value={formData.name} onChange={handleChange} onFocus={() => handleFocus('name')} /></div>
                  <span style={{ color: 'red' }}>{errors.name}</span>
                </div>
                <div className='col-md-6 col-xs-12 col-sm-12 mqPLvar' style={{ paddingLeft: '0px' }}>
                  <div>{t('registerForm.surname')}</div>
                  <div><input type='text' placeholder={t('registerForm.enterSurname')} className='w-100 whiteInput mt-1' name="surname" value={formData.surname} onChange={handleChange} onFocus={() => handleFocus('surname')} /></div>
                  <span style={{ color: 'red' }}>{errors.surname}</span>
                </div>

              </div>
              <div className='mt-3 fnt14px'>{t('registerForm.email')}</div>
              <div >
                <input type='text' autoComplete='cappadociaEmail' placeholder={t('registerForm.enterEmail')} className='w-100 whiteInput mt-1' name="email" value={formData.email} onChange={handleChange} onFocus={() => handleFocus('email')} />
              </div>
              <span style={{ color: 'red' }}>{errors.email}</span>
              <div className='mt-3 fnt14px'>{t('password')}</div>
              <div>
                <input type='password' placeholder={t('signUp.enterPassword')} className='w-100 whiteInput mt-1' name="password" value={formData.password} onChange={handleChange} onFocus={() => handleFocus('password')} />
              </div>
              <span style={{ color: 'red' }}>{errors.password}</span>
              <div className='mt-3 fnt14px'>{t('signUp.passwordConfirm')}</div>
              <div>
                <input type='password' placeholder={t('signUp.enterPasswordConfirm')} className='w-100 whiteInput mt-1' name="password_confirmation" value={formData.password_confirmation} onChange={handleChange} onFocus={() => handleFocus('password_confirmation')} />
              </div>
              <span style={{ color: 'red' }}>{errors.password_confirmation}</span>
              <div className='mt-3'>{t('registerForm.country')}</div>
              <div>
                <Select
                  className='w-100 mt-1 fnt08rem '
                  onChange={(selectedOption) => handleSelectChange(selectedOption, "country")}
                  onFocus={() => handleFocus('country_id')}
                  options={countries.data.map((country, index) => ({
                    value: country.id,
                    label: country.country,
                  }))}
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: 'gray',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: 'black',
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: 'white',
                    }),
                  }}
                />
              </div>
              <span style={{ color: 'red' }}>{errors.country_id}</span>
              <div className='row mt-3'>
                <div className='col-12'>{t('registerForm.mobile')}</div>
                <div className='col-3'>
                  <Select
                    className='w-100  mt-1 fnt08rem'
                    onChange={(selectedOption) => handleSelectChange(selectedOption, "dual_code")}
                    onFocus={() => handleFocus('dual_code')}
                    options={geoCodes.data.map((item, index) => ({
                      value: item.code,
                      label: item.value,
                    }))}
                    styles={{
                      menu: (provided, state) => ({
                        ...provided,
                        backgroundColor: 'gray',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: 'black',
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        color: 'white',
                      }),

                    }}
                  />
                  <span style={{ color: 'red' }}>{errors.dual_code}</span>


                </div>
                <div className='col-9 ps-0'>
                  <input type='text'
                    placeholder='555 55 55'
                    className='w-100 whiteInput mt-1'
                    id='phone'
                    name='phone'
                    value={formData.phone}
                    onChange={handleChange}
                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ""); }}
                    onFocus={() => handleFocus('phone')} />
                  <span style={{ color: 'red' }}>{errors.phone}</span>
                </div>

              </div>


              <div className='row mt-3'>
                <div className='col-3 d-flex'>
                  <input
                    type='radio'
                    name='gender'
                    value='MALE'
                    checked={formData.gender === 'MALE'}
                    onChange={handleOptionChange}
                  />
                  <div className='ms-2'>{t('registerForm.male')}</div>
                </div>
                <div className='col-9 d-flex ps-0'>
                  <input
                    type='radio'
                    name='gender'
                    value='FEMALE'
                    checked={formData.gender === 'FEMALE'}
                    onChange={handleOptionChange}
                  />
                  <div className='ms-2'>{t('registerForm.female')}</div>
                </div>
              </div>
              <span style={{ color: 'red' }}>{errors.gender}</span>

              <div className='mt-3 fnt12px d-flex'>
                <input type='checkbox' name='checkbox' onChange={handleChange} checked={checkboxChecked} />
                <div className='ms-2'>{t('signUp.confirmPrivacy')}</div>
              </div>
              <span style={{ color: 'red' }}>{errors.checkbox}</span>
              <div className='mt-3 fnt12px d-flex'>
                <input type='checkbox' />
                <div className='ms-2'>{t('signUp.informMe')}</div>
              </div>
              <div className='mt-4 mb-5'>
                <button className='btn bannerbtnwhite w-100 mt-2 fw-bold' onClick={handleSubmit} style={{ borderRadius: "60px" }}>{t('signUp.register')}</button>
              </div>

            </div>
          </div>
        </div>
      </div>


      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('signUp.registrationSuccess')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {t('signUp.registrationComplete')}
        </Modal.Body>
        <Modal.Footer>
          <Button className='bgblue' onClick={handleCloseSuccessModal}>
          {t('paymentForm.close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showFailureModal} onHide={handleCloseFailureModal} centered >
        <Modal.Header closeButton>
          <Modal.Title>{t('signUp.registrationFailed')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button className='bgblue' onClick={handleCloseFailureModal}>
          {t('paymentForm.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Register