
import React, { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useParams } from 'react-router-dom';
import FeaturedCard from './featuredcard';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';


import 'swiper/css/navigation';

const SearchedCart = (propsData) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();
  const currentTerm = propsData.currentTerm;
  const data = propsData.propsData.data;
  const curruntDate = propsData.currentDate;
  const setCurrentDate = propsData.setCurrentDate;
  const formatedDate = (date) => {
    const fixedDate = date.toLocaleDateString('en-CA'); // y-m-d formatında tarih dönüşümü
    setCurrentDate(fixedDate)
  };

  const resetFilter = () => (setCurrentDate())
  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  if (isLoading) {
    return ""
  };
  return (
    <div className='homeFeaturesection searchPage mediaQueryPadding' style={{ padding: "0px 100px 180px 100px" }}>
      <div className='pt-md-5' >
        <div className='row'>
          <div className='col-md-12 col-xs-12 col-sm-12'>
            <div className='d-flex align-items-center h-100'>
              <div className='w-100'>
                <div className='baslik1 mx-2'>{t('header.tours')}</div>

                <div className=' mx-2'>
                  <div className='row pt-3'>
                    <div className='col-md-6 col-xs-12 col-sm-12'>
                      <div className='baslik2'>{t('searchPage.results')}"{currentTerm}" </div>

                    </div>
                    <div className='col-md-6 col-xs-12 col-sm-12'>
                      <div className='d-flex align-items-center filterSection' style={{ justifyContent: 'end' }}>
                        <div>
                          <DatePicker
                            className='personalizeTourselect'
                            selected={curruntDate ? new Date(curruntDate) : null}
                            onChange={(date) => formatedDate(date)}
                            minDate={new Date()}
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy"
                            placeholderText={t('searchPage.filterDate')}
                          />
                        </div>
                        <button onClick={resetFilter} className='customButton ps-3'>
                          <div className='w-100 text-center' >
                            <i className="fas fa-filter"></i>
                          </div>
                          <div className='fnt08rem'>
                          {t('searchPage.clear')}
                          </div>
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
                <div className='mt-4 blogGrid'>
                  {(data).map((card, index) => (
                    <div key={index}>
                      <FeaturedCard
                        title={card.title}
                        content={card.get_type.title}
                        price={card.price}
                        saleprice={card.total_price}
                        star={card.star}
                        image={card.featured_file}
                        slug={card.slug}
                        tourId={card.id}
                        isFavorited={card.favorited}
                        perType={card.per_type.per_text}
                        type="feature"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchedCart;
