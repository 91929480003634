import { useState } from "react";
import ProductForms from "./profileModals/FinalThoughts";
import Col from 'react-bootstrap/Col';
import NavbarImg from "./navbarImg";
import PersonalInfoModal from "./profileModals/personalInfoModal";
import SecurityModal from "./profileModals/securityModal";
import FinalThoughts from "./profileModals/FinalThoughts";
import LoadingPage from './loadingPage';
import { useTranslation } from 'react-i18next';




function ProductNavbar(props) {
  const { t } = useTranslation();
  const data = props.props.data
  const userInfo = data;
  localStorage.setItem("user", JSON.stringify(userInfo));
  const [activeForm, setActiveForm] = useState('personal');
  const handleFormChange = (formType) => {
    setActiveForm(formType);
  };
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setImage(URL.createObjectURL(selectedImage));
    }
  };
  
  if(!data){
    return(<LoadingPage/>)
  }

  return (
    <div>
      <div className="padProduct mt-2 pb-4 mqNoneProduct">
        <div className="baslik1">{t('profilePage.profile')}</div>
        <div>{t('profilePage.easyFast')}</div>


      </div>
      <div className="mqDblock pt-4" style={{ display: 'none' }}>
        <div className="row">
          <div className="col-4">
            <div className="padProduct pt-3">
              <div className="fnt12rem fw-bold">{t('profilePage.profile')}</div>
              <div className="fnt06rem">{t('profilePage.easyFast')}</div>
            </div>
          </div>
          {/* <div className="col-8">
            <div className="bgblue w-100 mt-3 overflow-hidden d-flex align-items-center justify-content-center" style={{ height: '2rem' }}><div className="uploadImg"></div><div className="pP"></div></div>
            <div align='right'>
              <div className="bgorange pt-2 pe-2 fnt08rem" style={{ height: '2rem', width: '80%' }}>{data.name} {data.surname}</div>

            </div>
          </div> */}
        </div>
      </div>


      <div className="bgblue w-100">

        <div className="row">
          <Col md={9} sm={12} xs={12}>
            <div className="text-white productNavDivL pe-md-5 me-md-5" style={{ display: 'flex', height: '64px', gap: '4remy' }}>

              <div className={activeForm === 'personal' ? 'active' : ''}>
                <button type="button" className="productBtn padProduct overflow-hidden d-flex align-items-center h-100" onClick={() => handleFormChange('personal')}>
                  <div><NavbarImg type="personal" /></div>
                  <div className="ms-2 h1" style={{ color: activeForm === 'personal' ? '#FEC75D' : 'white' }}>{t('profilePage.personalInfo')}</div>
                </button>
              </div>
              <div className={activeForm === 'final' ? 'active' : ''}>
                <button type="button" className="productBtn padProduct overflow-hidden d-flex align-items-center h-100" onClick={() => handleFormChange('final')}>
                  <div><NavbarImg type="final" /></div>
                  <div className="ms-2 h1" style={{ color: activeForm === 'final' ? '#FEC75D' : 'white' }}>{t('profilePage.final')}</div>
                </button>
              </div>
              <div className={activeForm === 'security' ? 'active' : ''}>
                <button type="button" className="productBtn padProduct overflow-hidden d-flex align-items-center h-100" onClick={() => handleFormChange('security')}>
                  <div><NavbarImg type="security" /></div>
                  <div className="ms-2 h1" style={{ color: activeForm === 'security' ? '#FEC75D' : 'white' }}>{t('profilePage.security')}</div>
                </button>
              </div>
            </div>
          </Col>

          {/* <Col md={3}>
            <div className="mqNoneProduct">
              <div className="pP"></div>
              <div className="overflow-hidden d-flex align-items-center justify-content-end productNavDivR" style={{ height: '64px' }}>
                <div className="uploadImg" align="right">
                  <input type="file" accept="image/*" />
                </div>
              </div>
            </div>

          </Col> */}
        </div>
      </div>
      {/* <div className="mqNoneProduct">
        <div className="w-100 mt-1" align="right">
          <div className="bgorange p-2" align='center' style={{ width: '350px' }}>
            {data.name} {data.surname}
          </div>
        </div>
      </div> */}


      {activeForm === 'personal' && (
        <PersonalInfoModal props={data} />
      )}

      {activeForm === 'final' && (
        <FinalThoughts props={data} />
      )}

      {activeForm === 'security' && (
        <SecurityModal props={data} />
      )}
    </div>
  );
}

export default ProductNavbar;
