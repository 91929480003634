import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const token = localStorage.getItem("token") || "";
const reqHeader = token ? { Authorization: `Bearer ${token}` } : {};

const options = {
  headers: reqHeader
};


const initialState = {
    pageApi: [],
    isLoading: true

}

export const getPageApi = createAsyncThunk('getPageApi', async(page) =>{
    const {data} = await axios.get(process.env.REACT_APP_API_URL + `/template/${page}`, options)
    return data
})

export const pageApiSlice = createSlice({
    name: "pageApi",
    initialState,
    reducers :{

    },
    extraReducers: (builder) => {
        builder.addCase(getPageApi.fulfilled, (state, action) => {
            state.pageApi = action.payload;
            state.isLoading = false

        })
    }
})


export default pageApiSlice.reducer