import React, { useState, useEffect } from 'react';
import CartCreditForm from './cartCreditForm';
import { useSelector, useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker';

import { updateGuest, initializeGuests } from '../api/formValueState/formValueSlice';

const GuestCartForm = (props) => {
  const dispatch = useDispatch()

  const formData = props.props;
  const guestCount = props.guestCount
  const setCardTitle = props.setCardTitle;
  const { creditFormShow, setCreditFormShow } = props.creditFormState
  const [startDate, setStartDate] = useState(new Date());
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const initialUserCount = guestCount - 1;
  const [errors, setErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const guestSlice = useSelector(state => state.formValueInfo.guests)

  useEffect(() => {
    if (!guestSlice[0]) {
      const userArray = Array(initialUserCount).fill({ name: "", surname: "", birthdate: "", nationality: "", passport: "" });
      dispatch(initializeGuests(userArray));
    }

  }, [dispatch, initialUserCount]);

  useEffect(() => {
    const createUserObjects = () => {
      const userArray = [];
      for (let i = 0; i < initialUserCount; i++) {
        userArray.push({ name: "", surname: "" });
      }
      setUsers(userArray);
    };

    createUserObjects();
  }, [initialUserCount]);

  const handleChange = (index, field, value) => {
    const newUsers = [...users];
    newUsers[index][field] = value;
    setUsers(newUsers);
    dispatch(updateGuest({ index, field, value }));

  };

  const handleBirthDate = (index, field, date) => {
    const formattedDate = date.toLocaleDateString('en-CA'); // y-m-d formatında tarih dönüşümü
    const newUsers = [...users];
    newUsers[index][field] = formattedDate;
    setUsers(newUsers);
    dispatch(updateGuest({ index, field, value: formattedDate }));

  };

  const checkErrors = () => {
    const newErrors = Array(users.length).fill(false);

    guestSlice.forEach((user, index) => {

      if (!user.name || !user.surname || !user.birthdate || !user.nationality || !user.passport) {
        newErrors[index] = true;
      }
    });

    setErrors(newErrors);
    return newErrors.some(error => error);
  };

  const handleShow = () => {
    const hasErrors = checkErrors();
    if (hasErrors) {
      setShowErrors(true);
    } else {
      setCreditFormShow(true);
      setCardTitle(true)

    }
  };
  const allFormData = {
    ...formData,
    guests: guestSlice,
  };
  const [isOpen, setIsOpen] = useState(Array(users.length).fill(false));
  useEffect(() => {
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[0] = true;
      return newState;
    });
  }, []);
  const toggleForm = (index) => {
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !prevState[index];
      return newState;
    });
  };

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <div>
      {creditFormShow ?
        <CartCreditForm props={allFormData} />
        :
        <div>
          {users.map((user, index) => (
            <div key={index}>
              {showErrors && errors[index] && (
                <div className="error-message text-danger fw-bold">Please fill out all fields for Guest {index + 2}</div>
              )}

              <div className="collapse-toggle bgorange text-white pt-1 pb-1 mt-2 ps-3 d-flex justify-content-between align-items-center cpointer" onClick={() => toggleForm(index)}>
                <div>{index + 2}. Guest</div>
                <div className='text-dark fnt08rem pe-2 '><span>Click for form</span> <i className="fas fa-caret-down"></i></div>
              </div>
              {isOpen[index] && (
                <div className="collapse-content mb-3">
                  <div className="row mt-4">
                    <div className="col-md-6 col-xs-12 col-sm-12">
                      <div>Name</div>
                      <input
                        type="text"
                        name="name"
                        placeholder='Please enter your Name'
                        className="w-100 rezervationinput mt-1"
                        value={guestSlice[index]?.name}
                        onChange={(e) => handleChange(index, "name", e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-xs-12 col-sm-12 mqPLvar" style={{ paddingLeft: '0px' }}>
                      <div>Surname</div>
                      <input
                        type="text"
                        name="surname"
                        placeholder='Please enter your Surname'
                        className="w-100 rezervationinput mt-1"
                        value={guestSlice[index]?.surname}
                        onChange={(e) => handleChange(index, "surname", e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6 col-xs-12 col-sm-12">
                      <div>Birthdate</div>
                      <div className='position-relative'>
                        <DatePicker
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={100}
                          maxDate={new Date()}
                          placeholderText='Please Select Date'
                          selected={formData.birthdate && new Date(formData.birthdate)}
                          className="modalSelectDate"
                          onChange={(date) => {
                            setStartDate(date);
                            handleBirthDate(index, "birthdate", date);
                          }}
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy"
                          value={guestSlice[index]?.birthdate}
                        />
                        {!guestSlice[index]?.birthdate &&
                          <div className=" position-absolute top-50 end-0 translate-middle-y me-2 pe-1 modalCalendar" >
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12 col-sm-12 mqPLvar" style={{ paddingLeft: '0px' }}>
                      <div>Nationality</div>
                      <input
                        type="text"
                        name="nationality"
                        placeholder='Please enter your nationality'
                        className="w-100 rezervationinput mt-1"
                        value={guestSlice[index]?.nationality}
                        onChange={(e) => handleChange(index, "nationality", e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mt-3 fnt14px">Passport</div>
                    <input
                      type="text"
                      name="passport"
                      placeholder='Please enter your passport'

                      className="w-100 rezervationinput mt-1"
                      value={guestSlice[index]?.passport}
                      onChange={(e) => handleChange(index, "passport", e.target.value)}

                    />
                  </div>
                </div>
              )}

            </div>

          ))}

          <button className='btn bgblue bannerbtnwhite text-white w-100 mt-4 fw-bold' onClick={handleShow} >Save</button>
        </div>

      }
    </div>

  );
};

export default GuestCartForm;