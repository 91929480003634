import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import FeaturedCard from './featuredcard';
import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const BannerBalloonTours = (propsData) => {
    const data= propsData.propsData;
    return (
        <Swiper
            slidesPerView={1}
            spaceBetween={0}
            pagination={true}
            navigation={true}
            modules={[Pagination,Navigation]}
            className='balloonToursBanner'
        >
            {(data.datas).map((card) => (
                <SwiperSlide key={card.id}>
                    <FeaturedCard content={card.content} title={card.title} image={card.image} type="balloonToursBanner" />
                </SwiperSlide>
            ))}

        </Swiper>
    )
}

export default BannerBalloonTours