import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';



const initialState = {
    tourDetailApi: [],
    isLoading: true
}

export const getTourDetailApi = createAsyncThunk('getTourDetailApi', async(slug) =>{
    const {data} = await axios.get(`https://admin.cappadociavisitor.com/public/api/v1/tours/show/${slug}`)

    
    return data
})

export const tourDetailApiSlice = createSlice({
    name: "tourDetailApi",
    initialState,
    reducers :{

    },
    extraReducers: (builder) => {
        builder.addCase(getTourDetailApi.fulfilled, (state, action) => {
            state.tourDetailApi = action.payload;
            state.isLoading = false
        }) 
    }
})


export default tourDetailApiSlice.reducer