import React from 'react'
import { useTranslation } from 'react-i18next';


const PagesContentSection = (props) => {
    const { t } = useTranslation();
    const data=props.props

    const decodedData = atob(data.content);
    const textDecoder = new TextDecoder('utf-8');
    const decodedCancelRules = textDecoder.decode(new Uint8Array(decodedData.split('').map(char => char.charCodeAt(0))));


  return (
    <div className={`aboutContentSection ${data.slug}`}>
            <div className='d-flex blueAndOrangeDiv'>
                <div className='bgblue ' style={{ flexGrow: "1", padding: "10px 100px" }}>
                    <div className='text-white baslik1'>{data.title}</div>
                </div>
                {data.title === "About" ?
                    <div className='bgorange' style={{ padding: '0 180px 0 3rem' }}>
                        <div className='d-flex align-items-center h-100'>
                            <div className='w-100 '>
                                <span>{t('aboutUs.since')}</span>
                                <span className='px-2' style={{fontSize:'36px'}}>2020</span>
                            </div>
                        </div>
                    </div>
                    :""
                }
            </div>
        {data.sub_title ?         
            <div className='miniTitle'>
                <div className='bgorange w-25 p-2 mt-4'>
                    <div className='pl6re fw-bold'>{data.sub_title}</div>
                </div>
            </div>  
        : 
            ""}

        <div className='mediaQueryPadding' style={{ padding: "80px 100px 200px 100px"}}>
        <p dangerouslySetInnerHTML={{ __html: decodedCancelRules }} />
        </div>


    </div>
  )
}

export default PagesContentSection