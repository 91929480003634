import React, { useState } from 'react';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, TelegramShareButton } from 'react-share';
import { Helmet } from 'react-helmet';


const ShareModal = ({slug , closeButton, title}) => {
  console.log(title)
    const [success, setSuccess] = useState(false);

    const tdAdress = 'https://cappadociavisitor.com/tourdetail/'

    const copyLink = (slug) => {
        // 1. Geçici bir input elementi oluşturun
        const input = document.createElement('input');
        input.value = `${tdAdress}${slug}`;
    
        // 2. Input elementini body'ye ekleyin (görünmez şekilde)
        document.body.appendChild(input);
    
        // 3. Input elementini seçin
        input.select();
    
        // 4. Seçili metni kopyalayın
        document.execCommand('copy');
    
        // 5. Input elementini silin
        document.body.removeChild(input);
    
        // İsteğe bağlı: Kopyalama işleminin başarılı olduğunu bildiren bir mesaj görüntüleyin
        setSuccess(true);
        setTimeout(() => setSuccess(false), 1000);
      };
  return (
    <div className='shareModal p-2 ps-md-4 bg-white'>
      <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={title} />
        <meta property="og:url" content={`https://cappadociavisitor.com/tourdetail/${slug}`}/>
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={title} />
      </Helmet>
    <div className='modalHeader fw-bold '>
      <div className='d-flex align-items-center justify-content-between'>
        <div>
        Share Now
        </div>
        {
          closeButton &&
          <div>
          <button className='customButton' onClick={()=> closeButton()}>
          <i className="fas fa-times pe-1" />

          </button>
        </div>
        }

      </div>
      
    </div>
    <div className='modalBody pt-4'>
      <div className='pt-2 changePad'>
        Share this link
      </div>
      <div className=' d-flex pt-3 justify-content-between align-items-center pe-md-4 changePad'>
        <FacebookShareButton
          url={`https://cappadociavisitor.com/tourdetail/${slug}`}
        >
          <div className='shareSocial text-primary'>
            <i className="fa-brands fa-facebook" />
          </div>
        </FacebookShareButton>
        <TwitterShareButton
          url={`https://cappadociavisitor.com/tourdetail/${slug}`}
        >
          <div className='shareSocial'>
            <i className="fa-brands fa-x-twitter" />
          </div>
        </TwitterShareButton>


        <WhatsappShareButton
          url={`https://cappadociavisitor.com/tourdetail/${slug}`}
            
>
          <div className='shareSocial text-success'>
            <i className="fa-brands fa-whatsapp" />
          </div>
        </WhatsappShareButton>
        <TelegramShareButton
          url={`https://cappadociavisitor.com/tourdetail/${slug}`}
        >
          <div className='shareSocial text-primary'>
            <i className="fa-brands fa-telegram" />
          </div>
        </TelegramShareButton>

      </div>
      <div className='mt-2 changePad'>
        Or copy link
      </div>
      <div className='shareLinkSection mt-2 w-100 '>
        <div className='row w-100'>
          <div className='col-9 '>
            <div className='d-flex align-items-center  overflow-hidden text-nowrap w-100 h-100'>
              <i className="fa-solid fa-link pe-2" />
              <div>
                {tdAdress}{slug}
              </div>
            </div>
          </div>
          <div className='col-3 p-0'>
            <button
              className='copyIcon w-100'
              onClick={() => {
                copyLink(slug);
              }}
            >
              {success ? <i className="fa-solid fa-check" /> : 'Copy'}
            </button>
          </div>

        </div>

      </div>
    </div>
  </div>
  )
}

export default ShareModal