import axios from 'axios';

const BASE_URL = "https://admin.cappadociavisitor.com";

const authRequest = async (endpoint, method = "GET", data, changeData, setLoading=null, setError=null, sendBearer=true) => {

  const token = localStorage.getItem("token") || "";
  const reqHeader = token !== "" ? {Authorization: `Bearer ${token}`} : {}
  let header= {
    ...reqHeader,
    
  }
  if(!sendBearer){
    header={}
  }
  const options = {

    method :method,
    headers:header

  };

  if (method !== 'GET' && data) {
    options.data = data;
    
  }
 
  try {
    const response = await axios(`${BASE_URL}${endpoint}`, options);
    changeData(response.data);
    if(setError){
      setError("")
    }
  } catch (error) {
    console.error(error);
    console.log(error)
    if(setError){
      setError(error.response)
    }
  }finally {
    if(setLoading){

      setLoading(false);
    }
  }
};

export default authRequest;