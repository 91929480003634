import React from 'react'
import { useState, useEffect } from 'react';

const SiteMap = () => {
    const [sitemap, setSitemap] = useState('');

    useEffect(() => {
      fetch('https://admin.cappadociavisitor.com/api/v1/sitemap')
        .then(response => response.text())
        .then(data => setSitemap(data));
    }, []);
    return (
      <div>
        <h1>Sitemap</h1>
        <div dangerouslySetInnerHTML={{ __html: sitemap }} />
      </div>
    );
}

export default SiteMap