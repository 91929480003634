import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getCommentApi } from './api/comment/commentApiSlice';
import FeaturedCard from './featuredcard';
import { useTranslation } from 'react-i18next';


const ReviewSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const data = useSelector(state => state.commentApi.commentApi);
    const isLoading = useSelector(state => state.commentApi.isLoading);
    useEffect(() => {
        dispatch(getCommentApi())
    }, []);
    const mapData = data.data;

    if (isLoading) {
        return "";
    }

    return (
        <div className='reviewSection'>
            <div className='bgblue w-100 ps-md-1'>
                <div className='d-flex justify-content-between align-items-center blogSectionMain mediaQueryPadding  pt-0 pb-0'>
                    <div>
                        <h1 className='text-white baslik1 p-md-5 ps-0 '>{t('review.review')}</h1>
                    </div>


                    <div className='d-flex justify-content-center align-items-center pe-md-5'>
                        <div className='w-100'>
                            <div className='text-white pb-2'>
                            {t('review.commentType')}
                            </div>
                            <select    className='whiteborderinput'>
                                <option selected disabled>{t('review.all')}</option>
                            </select>
                        </div>

                    </div>




                </div>
            </div>

            <div className="blogSectionMain mediaQueryPadding pt-5 pb-5">

                <div className='blogGrid'>

                    {(mapData).map((item, index) => (
                        <div key={index} className='reviewCard'>
                            <FeaturedCard
                                commentUser={item.full_name}
                                content={item.comment}
                                commentName={item.item_name}
                                commentType={item.type}
                                image={item.featured_image}
                                star={item.rate}
                                type="review"
                            />
                        </div>
                    ))}
                </div>

            </div>
        </div>

    )
}

export default ReviewSection