import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import { setTransferInfo, setReservation_date, setChangeGroupId, setProduct_id } from '../api/transfer/transferInfoSlice';
import { useSelector, useDispatch } from 'react-redux'
import { Collapse } from 'react-bootstrap';
import authRequest from '../api/requests';
import { getTransferApi } from '../api/transfer/transferApiSlice';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import LoadingPage from '../loadingPage';

const BannerTransfer = (props) => {
    console.log(props)
    const { t } = useTranslation();
    const transferData = useSelector(state => state.transferInfo)
    const onShowModal = props.onShowModal;
    const data = props.data;
    const transferGroups = data.infos.transfer_groups;
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);
    const [responseData, setResponseData] = useState([]);
    const [error, setError] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [showLocation, setShowLocation] = useState(false);
    const [showDate, setShowDate] = useState(false);
    const [stateLocations, setStateLocations] = useState();
    // ****transferApiData******
    const returnLocations = useSelector(state => state.transferApi.returnLocations)
    const pickupLocations = useSelector(state => state.transferApi.pickupLocations)
    const avaibleDatesCalendar = useSelector(state => state.transferApi.avaibleDatesCalendar)
    const avaiblesTimes = useSelector(state => state.transferApi.avaiblesTimes)
    const totalData = useSelector(state => state.transferApi.total_data)
    const allCars = useSelector(state => state.transferApi.transferCars)

    const enableDates = (avaibleDatesCalendar).map((item) => {
        const startDateParts = item.start_date.split(',').map(part => parseInt(part, 10));
        const endDateParts = item.end_date.split(',').map(part => parseInt(part, 10));

        const startDate = new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2]);
        const endDate = new Date(endDateParts[0], endDateParts[1] - 1, endDateParts[2]);

        return {
            start: startDate,
            end: endDate
        };
    });

    useEffect(() => {
        dispatch(getTransferApi(transferData))
    }, [dispatch, transferData]);

    useEffect(() => {
        setIsLoading(transferData.transfer_group_id)
        setShowLocation(transferData.transfer_group_id)
        setShowDate(transferData.pickup_location_id && transferData.return_location_id ? true : false);

    }, [transferData])
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedInfo = {
            [name]: value,
        };
        if (name === "transfer_group_id") {
            dispatch(setChangeGroupId(value));
            setStateLocations(pickupLocations)
        }
        dispatch(setTransferInfo(updatedInfo));
    }
    const handleSelectChange = (selectedOption, selectName) => {
        const updatedInfo = {
            [selectName]: selectedOption.value,
        }
        dispatch(setTransferInfo(updatedInfo));
    };

    const handeleReservation_date = (date) => {
        const formattedDate = date.toLocaleDateString('en-CA'); // y-m-d formatında tarih dönüşümü
        dispatch(setReservation_date(formattedDate));
    };

    let errorCount = false;
    const validForm = (e) => {
        e.preventDefault();

        const fields = [
            { name: "return_location_id", message: t('transfer.enterFinish') },
            { name: "pickup_location_id", message: t('transfer.enterStart') },
            { name: "reservation_date", message: t('validation.selectDate') },
            { name: "reservation_time", message: t('validation.selectTime') },
            { name: "transfer_group_id", message: t('transfer.enterTransferType') },
        ];

        const newErrors = {};

        fields.forEach((field) => {
            if (!transferData[field.name]) {
                newErrors[field.name] = field.message;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setError(newErrors);
            errorCount = true;
            return;
        }
        if (!errorCount && allCars) {
            
            onShowModal()
        }
    };
    const handleFocus = () => {
        setError("");
        errorCount = false;
    };

    const interfaceSettingsData = useSelector(state => state.settingsApi.interfaceSettingsApi);

    useEffect(() => {
        if (!interfaceSettingsData) {
            setIsLoading(false);
        }else if (interfaceSettingsData) {
            setIsLoading(true);
        }
    }, [interfaceSettingsData]);
    const getValue = (key) => {
        if (!interfaceSettingsData) {
            return 'Loading...';
        }
        const foundItem = interfaceSettingsData?.data?.find((item) => item.key === key);
        return foundItem ? foundItem.value : 'Not found';
    };
    
    if (!data) {
        return (<LoadingPage />)
    }
    if (!isLoading) {
        return (<LoadingPage />)
    }
    return (

        <div className="bannerbg2 paymentBanner mediaQueryPadding" style={{ padding: '8vw 10vw 0 10vw', backgroundImage: `url(${getValue('transfer_bg')})` }}>
            <div className="d-flex align-items-center h-100">
                <div className="w-100">

                    <div className='becomeDiv'>
                        <div className="bannerbaslik">{data.infos.transfer_page_title}</div>
                        <div className='mt-3 text-white fnt14px' style={{ maxWidth: "800px" }}>{data.infos.transfer_page_description}</div>

                    </div>
                    <div className="bannersearcPayment  pt-3 pb-3 mb-5" >

                        <div className="mb-1 mt-2 text-white"><span className="fnt14px">{t('bannerTransfer.chooseTransferType')}</span></div>
                        <div>
                            <select defaultValue="Please Choose The Transfer Type" className='whiteborderinput' name="transfer_group_id" onChange={handleChange} onFocus={handleFocus} >
                                <option disabled>{t('bannerTransfer.pleaseChooseTransferType')}</option>
                                {(transferGroups).map((item, index) => (
                                    <option key={item.id} value={item.id} >{item.name}</option>
                                ))}

                            </select>
                        </div>
                        <span style={{ color: 'red' }}>{error.transfer_group_id}</span>
                        <Collapse in={showLocation}>
                            <div className='row'>
                                <div className="col-md-6 col-xs-12 col-sm-12">
                                    <div className="mb-1 mt-2 text-white"><i className="fas fa-map-marker-alt"></i><span className="px-2 fnt14px">{t('from')} ({t('location')})</span></div>
                                    <div>
                                        <Select
                                            className='transferSelect text-white fnt08rem'
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, "pickup_location_id")}
                                            onFocus={handleFocus}

                                            placeholder={<div className='text-white'>{t('transfer.takeYou')}</div>}
                                            options={totalData?.pickup_locations?.map((item, index) => ({
                                                value: item.id,
                                                label: item.name,
                                            }))}
                                            value={transferData.pickup_location_id ? { value: transferData.pickup_location_id, label: totalData?.pickup_locations?.find(location => location.id === transferData.pickup_location_id)?.name } : null}

                                        />

                                    </div>
                                    <span style={{ color: 'red' }}>{error.pickup_location_id}</span>
                                </div>
                                <div className="col-md-6 col-xs-12 col-sm-12">
                                    <div className="mb-1 mt-2 text-white"><i className="fas fa-map-marker-alt"></i><span className="px-2 fnt14px">{t('to')} ({t('location')})</span></div>
                                    <div>


                                        <Select
                                            className='transferSelect text-white fnt08rem'
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, "return_location_id")}
                                            onFocus={handleFocus}
                                            placeholder={<div className='text-white'>{t('transfer.takeYou')}</div>}
                                            options={totalData?.return_locations?.map((item, index) => ({
                                                value: item.id,
                                                label: item.name,
                                            }))}
                                            // value={transferData.pickup_location_id ? { value: transferData.pickup_location_id, label: totalData?.pickup_locations?.find(location => location.id === transferData.pickup_location_id)?.name } : null}

                                            value={transferData.return_location_id ? { value: transferData.return_location_id, label: totalData?.return_locations?.find(location => location.id === transferData.return_location_id)?.name } : null}
                                        />

                                    </div>
                                    <span style={{ color: 'red' }}>{error.return_location_id}</span>
                                </div>
                            </div>
                        </Collapse>



                        <Collapse in={showDate}>
                            {avaibleDatesCalendar.length > 0 ?
                                <div className="row  mb-2">
                                    <div className="col-md-6 col-xs-12 col-sm-12">
                                        <div className="mb-1 mt-2 text-white"><i className="fas fa-calendar"></i><span className="px-2 fnt14px">{t('date')}</span></div>
                                        <div>
                                            <DatePicker
                                                selected={transferData.reservation_date && new Date(transferData.reservation_date)}
                                                className="whiteborderinput"
                                                includeDateIntervals={enableDates}

                                                onChange={(date) => {
                                                    setStartDate(date);
                                                    handeleReservation_date(date);
                                                }}
                                                onFocus={(e) => {
                                                    handleFocus()
                                                    e.target.blur();
                                                }}
                                                timeIntervals={15}
                                                minDate={new Date()}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy"
                                                popperPlacement="top-start"
                                                value={transferData.reservation_date ? transferData.reservation_date : t('validation.selectDate')}

                                            />
                                        </div>
                                        <span style={{ color: 'red' }}>{error.reservation_date}</span>
                                    </div>
                                    <div className="col-md-6 col-xs-12 col-sm-12 mqMT05">
                                        <div className="mb-1 mt-2 text-white"><i className="fas fa-clock"></i><span className="px-2 fnt14px">{t('time')}</span></div>
                                        <div>
                                            <select className='whiteborderinput' name="reservation_time" onChange={handleChange} onFocus={handleFocus}>
                                                {transferData.reservation_time ? "" : <option disabled selected >{transferData.reservation_time ? "" : t('validation.selectTime')}  </option>}
                                                {(avaiblesTimes).map((item, index) => (
                                                    <option key={index}>{item}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <span style={{ color: 'red' }}>{error.reservation_time}</span>

                                    </div>

                                </div>
                                :
                                <div className='text-center text-danger fw-bold pt-3' >
                                    <div>
                                        {t('transfer.transferFail')}
                                    </div>
                                    <div>
                                        {t('transfer.chooseLocation')}
                                    </div>
                                </div>
                            }

                        </Collapse>

                        <div className='mt-4'>
                            <button  className={allCars ? `btn bg-white w-100 p-2` : `btn bg-white w-100 p-2 text-secondary`} style={{ borderRadius: "60px", fontWeight: "600", fontSize: "18px" }} onClick={validForm}>{t('transfer.bookNow')}</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default BannerTransfer