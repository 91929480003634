// LoadingPage.js

import React from 'react';
import { ClipLoader } from 'react-spinners';

const LoadingPage = () => {
  return (
    <div className='w-100 d-flex justify-content-center align-items-center' style={{height:'100vh'}}>
        <div>
        <ClipLoader color='gray' />
    </div>
    </div>

  );
};

export default LoadingPage;