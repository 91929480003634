import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select';
import GuestForm from './guestForm';
import PayCreditForm from './payCreditForm';
import DatePicker from 'react-datepicker';
import authRequest from './api/requests';
import { useTranslation } from 'react-i18next';


const RegisterForBuy = (props) => {
  const { t } = useTranslation();

  const data = props.props
  const personalizeData = useSelector(state => state.tourDetailInfo)



  const [geoCodes, setGeoCodes] = useState(null);
  const [countries, setCountries] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [guestForm, setGuestForm] = useState(false)
  const [cardForm, setCardForm] = useState(false)
  const [startDate, setStartDate] = useState(new Date());
  const [guestTitle, setGuestTitle] = useState(false)
  const [cardTitle, setCardTitle] = useState(false)
  const [creditFormShow, setCreditFormShow] = useState(false)
  const creditFormState = {
    creditFormShow,
    setCreditFormShow,
  };

  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    country_id: '',
    gender: '',
    dual_code: '',
    phone: '',
    comment: '',
    hotel_name: '',
    guests: [],
    tour_date: personalizeData.tour_date,
    tour_time: personalizeData.time,
    person: personalizeData.person,
    products: personalizeData.products,
    tour_id: data.id,
  });

  console.log(formData)
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [checkMsg, setCheckMsg] = useState(true)
  const [checkMsgSecond, setCheckMsgSecond] = useState(false)

  const [errors, setErrors] = useState({
  });

  const guestInputs = [];

  for (let i = 0; i < personalizeData.person; i++) {
    guestInputs.push(<input key={i} type="text" placeholder={`Input ${i + 1}`} />);
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/definations/geo-codes');
        const responseCountries = await axios.get(process.env.REACT_APP_API_URL + '/definations/countries');
        setGeoCodes(response.data);
        setCountries(responseCountries.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFocus = (name) => {
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleOptionChange = (e) => {
    const { value } = e.target;

    setFormData({
      ...formData,
      gender: value,
    });

    setErrors({
      ...errors,
      gender: '',
    });
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;


    if (type === 'checkbox') {
      if (name === 'checkMsg') {
        setCheckMsg(checked)
      }
      else if (name === 'checkbox') {
        setCheckboxChecked(checked);
      }
      else if (name === 'checkMsgSecond') {
        setCheckMsgSecond(checked);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setErrors({
      ...errors,
      [name]: '',
      checkbox: '',
    });
  };

  const handleSelectChange = (selectedOption, selectName) => {
    if (selectName === "country") {
      setFormData({
        ...formData,
        country_id: selectedOption.value,
      });
    } else if (selectName === "dual_code") {
      setFormData({
        ...formData,
        dual_code: selectedOption.value,
      });
    }
  };

  const handleBirthDate = (date) => {
    const formattedDate = date.toLocaleDateString('en-CA'); // y-m-d formatında tarih dönüşümü
    setFormData({
      ...formData,
      birthdate: formattedDate,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const fields = [
      { name: "name", message: t('validation.invalidName') },
      { name: "surname", message: t('validation.invalidSurname') },
      { name: "email", message: t('validation.invalidEmail') },
      { name: "gender", message: t('validation.invalidGender') },
      { name: "country_id", message: t('validation.invalidCountry') },
      { name: "dual_code", message: t('validation.invalidDualCode') },
      { name: "phone", message: t('validation.invalidPhone') },
      { name: "birthdate", message: t('validation.invalidBirthDate') },

    ];

    const newErrors = {};

    fields.forEach((field) => {
      if (!formData[field.name]) {
        newErrors[field.name] = field.message;
      }
    });

    if (formData.email) {
      if (!isEmailValid(formData.email)) {
        newErrors.email = t('validation.invalidEmail');
      }
    }

    if (!checkboxChecked) {
      newErrors.checkbox = t('validation.invalidCheckbox');
    }
    if (!checkMsg) {
      newErrors.checkMsg = t('validation.invalidCheckbox');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    authRequest("/api/v1/carts/preAdd", "POST", formData, null, null, null, false);

    if (personalizeData.person > 1) {
      setGuestForm(true);
      setGuestTitle(true)
    } else {
      setCardForm(true);
    }
  };
  
  const handleBackForm = () => {
    if (guestForm && cardTitle) {
      setGuestForm(true);
      setGuestTitle(true)
      setCardForm(false)
      setCardTitle(false)
      setCreditFormShow(false)
    } else {
      setGuestForm(false);
      setCardForm(false);
      setGuestTitle(false);
      setCardTitle(false)
    }
  };


  const selectedCountry = countries?.data?.find(country => country.id === formData.country_id);
  const selectedDual_code = geoCodes?.data?.find(item => item.code === formData.dual_code);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (

    <div className='registerForBuy'>
      <div className='fnt14px mt-4 mb-5 d-flex justify-content-between align-items-center'>
        {!guestForm && !cardForm && <div class="fnt14rem ">{t('registerForm.mainContact')}</div>}
        {guestForm && guestTitle && !cardTitle && <div class="fnt14rem ">{t('registerForm.guestInfo')}</div>}
        {cardTitle && <div class="fnt14rem ">{t('registerForm.payment')}</div>}
        {cardForm && <div class="fnt14rem ">{t('registerForm.payment')}</div>}
        {guestForm || cardForm ? <div onClick={handleBackForm}><i className="fas fa-arrow-left fnt14px cpointer"></i></div> : ""}


      </div>
      {guestForm ?

        <GuestForm props={formData} setCardTitle={setCardTitle} creditFormState={creditFormState} />
        :
        <div>
          {/* // *******mainUserForm**** */}
          {cardForm ?
            <PayCreditForm props={formData} />
            :
            <div className='modalForm fnt14px'>
              <div className='row mt-4'>
                <div className='col-md-6 col-xs-12 col-sm-12'>
                  <div>{t('registerForm.name')}</div>
                  <div><input type='text' placeholder={t('registerForm.enterName')} className='w-100 rezervationinput mt-1' name="name" value={formData.name} onChange={handleChange} onFocus={() => handleFocus('name')} /></div>
                  <span style={{ color: 'red' }}>{errors.name}</span>
                </div>
                <div className='col-md-6 col-xs-12 col-sm-12 mqPLvar' style={{ paddingLeft: '0px' }}>
                  <div>{t('registerForm.surname')}</div>
                  <div><input type='text' placeholder={t('registerForm.enterSurname')} className='w-100 rezervationinput mt-1' name="surname" value={formData.surname} onChange={handleChange} onFocus={() => handleFocus('surname')} /></div>
                  <span style={{ color: 'red' }}>{errors.surname}</span>
                </div>

              </div>
              <div className='mt-3 fnt14px'>{t('registerForm.email')}</div>
              <div >
                <input type='text' autoComplete='cappadociaEmail' placeholder={t('registerForm.enterEmail')} className='w-100 rezervationinput mt-1' name="email" value={formData.email} onChange={handleChange} onFocus={() => handleFocus('email')} />
              </div>
              <span style={{ color: 'red' }}>{errors.email}</span>

              <div className='mt-3'>{t('registerForm.birthDate')}</div>
              <div className='mt-2 position-relative'>
                <DatePicker
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                  onFocus={(e) => {
                    handleFocus('birthdate')
                    e.target.blur();
                  }}
                  selected={formData.birthdate && new Date(formData.birthdate)}
                  className="modalSelectDate"
                  onChange={(date) => {
                    setStartDate(date);
                    handleBirthDate(date);
                  }}
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy"
                  value={formData.birthdate}
                  placeholderText={t('registerForm.enterDate')}
                />
                {!formData.birthdate &&
                  <div className=" position-absolute top-50 end-0 translate-middle-y me-2 pe-1 modalCalendar" >
                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                  </div>
                }
              </div>

              <div className='row mt-3'>
                <div className='col-12'>{t('registerForm.mobile')}</div>
                <div className='col-md-3 col-sm-12 col-xs-12'>
                  <Select
                    className='w-100  mt-1 fnt08rem '
                    onChange={(selectedOption) => handleSelectChange(selectedOption, "dual_code")}
                    onFocus={() => handleFocus('dual_code')}
                    options={geoCodes.data.map((item, index) => ({
                      value: item.code,
                      label: item.value,
                    }))}
                    value={selectedDual_code ? { value: selectedDual_code.code, label: selectedDual_code.value } : null}

                  />
                  <span style={{ color: 'red' }}>{errors.dual_code}</span>


                </div>
                <div className='col-md-9 col-sm-12 col-xs-12 ps-md-0'>
                  <input type='text'
                    placeholder='555 55 55'
                    className='w-100 rezervationinput mt-1'
                    id='phone'
                    name='phone'
                    value={formData.phone}
                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ""); }}
                    onChange={handleChange} onFocus={() => handleFocus('phone')} />
                  <span style={{ color: 'red' }}>{errors.phone}</span>
                </div>

              </div>

              <div className='mt-3'>{t('registerForm.country')}</div>
              <div>
                <Select
                  className='w-100 mt-1 fnt08rem '
                  onChange={(selectedOption) => handleSelectChange(selectedOption, "country")}
                  onFocus={() => handleFocus('country_id')}
                  options={countries.data.map((country, index) => ({
                    value: country.id,
                    label: country.country,
                  }))}
                  value={selectedCountry ? { value: selectedCountry.id, label: selectedCountry.country } : null}

                />
              </div>
              <span style={{ color: 'red' }}>{errors.country_id}</span>



              <div className='mt-3 fnt14px'>{t('registerForm.hotelName')}</div>
              <div >
                <input type='text' placeholder={t('registerForm.enterHotelName')} className='w-100 rezervationinput mt-1' name="hotel_name" value={formData.hotel_name} onChange={handleChange} />
              </div>

              <div className='row mt-3'>
                <div className='col-3 d-flex'>
                  <input
                    type='radio'
                    name='gender'
                    value='MALE'
                    checked={formData.gender === 'MALE'}
                    onChange={handleOptionChange}
                  />
                  <div className='ms-2'>{t('registerForm.male')}</div>
                </div>
                <div className='col-9 d-flex ps-0'>
                  <input
                    type='radio'
                    name='gender'
                    value='WOMAN'
                    checked={formData.gender === 'WOMAN'}
                    onChange={handleOptionChange}
                  />
                  <div className='ms-2'>{t('registerForm.female')}</div>
                </div>
              </div>
              <span style={{ color: 'red' }}>{errors.gender}</span>

              <div className='mt-3 fnt14px'>{t('registerForm.notes')}</div>
              <div >
                <textarea type='text' placeholder={t('registerForm.enterNotes')} className='w-100 rezervationinput mt-1' name="comment" value={formData.comment} onChange={handleChange} onFocus={() => handleFocus('comment')} />
              </div>
              <div className='mt-3 fnt12px d-flex'>
                <input
                  type='checkbox'
                  name='checkMsg'
                  checked={true}
                  id='checkMsg'
                />
                <label htmlFor='checkMsg' className='ms-2'>
                {t('registerForm.retainAdress')}
                </label>
              </div>
              <span style={{ color: 'red' }}>{errors.checkMsg}</span>
              <div className='mt-3 fnt12px d-flex'>
                <input
                  type='checkbox'
                  name='checkbox'
                  onChange={handleChange}
                  checked={checkboxChecked}
                  id='checkbox'
                />
                <label htmlFor='checkbox' className='ms-2'>
                {t('registerForm.agreement')}
                </label>
              </div>
              <span style={{ color: 'red' }}>{errors.checkbox}</span>
              <div className='mt-3 fnt12px d-flex'>
                <input
                  type='checkbox'
                  name='checkMsgSecond'
                  onChange={handleChange}
                  checked={checkMsgSecond}
                  id='checkMsgSecond'
                />
                <label htmlFor='checkMsgSecond' className='ms-2'>
                {t('registerForm.informMe')}
                </label>
              </div>
              <div className='mt-4'>
                <button className='btn text-white justbtnwhite w-100 mt-2 fw-bold' onClick={handleSubmit} style={{ borderRadius: "60px" }}>Continue</button>
              </div>
            </div>

          }
        </div>

      }




    </div>

  )
}

export default RegisterForBuy