import React, { useEffect } from 'react'
import Header from '../components/header';
import Footer from '../components/footer';
import Comment from '../components/comment';
import PagesContentSection from '../components/pagesContentSection';
import BannerPages from '../components/bannerPages';

import { useSelector, useDispatch } from 'react-redux'
import { getContentPageApi } from './api/contentPageApi/contentPageApiSlice';
import { useParams } from 'react-router-dom';
import LoadingPage from './loadingPage';

const Pages = () => {

  const dispatch = useDispatch();
  const {slug} = useParams();
  document.title =`${slug}  | Cappadocia Visitor`
  const data = useSelector(state => state.contentPageApi.contentPageApi.data);
  const isLoading = useSelector(state => state.contentPageApi.isLoading);
  useEffect(()=>{
      dispatch(getContentPageApi(slug))
   },[dispatch]);


  if(isLoading) {
    return (<LoadingPage/>);
  };

  return (
    <div className="container-fluid w-100 h-100 p-0" >
    <Header/>
    <BannerPages props={data}/>
    <PagesContentSection props={data}/>
    <Footer/> 
  </div>
  )
}

export default Pages