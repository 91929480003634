import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate';
import FeaturedCard from './featuredcard';
import { Dropdown, DropdownButton, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux'
import { getPaginateApi } from './api/paginateTour/paginateApiSlice';
import LoadingPage from './loadingPage';
import { Link, useParams } from 'react-router-dom';
import { getTourCategories } from './api/paginateTour/tourCategoriesSlice';
import { useTranslation } from 'react-i18next';


const TourCategorySection = () => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(0);
    const breakpoint = '(max-width: 975px)';
    const isMobile = useMediaQuery({ query: breakpoint });
    const dispatch = useDispatch();
    const { slug } = useParams();

    useEffect(() => {
        setCurrentPage(0)
    }, [slug])

    const data = useSelector(state => state.paginateApi.paginateApi);
    const categoriesTitleList = useSelector(state => state.tourCategories.tourCategories.data);
    const mapData = data.data;
    const metaData = data.meta;
    const isLoading = useSelector(state => state.paginateApi.isLoading);
    document.title =`${data?.infos?.category?.title} | Cappadocia Visitor`;

    useEffect(() => {
        dispatch(getPaginateApi({ slug: slug, currentPage: currentPage + 1 }))
        dispatch(getTourCategories())
    }, [dispatch, currentPage, slug]);

    if (isLoading) {
        return (<LoadingPage />);
    };

    const handlePageClick = (data) => {
        const selectedPage = data.selected;
        setCurrentPage(selectedPage);
    };
    // Şu anki sayfadaki öğeleri 
    return (
        <div className='blogSection tourPagination'>
            <div className='bgblue w-100 ps-md-1'>
                <h1 className='text-white baslik1 p-md-5 p-3  ms-md-5' style={{ textTransform: 'uppercase' }}>{data.infos.category.title}</h1>
            </div>
            <div className='blogSectionMain mediaQueryPadding pt-4'>
                <div className='titleSection d-flex align-items-center pb-2'>
                    {isMobile ? (
                        <div className='d-flex align-items-center justify-content-end w-100'>
                            <DropdownButton title="Categories">
                                {(categoriesTitleList).map((item, index) => (
                                    <div key={index}>
                                        <Dropdown.Item>
                                            <Link to={`/category/${item.slug}`} className='custom-link' key={index}>
                                                {item.title}
                                            </Link>
                                        </Dropdown.Item>
                                    </div>

                                ))}
                            </DropdownButton>
                        </div>
                    ) : ("")}
                </div>
                <div className='row'>
                    <div className='col-md-9 col-xs-12 col-sm-12'>
                        <div className='blogSectionLeft'>
                            <div className="blogPaginationSection categoryPageSection">
                                {mapData.length > 0 ?
                                    <div className='blogGrid'>
                                        {(mapData).map((item, index) => (
                                            <div key={index}>
                                                <FeaturedCard
                                                    title={item.title}
                                                    content={item.get_type.title}
                                                    price={item.price}
                                                    saleprice={item.total_price}
                                                    star={item.star}
                                                    image={item.featured_file}
                                                    slug={item.slug}
                                                    tourId={item.id}
                                                    isFavorited={item.favorited}
                                                    perType={item.per_type.per_text}

                                                    type="paginateCard"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        {t('tourCategory.noTours')}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='d-flex justify-content-center align-items-center pe-4 pt-5 pb-5'>
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={metaData.last_page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </div>
                    </div>

                    {isMobile ? (
                        ""
                    ) : (
                        <Col md={3} xs={12} sm={12}>
                            <div className="bg-white w-100 informationSection">
                                <div className="title">
                                    <div className="d-flex justify-content-center align-items-center pt-4 fw-bold">
                                        <h3>{t('blogPage.categories')}</h3>
                                    </div>
                                </div>
                                <div className="informationSectionBody pt-4">
                                    {(categoriesTitleList).map((item, index) => (
                                        <Link to={`/category/${item.slug}`} className='custom-link' key={index}>
                                            <div className={`ps-5 pt-4 pb-4 ${index !== categoriesTitleList.length - 1 ? 'borderbottomdashed' : ''} d-flex align-items-center`}>
                                                <i className="fas fa-chevron-right"></i>
                                                <div className="ps-md-5 ps-sm-2 fw-bold">{item.title}</div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </Col>

                    )}



                </div>



            </div>
        </div>

    )
}

export default TourCategorySection