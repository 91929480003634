import React, { useEffect, useState, useCallback } from 'react';
import { getSettingsApi } from './api/settings/settingsApiSlice';
import { useSelector, useDispatch } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SearchResultsCollapse from './searchResults';
import debounce from 'lodash/debounce';
import { getSearchApi } from './api/search/searchApiSlice';
import HeaderImg from './headerImg';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../provider/LanguageContext';
import ReactCountryFlag from "react-country-flag"
import i18n from '../i18n'; // your i18n configuration file



function Header({ currentDate, setCurrentTerm }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };


  const location = useLocation();
  const { slug } = useParams();
  const isSearchPage = location.pathname !== `/search/${slug}`;
  const count = useSelector((state) => state.counter.value);
  const token = localStorage.getItem("token");

  const logOutToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  useEffect(() => {
    dispatch(getSettingsApi());
  }, [dispatch, slug]);

  const scrollContact = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const [isLoading, setIsLoading] = useState(true);

  const generalSettingsData = useSelector(state => state.settingsApi.generalSettingsApi.data);
  const socialsData = useSelector(state => state.settingsApi.socialsSettingsApi.data);

  useEffect(() => {
    if (generalSettingsData && socialsData) {
      setIsLoading(false);
    }
  }, [generalSettingsData, socialsData]);

  const getValue = (key) => {
    if (!generalSettingsData) {
      return 'Loading...';
    }
    const foundItem = generalSettingsData.find((item) => item.key === key);
    return foundItem ? foundItem.value : 'Not found';
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((searchQuery, date) => {
      dispatch(getSearchApi({ slug: searchQuery, selectDate: date }));
    }, 300),
    [dispatch]
  );

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);

    if (isSearchPage) {
      if (event.target.value !== '') {
        setIsCollapseOpen(true);
      } else {
        setIsCollapseOpen(false);
      }
    }

    if (!isSearchPage) {
      if (event.target.value.length > 1) {
        debouncedSearch(event.target.value, currentDate);
        setCurrentTerm(event.target.value);
      }
    } else {
      if (event.target.value.length > 1) {
        debouncedSearch(event.target.value, null);
      }
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    navigate(`/search/${searchTerm}`);
  };

  const searchResults = useSelector(state => state.searchApi.searchApi.data);
  
  const languageFlags = {
    en: 'GB',
    es: 'ES',
    cn: 'CN',
    };

  if (isLoading) {
    return "";
  };


  // URL'deki query parametrelerini kontrol etme
  const testQuery = new URLSearchParams(location.search);
  const showLanguageButtons = testQuery.get('test') === 'elif2024';


  return (
    <header>
    <Navbar expand="xl" className='p-md-5 p-3 pb-3 ps-4 pb-md-4'>
      <Navbar.Brand style={{ paddingLeft: '5rem' }}>
        <Nav.Link href="/">
          <img className='headerLogo' src={getValue("logo")} alt="logo" />
        </Nav.Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarNavDropdown" />
      <Navbar.Collapse id="navbarNavDropdown" className='justify-content-end'>
        <Nav className="ml-auto d-flex justify-content-evenly ps-md-5 me-4">
          <Nav.Link href="/">{t('header.home')}</Nav.Link>
          <Nav.Link href="/tourlist">{t('header.tours')}</Nav.Link>
          <Nav.Link href="/activities">{t('header.activities')}</Nav.Link>
          <Nav.Link href="/balloontours">{t('header.balloonTours')}</Nav.Link>
          <Nav.Link href="/transfer">{t('header.transfers')}</Nav.Link>
          <Nav.Link href="/blog">{t('header.blogs')}</Nav.Link>
          <Nav.Link href="/pages/about">{t('header.about')}</Nav.Link>
          <div className='headerIcon d-flex'>
            {token ? (
              <Dropdown data-bs-theme="dark">
                <Dropdown.Toggle className='navbarDropdown pt-2 nav-link'>
                  <HeaderImg type="profile" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href='/profile'>
                    {t('header.profile')}
                  </Dropdown.Item>
                  <Dropdown.Item href="/favorites">
                    {t('header.favoriteList')}
                  </Dropdown.Item>
                  <Dropdown.Item href="/" onClick={logOutToken}>
                    {t('header.logOut')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Nav.Link className='loginHeader' href="/login">{t('header.joinUs')}</Nav.Link>
            )}
            <Nav.Link href="/cart">
              <div className="position-relative">
                <HeaderImg type="cart" />
                {count > 0 && <div className='cartCount'><span>{count}</span></div>}
              </div>
            </Nav.Link>
            <Nav.Link onClick={scrollContact}><HeaderImg type="contact" /></Nav.Link>
          </div>
          {showLanguageButtons && (
       <div class="languageSelect">

       <NavDropdown id="basic-nav-dropdown"  title={
         <ReactCountryFlag
             countryCode={languageFlags[i18n.language]}
             svg
             style={{
                 width: '2em',
                 height: '2em',
             }}
             title={i18n.language}
         />} >
         <NavDropdown.Item onClick={() => changeLanguage('en')}>
         <ReactCountryFlag
             countryCode={"GB"}
             svg
             style={{
                 width: '2em',
                 height: '2em',
             }}
             title="GB"
         />
           </NavDropdown.Item>
         <NavDropdown.Item onClick={() => changeLanguage('es')}>
         <ReactCountryFlag
             countryCode="ES"
             svg
             style={{
                 width: '2em',
                 height: '2em',
             }}
             title="ES"
         />
           </NavDropdown.Item>
           <NavDropdown.Item onClick={() => changeLanguage('cn')}>
         <ReactCountryFlag
             countryCode="CN"
             svg
             style={{
                 width: '2em',
                 height: '2em',
             }}
             title="CN"
         />
           </NavDropdown.Item>
       </NavDropdown>
       </div>
      )} 
        

        </Nav>
      </Navbar.Collapse>
    </Navbar>
    
    <form onSubmit={handleSearch} className='ps-md-5 pe-md-5 ps-3 pe-3'>
      <div className='w-100 d-flex bannerSearchDiv'>
        <input
          className='bannerSearch'
          type="text"
          onChange={handleChange}
        />
        <button type="submit"><HeaderImg type="search" /></button>
      </div>
      {isCollapseOpen && searchTerm.length > 1 && <SearchResultsCollapse results={searchResults} className='blueCollapse' />}
    </form>
  </header>
  );
}

export default Header;
