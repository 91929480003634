import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import imagesCong from './images/congrats.png';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const CongratulationsModal = () => {
  const { t } = useTranslation();

  const { slug } = useParams();
  const location = useLocation();

const [searchParams] = useSearchParams();
const product_type = searchParams.get("product_type"); 
 
  useEffect(() => {
    if(product_type==="TRANSFER"){
      window.top.location.href = `/vouchertransfer/${slug}`;
    }else{
      if (location.pathname.startsWith("/success/ORD-GRP")) {
        window.top.location.href = `/vouchergrp/${slug}`;
      } else if (location.pathname.startsWith("/success/ORD")) {
        window.top.location.href = `/voucher/${slug}`;
      }
    }
    

      
  }, [slug, product_type, location.pathname]);

  return (
    <div className='h-100 pt-5'>
      <div className='d-flex align-items-center justify-content-center'>
        <img src={imagesCong} alt='successImg' className='imgPaymentResponse' />
      </div>
      <div className='text-center'>
        <h1 className='text-warning fw-bold pt-5 mt-5'>{t('congratulations.congrats')}</h1>
        <p className='text-secondary fw-bold pt-4 fnt18rem'>{t('congratulations.paymentSuccess')}</p>
      </div>
    </div>
  )
}

export default CongratulationsModal;
