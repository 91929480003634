import React, { useEffect, useState } from 'react'
import authRequest from '../api/requests';
import { useMediaQuery } from 'react-responsive';
import { Modal, Button } from 'react-bootstrap';

const CartCreditForm = (props) => {
    const breakpoint = '(max-width: 975px)';

    const isMobile = useMediaQuery({ query: breakpoint });
    const personData = props.props;
    
    const [response, setResponse] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [responseError, setResponseError] = useState();

    const [formData, setFormData] = useState({
        card_name: '',
        card_surname: '',
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        cvc: '',
    });
    const [errors, setErrors] = useState({
        card_name: '',
        card_surname: '',
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        cvc: '',
        serviceAgreement: '',
        privacyPolicy: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    // function isValidLuhn(cardNumber) {
    //     let sum = 0;
    //     let isEven = false;

    //     // Iterate over each digit of the card number from right to left
    //     for (let i = cardNumber.length - 1; i >= 0; i--) {
    //         let digit = parseInt(cardNumber.charAt(i), 10);

    //         // Check if the character is a valid digit
    //         if (isNaN(digit)) {
    //             return false; // If it's not a digit, return false immediately
    //         }

    //         if (isEven) {
    //             digit *= 2;
    //             if (digit > 9) {
    //                 digit -= 9;
    //             }
    //         }

    //         sum += digit;
    //         isEven = !isEven;
    //     }

    //     return sum % 10 === 0;
    // }
    function formatCardNumber(value) {
        if (!value) {
            return '';
        }
        var strippedValue = value.replace(/\s/g, '');
        strippedValue = strippedValue.slice(0, 16);
        var formattedNumber = strippedValue.match(/.{1,4}/g).join(' ');

        return formattedNumber;
    }

    const handleFocus = (name) => {
        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formIsValid = true;
        const newErrors = { ...errors };

        // Validation rules
        if (formData.card_name.trim() === '') {
            formIsValid = false;
            newErrors.card_name = 'Name is required';
        }
        if (formData.card_surname.trim() === '') {
            formIsValid = false;
            newErrors.card_surname = 'Surname is required';
        }

        // Card Number validation
        if (formData.cardNumber.trim() === '') {
            formIsValid = false;
            newErrors.cardNumber = 'Card Number is required';

        } 



        // else if (!isValidLuhn(formData.cardNumber.replace(/\D/g, ''))) {
        //     formIsValid = false;
        //     newErrors.cardNumber = 'Invalid Card Number';
        // }

        // Expiration Month validation
        if (formData.expirationMonth.trim() === '') {
            formIsValid = false;
            newErrors.expirationMonth = 'Expiration Month is required';
        } else if (!/^\d{2}$/.test(formData.expirationMonth) || formData.expirationMonth < 1 || formData.expirationMonth > 12) {
            formIsValid = false;
            newErrors.expirationMonth = 'Invalid Expiration Month';
        }

        // Expiration Year validation
        if (formData.expirationYear.trim() === '') {
            formIsValid = false;
            newErrors.expirationYear = 'Expiration Year is required';
        } else if (!/^\d{4}$/.test(formData.expirationYear) || parseInt(formData.expirationYear) < new Date().getFullYear()) {
            formIsValid = false;
            newErrors.expirationYear = 'Invalid Expiration Year';
        }

        // CVC validation
        if (formData.cvc.trim() === '') {
            formIsValid = false;
            newErrors.cvc = 'CVC is required';
        } else if (!/^\d{3,4}$/.test(formData.cvc)) {
            formIsValid = false;
            newErrors.cvc = 'Invalid CVC format';
        }

        // Service Agreement validation
        if (!formData.serviceAgreement) {
            formIsValid = false;
            newErrors.serviceAgreement = 'You must accept the Service Agreement';
        }

        // Privacy Policy validation
        if (!formData.privacyPolicy) {
            formIsValid = false;
            newErrors.privacyPolicy = 'You must accept the Privacy Policy';
        }

        setErrors(newErrors);

        if (!formIsValid && isMobile) {
            alert("please fill in the required fields")
        }



        if (formIsValid) {

            const cardForm = {
                cc_name: formData.card_name,
                cc_surname: formData.card_surname,
                cardNumber: formData.cardNumber,
                expirationMonth: formData.expirationMonth,
                expirationYear: formData.expirationYear,
                cvc: formData.cvc,
                ...personData
            };
            authRequest("/api/v1/tours/reservation-multiple", "POST", cardForm, setResponse, setIsLoading, setResponseError);

        }

    };

    // ***ErorModal*****
    const [errorModal, setErrorModal] = useState(false);
    const handleCloseFailureModal = () => {
        setErrorModal(false);
    };
    useEffect(() => {
        if (responseError?.data?.error) {
            setErrorModal(true)
        } else {
            setErrorModal(false)
        }
    }, [responseError]);

    // ********


    return (
        <div>
            {response ? <div className='responsePayment'><iframe title="paymentiFrame" src={process.env.REACT_APP_API_URL + `/paymentForm?data=${response.paymentForm}`} /></div> :
                <div className='mt-4'>


                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-md-6 col-sm-12 col-xs-12'>
                                <div className='d-flex'>
                                    <div className='w-50 pe-2'>
                                        <div>Name</div>
                                        <div className='mt-2'>
                                            <input
                                                type='text'
                                                className='rezervationinput w-100'
                                                placeholder='Thomas'
                                                name='card_name'
                                                value={formData.card_name}
                                                onChange={handleChange}
                                                onFocus={() => handleFocus('card_name')}
                                            />
                                            {errors.card_name && <span className="text-danger">{errors.card_name}</span>}
                                        </div>
                                    </div>

                                    <div className='w-50'>
                                        <div>Surname</div>
                                        <div className='mt-2'>
                                            <input
                                                type='text'
                                                className='rezervationinput w-100'
                                                placeholder='Edison'
                                                name='card_surname'
                                                value={formData.card_surname}
                                                onChange={handleChange}
                                                onFocus={() => handleFocus('card_surname')}
                                            />
                                            {errors.card_surname && <span className="text-danger">{errors.card_surname}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <div>Card Number</div>
                                    <div className='mt-2'>
                                        <input
                                            type='text'
                                            className='rezervationinput w-100'
                                            placeholder='0000 000 000 00 00'
                                            name='cardNumber'
                                            value={formatCardNumber(formData.cardNumber)}
                                            onChange={handleChange}
                                            onFocus={() => handleFocus('cardNumber')}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                            }}

                                        />
                                        {errors.cardNumber && <span className="text-danger">{errors.cardNumber}</span>}
                                    </div>
                                </div>

                                <div className='mt-3'>
                                    <div className='d-flex'>
                                        <div>
                                            <div>Month</div>
                                            <div className='mt-2'>
                                                <input
                                                    type='text'
                                                    className='rezervationinput w-100'
                                                    placeholder='MM'
                                                    name='expirationMonth'
                                                    value={formData.expirationMonth}
                                                    onChange={handleChange}
                                                    onFocus={() => handleFocus('expirationMonth')}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                                    }}
                                                    maxLength="2"
                                                    pattern="[0-9]*"
                                                />
                                                {errors.expirationMonth && <span className="text-danger">{errors.expirationMonth}</span>}
                                            </div>
                                        </div>
                                        <div className='px-4'>
                                            <div>Year</div>
                                            <div className='mt-2'>
                                                <input
                                                    type='text'
                                                    className='rezervationinput w-100'
                                                    placeholder='YYYY'
                                                    name='expirationYear'
                                                    value={formData.expirationYear}
                                                    onFocus={() => handleFocus('expirationYear')}
                                                    onChange={handleChange}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                                    }}
                                                    maxLength="4"
                                                    pattern="[0-9]*"
                                                />
                                                {errors.expirationYear && <span className="text-danger">{errors.expirationYear}</span>}
                                            </div>
                                        </div>
                                        <div>
                                            <div>CVC</div>
                                            <div className='mt-2'>
                                                <input
                                                    type='text'
                                                    className='rezervationinput w-100'
                                                    placeholder='CVC'
                                                    name='cvc'
                                                    value={formData.cvc}
                                                    onChange={handleChange}
                                                    onFocus={() => handleFocus('cvc')}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                                    }}
                                                    maxLength="4"
                                                    pattern="[0-9]*"
                                                />
                                                {errors.cvc && <span className="text-danger">{errors.cvc}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>          </div>
                            <div className='col-md-6 col-sm-12 col-xs-12'>
                                <div className='cardimage'></div>
                            </div>
                        </div>
                        <div className='col-md-12 col-xs-12 col-sm-12 pt-5'>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={formData.serviceAgreement}
                                    id="service"
                                    name="serviceAgreement"
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="service">
                                    I have read and accept the Service Agreement regarding the reservation.
                                </label>
                                {errors.serviceAgreement && <span className="text-danger">{errors.serviceAgreement}</span>}
                            </div>
                            <div className="form-check mt-2">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={formData.privacyPolicy}
                                    id="privacy"
                                    name="privacyPolicy"
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="privacy">
                                    I have read and approved the Personal Data Protection and Privacy Policy.
                                </label>
                                {errors.privacyPolicy && <span className="text-danger">{errors.privacyPolicy}</span>}
                            </div>
                        </div>
                        <div className='col-md-12 col-xs-12 col-sm-12 pt-5'>
                            <button type="submit" className="btn p-3 bigbluebtn w-100 mt-3">Pay Now</button>
                        </div>
                    </form>
                </div>
            }

            <Modal show={errorModal} onHide={handleCloseFailureModal} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Payment Failed!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {responseError?.data?.message}
                </Modal.Body>
                <Modal.Footer>
                    <Button className='bgblue' onClick={handleCloseFailureModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CartCreditForm;

