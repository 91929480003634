import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import authRequest from './api/requests';
import { useMediaQuery } from 'react-responsive';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const CreditTransfer = ({ data }) => {
    const { t } = useTranslation();
    const breakpoint = '(max-width: 975px)';
    const isMobile = useMediaQuery({ query: breakpoint });
    const transferData = useSelector(state => state.transferInfo)
    const returnLocations = useSelector(state => state.transferApi.returnLocations)
    const pickupLocations = useSelector(state => state.transferApi.pickupLocations)
    const selectableProducts = useSelector(state => state.transferApi.products)

    // ******id ile name esitleme ****
    const picupLocation = (pickupLocations).find(item => item.id === parseInt(transferData.pickup_location_id));
    const returnLocation = (returnLocations).find(item => item.id === parseInt(transferData.return_location_id));
    const selectedProducst = (selectableProducts).find(item => item.id === parseInt(transferData.product_id));
    const prices = useSelector(state => state.transferApi.prices)


    const [response, setResponse] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [responseError, setResponseError] = useState();



    const [formData, setFormData] = useState({
        card_name: '',
        card_surname: '',
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        cvc: '',
    });
    const [errors, setErrors] = useState({
        card_name: '',
        card_surname: '',
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        cvc: '',
        serviceAgreement: '',
        privacyPolicy: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    // function isValidLuhn(cardNumber) {
    //     let sum = 0;
    //     let isEven = false;

    //     // Iterate over each digit of the card number from right to left
    //     for (let i = cardNumber.length - 1; i >= 0; i--) {
    //         let digit = parseInt(cardNumber.charAt(i), 10);

    //         // Check if the character is a valid digit
    //         if (isNaN(digit)) {
    //             return false; // If it's not a digit, return false immediately
    //         }

    //         if (isEven) {
    //             digit *= 2;
    //             if (digit > 9) {
    //                 digit -= 9;
    //             }
    //         }

    //         sum += digit;
    //         isEven = !isEven;
    //     }

    //     return sum % 10 === 0;
    // }

    function formatCardNumber(value) {
        if (!value) {
            return '';
        }
        var strippedValue = value.replace(/\s/g, '');
        strippedValue = strippedValue.slice(0, 16);
        var formattedNumber = strippedValue.match(/.{1,4}/g).join(' ');

        return formattedNumber;
    }

    const handleFocus = (name) => {
        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formIsValid = true;
        const newErrors = { ...errors };

        // Validation rules
        if (formData.card_name.trim() === '') {
            formIsValid = false;
            newErrors.card_name = t('paymentForm.nameisrequired');
        }

        if (formData.card_surname.trim() === '') {
            formIsValid = false;
            newErrors.card_surname = t('paymentForm.surnameisrequired');
        }

        // Card Number validation
        if (formData.cardNumber.trim() === '') {
            formIsValid = false;
            newErrors.cardNumber = t('paymentForm.cardnumberisrequired');
        }
        // } else if (!isValidLuhn(formData.cardNumber.replace(/\D/g, ''))) {
        //     formIsValid = false;
        //     newErrors.cardNumber = 'Invalid Card Number';
        // }

        // Expiration Month validation
        if (formData.expirationMonth.trim() === '') {
            formIsValid = false;
            newErrors.expirationMonth = t('paymentForm.expirationmonthisrequired');
        } else if (!/^\d{2}$/.test(formData.expirationMonth) || formData.expirationMonth < 1 || formData.expirationMonth > 12) {
            formIsValid = false;
            newErrors.expirationMonth = t('paymentForm.invalidExpirationMonth');
        }

        // Expiration Year validation
        if (formData.expirationYear.trim() === '') {
            formIsValid = false;
            newErrors.expirationYear = t('paymentForm.expirationYearisrequired');
        } else if (!/^\d{4}$/.test(formData.expirationYear) || parseInt(formData.expirationYear) < new Date().getFullYear()) {
            formIsValid = false;
            newErrors.expirationYear = t('paymentForm.invalidExpirationYear');
        }

        // CVC validation
        if (formData.cvc.trim() === '') {
            formIsValid = false;
            newErrors.cvc = t('paymentForm.cvcisrequired');
        } else if (!/^\d{3,4}$/.test(formData.cvc)) {
            formIsValid = false;
            newErrors.cvc = t('paymentForm.invalidCvc');
        }

        // Service Agreement validation
        if (!formData.serviceAgreement) {
            formIsValid = false;
            newErrors.serviceAgreement = t('paymentForm.serviceAgreement');
        }

        // Privacy Policy validation
        if (!formData.privacyPolicy) {
            formIsValid = false;
            newErrors.privacyPolicy = t('paymentForm.privacyPolicy');
        }

        setErrors(newErrors);


        if (!formIsValid && isMobile) {
            alert(t('paymentForm.fillAllFields'))
        }

        if (formIsValid) {

            const cardForm = {
                cc_name: formData.card_name,
                cc_surname: formData.card_surname,
                cardNumber: formData.cardNumber,
                expirationMonth: formData.expirationMonth,
                expirationYear: formData.expirationYear,
                cvc: formData.cvc,
                ...transferData
            };
            authRequest("/api/v1/transfers/store", "POST", cardForm, setResponse, setIsLoading, setResponseError);

        }

    };


// ***ErorModal*****
const [errorModal, setErrorModal] = useState(false);
const handleCloseFailureModal = () => {
    setErrorModal(false);
  };
useEffect(() => {
    if(responseError?.data?.error){
        setErrorModal(true)
    }else{
        setErrorModal(false)
    }
}, [responseError]);

// ********


    return (
        <div className='row'>
            <div className='col-md-4 col-sm-12 col-xs-12 paymentModalMQCol'>
                <div className='bannersearch text-white bgblue p-5 h-100'>
                    <div className='fnt14rem'>{t('paymentModal.resDetail')}</div>
                    <div className='mt-3 borderdashed pb-4'>
                        <div className='fnt10rem mqModalFsize'>{t('transfer.fromAirHot')}</div>
                        <div className='fnt08rem text-grey mt-1'>{picupLocation.name}</div>
                    </div>
                    <div className='mt-4 borderdashed pb-4'>
                        <div className='fnt10rem mqModalFsize'>{t('transfer.toAirHot')}</div>
                        <div className='fnt08rem text-grey mt-1'>{returnLocation.name}</div>
                    </div>
                    <div className='mt-4 borderdashed pb-4'>
                        <div className='d-flex align-items-center'>
                            <div className='mqModalFsize' style={{ flexGrow: "1" }}>{t('date')}</div>
                            <div><div className='fnt08rem mqModalFsizeSecond'>{transferData.reservation_date}</div></div>
                        </div>
                    </div>
                    {selectedProducst &&
                        <div>
                            <div className='mt-3 pb-5'>
                                <div className='d-flex align-items-center'>
                                    <div className='mqModalFsize' style={{ flexGrow: "1" }}>{t('transfer.selectedProducts')}</div>
                                    <div><div className='fnt08rem mqModalFsizeSecond'>{transferData.person}</div></div>
                                </div>
                            </div>
                            <div className='bg-white borderrad12 p-3 text-muted fnt08rem mb-5'>{selectedProducst.name}: {selectedProducst.price} EUR</div>
                        </div>
                    }

                    <div className='mt-3 pb-4'>
                        <div className='d-flex align-items-center'>
                            <div className='mqModalFsize' style={{ flexGrow: "1" }}>{t('transfer.amount')}</div>
                            <div><div className='fnt16rem mqModalFsizeSecond'>{prices.total_price} EUR</div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-8 col-sm-12 col-xs-12 paymentModalForm paymentModalMQCol'>
                <div>
                    {response ? <div className='responsePayment'><iframe title="paymentiFrame" src={process.env.REACT_APP_API_URL + `/paymentForm?data=${response.paymentForm}`} /></div> :
                        <div className='mt-4'>


                            <form onSubmit={handleSubmit}>
                                <div className='modalForm fnt14px'>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12 col-xs-12'>
                                        <div className='d-flex'>
                                            <div className='w-50 pe-2'>
                                                <div>{t('registerForm.name')}</div>
                                                <div className='mt-2'>
                                                    <input
                                                        type='text'
                                                        className='rezervationinput w-100'
                                                        placeholder='Thomas'
                                                        name='card_name'
                                                        value={formData.card_name}
                                                        onChange={handleChange}
                                                        onFocus={() => handleFocus('card_name')}
                                                    />
                                                    {errors.card_name && <span className="text-danger">{errors.card_name}</span>}
                                                </div>
                                            </div>

                                            <div className='w-50'>
                                                <div>{t('registerForm.surname')}</div>
                                                <div className='mt-2'>
                                                    <input
                                                        type='text'
                                                        className='rezervationinput w-100'
                                                        placeholder='Edison'
                                                        name='card_surname'
                                                        value={formData.card_surname}
                                                        onChange={handleChange}
                                                        onFocus={() => handleFocus('card_surname')}
                                                    />
                                                    {errors.card_surname && <span className="text-danger">{errors.card_surname}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <div>{t('paymentForm.cardNumber')}</div>
                                            <div className='mt-2'>
                                                <input
                                                    type='text'
                                                    className='rezervationinput w-100'
                                                    placeholder='0000 000 000 00 00'
                                                    name='cardNumber'
                                                    value={formatCardNumber(formData.cardNumber)}
                                                    onChange={handleChange}
                                                    onFocus={() => handleFocus('cardNumber')}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                                    }}

                                                />
                                                {errors.cardNumber && <span className="text-danger">{errors.cardNumber}</span>}
                                            </div>
                                        </div>

                                        <div className='mt-3'>
                                            <div className='d-flex'>
                                                <div>
                                                    <div>{t('paymentForm.month')}</div>
                                                    <div className='mt-2'>
                                                        <input
                                                            type='text'
                                                            className='rezervationinput w-100'
                                                            placeholder='MM'
                                                            name='expirationMonth'
                                                            value={formData.expirationMonth}
                                                            onChange={handleChange}
                                                            onFocus={() => handleFocus('expirationMonth')}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                                            }}
                                                            maxLength="2"
                                                            pattern="[0-9]*"
                                                        />
                                                        {errors.expirationMonth && <span className="text-danger">{errors.expirationMonth}</span>}
                                                    </div>
                                                </div>
                                                <div className='px-4'>
                                                    <div>{t('paymentForm.year')}</div>
                                                    <div className='mt-2'>
                                                        <input
                                                            type='text'
                                                            className='rezervationinput w-100'
                                                            placeholder='YYYY'
                                                            name='expirationYear'
                                                            value={formData.expirationYear}
                                                            onFocus={() => handleFocus('expirationYear')}
                                                            onChange={handleChange}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                                            }}
                                                            maxLength="4"
                                                            pattern="[0-9]*"
                                                        />
                                                        {errors.expirationYear && <span className="text-danger">{errors.expirationYear}</span>}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>CVC</div>
                                                    <div className='mt-2'>
                                                        <input
                                                            type='text'
                                                            className='rezervationinput w-100'
                                                            placeholder='CVC'
                                                            name='cvc'
                                                            value={formData.cvc}
                                                            onChange={handleChange}
                                                            onFocus={() => handleFocus('cvc')}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                                            }}
                                                            maxLength="4"
                                                            pattern="[0-9]*"
                                                        />
                                                        {errors.cvc && <span className="text-danger">{errors.cvc}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>          </div>
                                    <div className='col-md-6 col-sm-12 col-xs-12'>
                                        <div className='cardimage'></div>
                                    </div>
                                </div>
                                <div className='col-md-12 col-xs-12 col-sm-12 pt-5'>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={formData.serviceAgreement}
                                            id="service"
                                            name="serviceAgreement"
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="service">
                                            {t('paymentForm.readAgreement')}
                                        </label>
                                        {errors.serviceAgreement && <span className="text-danger">{errors.serviceAgreement}</span>}
                                    </div>
                                    <div className="form-check mt-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={formData.privacyPolicy}
                                            id="privacy"
                                            name="privacyPolicy"
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="privacy">
                                        {t('paymentForm.readPrivacy')}
                                        </label>
                                        {errors.privacyPolicy && <span className="text-danger">{errors.privacyPolicy}</span>}
                                    </div>
                                </div>
                                <div className='col-md-12 col-xs-12 col-sm-12 pt-5'>
                                    <button type="submit" className="btn p-3 bigbluebtn w-100 mt-3">{t('paymentForm.pay')}</button>
                                </div>
                                </div>

                            </form>
                        </div>
                    }
                </div>
            </div>
            <Modal show={errorModal} onHide={handleCloseFailureModal} centered >
                <Modal.Header closeButton>
                  <Modal.Title>{t('paymentForm.payFailed')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {responseError?.data?.message}
                </Modal.Body>
                <Modal.Footer>
                  <Button className='bgblue' onClick={handleCloseFailureModal}>
                  {t('paymentForm.close')}
                  </Button>
                </Modal.Footer>
              </Modal>
        </div>
    )
}

export default CreditTransfer