import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';



const initialState = {
    blogApi: [],
    isLoading: true
}

export const getBlogApi = createAsyncThunk('getBlogApi', async(getPage) =>{
    const {data} = await axios.get(process.env.REACT_APP_API_URL + `/blogs?page=${getPage}`)
    return data
})

export const blogApiSlice = createSlice({
    name: "blogApi",
    initialState,
    reducers :{

    },
    extraReducers: (builder) => {
        builder.addCase(getBlogApi.fulfilled, (state, action) => {
            state.blogApi = action.payload;
            state.isLoading = false
        }) 
    }
})


export default blogApiSlice.reducer