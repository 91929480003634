import { createSlice } from '@reduxjs/toolkit'

const transferInfo = createSlice({
  name: 'transferInfo',
  initialState: {
    transfer_group_id: '',
    reservation_date: '',
    pickup_location_id: '',
    return_location_id: '',
    reservation_time: '',
    product_id:[],  
  },
  reducers: {
    setTransferInfo: (state, action) => {
      return { ...state, ...action.payload };
    },
    setReservation_date: (state, action) => {
      state.reservation_date = action.payload;
    },
    setProduct_id: (state, action) => {
      state.product_id = action.payload;
    },
    setChangeGroupId: (state, action) => {
      // Set transfer_group_id from payload
      state.transfer_group_id = action.payload;
      // Clear other fields
      state.reservation_date = '';
      state.pickup_location_id = '';
      state.return_location_id = '';
      state.reservation_time = '';
    },


  },
});

export const { setTransferInfo, setReservation_date, setChangeGroupId, setResetPickup,setProduct_id } = transferInfo.actions;

export default transferInfo.reducer;