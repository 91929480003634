import React from 'react'
import FeaturedCard from './featuredcard';
import { Dropdown, DropdownButton, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BlogDetail = ({props, propsCategories}) => {

    const { t } = useTranslation();
    const breakpoint = '(max-width: 975px)';
    const categoriesTitleList = propsCategories
    const data=props;
    const isMobile = useMediaQuery({ query: breakpoint });
  return (
    <div className='blogSection '>
        <div className='bgblue w-100 ps-md-1'>
            <h1 className='text-white baslik1 p-md-5 p-3  ms-md-5'>{t('blogPage.blogpage')}</h1>
        </div>
        <div className='blogSectionMain mediaQueryPadding pt-4 pb-5'>
          <div className='titleSection d-flex align-items-center pb-2'>
            {isMobile ? (
              <div className='d-flex align-items-center justify-content-end w-100'>
                  <DropdownButton  title="Categories">
                    {(categoriesTitleList).map((item,index)=>(
                        <div key={index}>
                          <Dropdown.Item href="#/action-1">{item.name}</Dropdown.Item>
                        </div>
                          
                    ))}
                  </DropdownButton>
              </div>
            ) : ("")}
          </div>
        
          <div className='row'>
            <div className='col-md-9 col-xs-12 col-sm-12'>
            <div className='blogSectionLeft'>
              <div className="blogDetailSection">

                <div className='blogDetailCart'>
                  

                    <FeaturedCard 
                        title={data.title}
                        description={data.description}
                        content={data.content}
                        createdTime={data.created_at}
                        image={data.featured_image}
                        type="blogDetailCard"
                            />

                </div>

              </div>
            </div>
            </div>
            
    {isMobile ? (
            ""
    ) : (
      <Col md={3} xs={12} sm={12}>
      <div className="bg-white w-100 informationSection">
        <div className="title">
          <div className="d-flex justify-content-center align-items-center pt-4">
            <h3 className='fw-bold'>{t('blogPage.categories')}</h3>
          </div>
        </div>
        <div className="informationSectionBody pt-4">
        {(categoriesTitleList).map((item, index) => (
          <Link to={`/blog/${item.slug}`} className='custom-link' key={index}>
            <div className={`ps-5 pt-4 pb-4 ${index !== categoriesTitleList.length - 1 ? 'borderbottomdashed' : ''} d-flex align-items-center`}>
              <i className="fas fa-chevron-right text-muted"></i>
              <div className="ps-md-5 ps-sm-2  text-muted">{item.name}</div>
            </div>
          </Link>
        ))}
        </div>
      </div>
      </Col>
    )}
  
            
          
        </div>            
        </div>
    </div>
    
  )
}

export default BlogDetail