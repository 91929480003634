import React, { useEffect, useState } from 'react'
import Header from '../components/header';
import Footer from '../components/footer';
import Comment from '../components/comment';
import CartSection from '../components/cartSection';
import CartSectionNoUser from '../components/cartSectionNoUser';
import authRequest from './api/requests';
import LoadingPage from './loadingPage';

const Cart = () => {
  const [isLoading, setIsLoading] = useState(true);
  let isUser = localStorage.getItem("token") ? true : false;
  document.title ="Cart | Cappadocia Visitor"
  const [data, setData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 140);
    setIsLoading(true);

    authRequest("/api/v1/template/activities", "GET", null, setData, setIsLoading);

  }, []);

  if(isLoading) {
    return (<LoadingPage/>)
};
  return (
    <div className="container-fluid w-100 h-100 p-0" >
    <Header/>
    {isUser ?
    <CartSection/> 
    :
    <CartSectionNoUser/>
    }
    
    <Comment/>
    <Footer/>
  </div>
  )
}

export default Cart