import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import FeaturedCard from './featuredcard';
import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const BannerActivities = (propsData) => {

    const data= propsData.propsData;

    return (
        <Swiper
            slidesPerView={1}
            spaceBetween={0}
            pagination={true}
            navigation={true}
            modules={[Pagination,Navigation]}
            className='activitiesBanner'
        >
            {(data.datas).map((card) => (
                <SwiperSlide key={card.id}>
                    <FeaturedCard content={card.content} title={card.name}  image={card.image} type="activitiesBanner" />
                </SwiperSlide>
            ))}

        </Swiper>
    )
}

export default BannerActivities