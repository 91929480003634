import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BecomeMember = (propsData) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const data = propsData.propsData;

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);
  // *******decode******
  const decodedData = atob(data.description);
  const textDecoder = new TextDecoder('utf-8');
  const decodedDescription = textDecoder.decode(new Uint8Array(decodedData.split('').map(char => char.charCodeAt(0))));
  // ************
  if (isLoading) {
    return ""
  };
  return (
    <div className='becomebg mediaQueryPadding ' style={{ padding: "80px 100px 80px 100px" }}>
      <div className='d-flex align-items-center h-100'>
        <div className='becomeDiv'>
          <div className='text-white fnt44px'>{data.title}</div>
          <div className='mt-3 text-white fnt20px'>{decodedDescription}</div>
          <div className='becomeBtnDiv pt-5'>
            <Link to={"/register"} className='customLink' ><button className='btn p-3 bannerbtnwhite'><strong>{t('joinNow')}</strong></button></Link>
            <Link to={"/login"} className='customLink'><button className='btn p-3 mx-4 bannerbtnblack'>{t('signIn')}</button></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BecomeMember