import React, { useEffect, useState } from 'react'
import Header from '../components/header';
import Footer from '../components/footer';
import Comment from '../components/comment';

import authRequest from './api/requests';
import LoadingPage from './loadingPage';
import FavSection from './favSection';
const Favorites = () => {
  return (
    <div className="container-fluid w-100 h-100 p-0" >
    <Header/>
    <FavSection/>
    <Comment/>
    <Footer/>
    </div>

  )
}

export default Favorites