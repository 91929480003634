import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate';
import FeaturedCard from './featuredcard';
import { Dropdown, DropdownButton, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux'
import { getBlogCategoryApi } from './api/blogdetail/blogCategoryApiSlice';
import LoadingPage from './loadingPage';
import { Link, useParams } from 'react-router-dom';
import { getBlogCategoriesTitle } from './api/blogdetail/blogCategoriesTitleSlice';
import { useTranslation } from 'react-i18next';

const BlogCategorySection = () => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(0);
  const breakpoint = '(max-width: 975px)';

  const isMobile = useMediaQuery({ query: breakpoint });

  const dispatch = useDispatch();
  const { slug } = useParams();




  const data = useSelector(state => state.blogCategoryApi.blogCategoryApi);
  const categoriesTitleList = useSelector(state => state.blogCategoriesTitle.blogCategoriesTitle.data);
  const mapData = data.data;
  const metaData = data.meta;
  const isLoading = useSelector(state => state.blogCategoryApi.isLoading);
  useEffect(() => {
    setCurrentPage(0)
  }, [slug])
  useEffect(() => {
    dispatch(getBlogCategoryApi({ slug: slug, currentPage: currentPage + 1 }))
    dispatch(getBlogCategoriesTitle())
  }, [dispatch, currentPage, slug]);


  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };
  if (isLoading) {
    return (<LoadingPage />);
  };
  if (!categoriesTitleList) {
    return (<LoadingPage />);
  }
  return (
    <div className='blogSection '>
      <div className='bgblue w-100 ps-md-1'>
        <h1 className='text-white baslik1 p-md-5 p-3  ms-md-5' style={{ textTransform: 'uppercase' }}>{mapData.length > 0 ? mapData[0].category.name : { slug }}</h1>
      </div>
      <div className='blogSectionMain mediaQueryPadding pt-4'>
        <div className='titleSection d-flex align-items-center pb-2'>
          {isMobile ? (
            <div className='d-flex align-items-center justify-content-end w-100'>
              <DropdownButton title="Categories">
                {(categoriesTitleList).map((item, index) => (
                  <div key={index}>
                    <Dropdown.Item>
                      <Link to={`/blog/${item.slug}`} className='custom-link' key={index}>

                        {item.name}
                      </Link>
                    </Dropdown.Item>
                  </div>

                ))}
              </DropdownButton>
            </div>
          ) : ("")}
        </div>
        <div className='row'>

          <div className='col-md-9 col-xs-12 col-sm-12'>
            <div className='blogSectionLeft'>
              <div className="blogPaginationSection">
                {mapData.length > 0 ?
                  <div className='blogGrid'>
                    {(mapData).map((item, index) => (
                      <div key={index} className=''>
                        <FeaturedCard
                          title={item.title}
                          description={item.description}
                          content={item.content_index}
                          createdTime={item.created_at}
                          image={item.featured_image}
                          slug={item.slug}
                          type="blogCard"
                        />
                      </div>
                    ))}

                  </div>
                  :
                  <div className='d-flex justify-content-center align-items-center fw-bold'>
                    {t('blogPage.noBlogs')}
                  </div>
                }
              </div>
            </div>
          </div>

          {isMobile ? (
            ""
          ) : (
            <Col md={3} xs={12} sm={12}>
              <div className="bg-white w-100 informationSection">
                <div className="title">
                  <div className="d-flex justify-content-center align-items-center pt-4">
                    <h3 className='fw-bold'>{t('blogPage.categories')}</h3>
                  </div>
                </div>
                <div className="informationSectionBody scrollBlog pt-4">
                  {(categoriesTitleList).map((item, index) => (
                    <Link to={`/blog/${item.slug}`} className='custom-link' key={index}>
                      <div className={`ps-5 pt-4 pb-4 ${index !== categoriesTitleList.length - 1 ? 'borderbottomdashed' : ''} d-flex align-items-center`}>
                        {item.name === mapData[0]?.category?.name ? <i className="fas fa-chevron-left"></i> : <i className="fas fa-chevron-right text-muted"></i>}
                        {item.name === mapData[0]?.category?.name ? <div className="ps-md-5 ps-sm-2 ">{item.name}</div> : <div className="ps-md-5 ps-sm-2 text-muted">{item.name}</div>}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </Col>

          )}



        </div>
        <div className='d-flex justify-content-center align-items-center pe-4 pt-5 pb-5'>
          <ReactPaginate
            previousLabel={t('previous')}
            nextLabel={t('next')}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={metaData.last_page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </div>


      </div>
    </div>

  )
}

export default BlogCategorySection