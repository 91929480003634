import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

const SearchResultsCollapse = (results) => {
    const resultData = results.results
    const breakpoint = '(max-width: 975px)';
    const isMobile = useMediaQuery({ query: breakpoint });
    if (!resultData) {
        return ("loading..")
    }
    return (
        <div className="collapse show" id="searchResults" style={{ zIndex: '999', position: 'absolute', width: '95%' }}>
            <ul className="list-group ">
                {resultData.map((result, index) => (
                    <a href={`/tourdetail/${result.slug}`} className='custom-link' key={index}>
                        <li className="list-group-item" >
                            <div className='row'>
                                <div className='col-md-9 '>
                                    <div className='d-flex align-items-center h-100'>
                                        <img className='searchImg' src={result.featured_file} alt={result.title} />
                                        <span className='ps-3 mqFnt06'>{result.title}</span>
                                    </div>

                                </div>
                                {!isMobile &&
                                    <div className='col-3'>
                                        <div className='bgorange text-white p-2 '>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <span className='underlinetext '>{result.price} Eur</span>
                                                    <span className=' ps-2 fnt16rem'>{result.total_price} EUR</span>
                                                    <span className='fnt08rem ps-1'>Per Person</span>
                                                </div>
                                                <div>
                                                    <i className="fas fa-arrow-right ps-3 fnt18rem" style={{ color: '#234296' }}></i>

                                                </div>
                                            </div>

                                        </div>
                                    </div>}


                            </div>

                        </li>
                    </a>


                ))}
            </ul>
        </div>
    )
}

export default SearchResultsCollapse