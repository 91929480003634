import { Routes, Route } from "react-router-dom";
import {Helmet} from "react-helmet";
import Home from './components/home';
import Tourlist from './components/tourlist';
import Tourdetail from './components/tourdetail';
import Payment from './components/payment';
import Login from './components/login';
import Register from './components/register';
import Profile from './components/profile';
import Aboutus from './components/aboutus';
import Cart from './components/cart';
import NotFoundPage from './components/notFoundPage';
import ActivitiesPage from "./components/activitiesPage";
import BalloonToursPage from "./components/balloonToursPage";
import BlogPage from "./components/blogPage";
import BlogDetailPage from "./components/blogDetailPage";
import BlogCategoryPage from "./components/blogCategoryPage";
import SearchPage from "./components/searchPage";
import Pages from "./components/pages";
import EmailVerify from "./components/emailVerify";
import ChangeForgotPswd from "./components/changeForgotPswd";
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import CongratulationsModal from "./components/congratulationsModal";
import WarningModal from "./components/warningModal";
import Voucher from "./components/voucher";
import Test from "./components/test";
import TourCategoryPage from "./components/tourCategoryPage";
import VoucherGrp from "./components/voucherGrp";
import VoucherTransfer from "./components/voucherTransfer";
import AuthWithGoogle from "./components/authWithGoogle";
import Review from "./components/review";
import Favorites from "./components/favorites";
import SiteMap from "./components/siteMap";


function App() {
  const [isLoading, setIsLoading] = useState(true);

  const generalSettingsData = useSelector(state => state.settingsApi.generalSettingsApi.data)
  const [data, setData] = useState();
  useEffect(() => {
    if (generalSettingsData) {
      const scriptCode = (generalSettingsData).find(item => item.key === "head_codes");
      const decodedData = atob(scriptCode.value);
      const textDecoder = new TextDecoder('utf-8');
      const decodedCancelRules = textDecoder.decode(new Uint8Array(decodedData.split('').map(char => char.charCodeAt(0))));
      setData(decodedCancelRules)
        setIsLoading(false);
    }
  }, [generalSettingsData]);

  return (
    <div>
      {isLoading === false ?      
      <Helmet>
        <script>{data}</script>
      </Helmet> : ""} 

    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/tourlist" element={<Tourlist/>} />
      <Route path="/tourdetail/:slug" element={<Tourdetail/>} />
      <Route path="/blogdetail/:slug" element={<BlogDetailPage/>} />
      <Route path="/search/:slug" element={<SearchPage/>} />
      <Route path="/transfer" element={<Payment/>} />
      <Route path="/login" element={<Login/>} />
      <Route path="/register" element={<Register/>} />
      <Route path="/profile" element={<Profile/>} />
      <Route path="/success/:slug" element={<CongratulationsModal/>} />
      <Route path="/failed/:slug" element={<WarningModal/>} />
      <Route path="/voucher/:slug" element={<Voucher/>} />
      <Route path="/vouchertransfer/:slug" element={<VoucherTransfer/>} />
      <Route path="/vouchergrp/:slug" element={<VoucherGrp/>} />
      <Route path="/cart" element={<Cart/>} />
      <Route path="/about" element={<Aboutus/>} />
      <Route path="/activities" element={<ActivitiesPage/>} />
      <Route path="/balloontours" element={<BalloonToursPage/>} />
      <Route path="/blog" element={<BlogPage/>}/>
      <Route path="/test" element={<Test/>}/>
      <Route path="/blog/:slug" element={<BlogCategoryPage/>} />
      <Route path="/category/:slug" element={<TourCategoryPage/>} />
      <Route path="/pages/:slug" element={<Pages/>} />
      <Route path="/email-verify/:token/:email" element={<EmailVerify/>} />
      <Route path="/auth/socialite" element={<AuthWithGoogle/>} />
      <Route path="/favorites" element={<Favorites/>} />
      <Route path="/review" element={<Review/>} />
      <Route path="/reset-password/:token/:email" element={<ChangeForgotPswd/>} />
      <Route path="/sitemap" element={<SiteMap/>} />
      <Route path="*" element={<NotFoundPage/>} />
    </Routes>
    </div>
  );
}
export default App;
