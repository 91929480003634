import React, { useEffect, useState } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../components/header';
import Footer from '../components/footer';
import Comment from '../components/comment';
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom';

import { getSearchApi } from './api/search/searchApiSlice';
import LoadingPage from './loadingPage';
import { useParams } from 'react-router-dom';
import SearchedCart from './searchedCart';


function SearchPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const {slug} = useParams();
  const propsDate = location.state?.propsDate;

   // Document title set
   useEffect(() => {
    document.title = `${slug} | Cappadocia Visitor`;
  }, [slug]);

  // State initialization
  const [currentDate, setCurrentDate] = useState(propsDate);
  const [currentTerm, setCurrentTerm] = useState(`${slug}`)
  const data = useSelector(state => state.searchApi.searchApi);
  const isLoading = useSelector(state => state.searchApi.isLoading);

  
  useEffect(()=>{
      dispatch(getSearchApi({slug:currentTerm, selectDate:currentDate}))
    },[dispatch,slug, currentDate]);
if(isLoading && !slug) {
  return (<LoadingPage/>);
}
  return (
    <div className="container-fluid w-100 h-100 p-0">
      <Header 
      setCurrentTerm={setCurrentTerm} 
      currentDate={currentDate}
      />
      <SearchedCart 
      propsData={data} 
      currentTerm={currentTerm} 
      currentDate={currentDate} 
      setCurrentDate={setCurrentDate}/>
      <Footer/>
    </div>
  );
}

export default SearchPage;
