import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import NavbarImg from "./navbarImg";
import Collapse from 'react-bootstrap/Collapse';
import DatePicker from 'react-datepicker';
import authRequest from './api/requests';
import LoadingPage from './loadingPage';
import TokenModal from './alerts/tokenModal';
import { decrement } from './api/counter/counterSlice';
import { useDispatch } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import PaymentCartModal from './cartComponents/paymentCartModal';
import { initializeGuests } from './api/formValueState/formValueSlice';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const CartSection = () => {
  const { t } = useTranslation();
  // const cartsFromStorage = JSON.parse(localStorage.getItem('cart'));
  const dispatch = useDispatch()
  let localData = JSON.parse(localStorage.getItem("allCartList")) || [];
  const [data, setData] = useState(localData);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [errorData, setErrorData] = useState();
  const [showError, setShowError] = useState(false)
  const [responsePostData, setResponsePostdata] = useState([])
  const [enableDatesList, setEnableDatesList] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [enableDatesPriceList, setEnableDatesPriceList] = useState([]);
  const [allCartList, setAllCartList] = useState([]);
  const closeModal = () => {
    localStorage.removeItem("token");
    setShowError(false);
    window.location.href = '/login';
  }

  useEffect(() => {

    setIsLoading(true);

    if (data) {
      const postData = {
        allCartList: data
      }
      authRequest("/api/v1/carts/add", "POST", postData, setData, setIsLoading, null, false);

    }
  }, []);

  useEffect(() => {
    if (errorData) {
      setShowError(true);
    } else {
      setShowError(false)
    }
  }, [errorData]);

  const [openCollapseIndex, setOpenCollapseIndex] = useState(null);

  const openCollapse = (index) => {
    setOpenCollapseIndex(openCollapseIndex === index ? null : index);
  }


  const handleClose = () => {
    setShowModal(false)
    dispatch(initializeGuests([]))

  };
  const handleShow = () => setShowModal(true)
  // ******Datepicker*********


  const handleChange = (e, index, type) => {

    const value = parseInt(e.target.value); // Gelen değeri sayıya dönüştürün
    const updatedAllCartList = [...allCartList];
    updatedAllCartList[index][type] = value
    setAllCartList(updatedAllCartList);

  };


  const handeleReservation_date = (index, date) => {
    const formattedDate = date.toLocaleDateString('en-CA'); // y-m-d formatında tarih dönüşümü
    const updatedAllCartList = [...allCartList];
    updatedAllCartList[index].tour_date = formattedDate
    setAllCartList(updatedAllCartList);

  };

  const postAllCartlist = () => {
    const postData = {
      allCartList: allCartList
    }
    setIsLoading(true);

    authRequest("/api/v1/carts/add", "POST", postData, setData, setIsLoading);
    localStorage.setItem("allCartList", JSON.stringify(allCartList));

  }

  const deleteCart = (tour_id) => {
    let allCartList = JSON.parse(localStorage.getItem("allCartList")) || [];

    const updatedAllCartList = allCartList.filter(item => item.tour_id !== tour_id);

    localStorage.setItem("allCartList", JSON.stringify(updatedAllCartList));

    setAllCartList(updatedAllCartList);
    const postData = {
      allCartList: updatedAllCartList // Güncellenmiş listeyi kullanın
    };


    authRequest("/api/v1/carts/add", "POST", postData, setData, setIsLoading);
    dispatch(decrement())

  };

  useEffect(() => {

    if (data.data) {

      const newUpdatedCartList = [];
      const newEnableDatesList = [];
      const newEnableDatesPrice = [];
      data.data.forEach((tour, index) => {

        const tourData = {
          tour_id: tour.tour_id,
          person: tour.person,
          tour_date: tour.tour_date,
          time: tour.time,
          products: tour.products.map(product => product.id)
        }
        newUpdatedCartList.push({ ...tourData })

        const enableDates = tour.tour.avaible_dates_calendar.map(item => {
          // Virgüllerle ayrılmış string tarihleri parçalayarak diziye dönüştürüyoruz
          const startDateParts = item.start_date.split(',').map(part => parseInt(part, 10));
          const endDateParts = item.end_date.split(',').map(part => parseInt(part, 10));

          // Parçalanan dizileri kullanarak yeni Date nesneleri oluşturuyoruz
          const startDate = new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2]);
          const endDate = new Date(endDateParts[0], endDateParts[1] - 1, endDateParts[2]);

          return {
            start: startDate,
            end: endDate
          };
        });
        newEnableDatesList.push({ enableDates: enableDates });


        const enableDatesPrice = (tour.tour.avaible_dates).map((item) => ({
          start: new Date(item.start_date),
          end: new Date(item.end_date),
          price: item.total_price,
        }));

        enableDatesPrice.forEach(dateRange => {
          dateRange.start.setDate(dateRange.start.getDate() - 1);
        });

        newEnableDatesPrice.push({ enableDatesPrice: enableDatesPrice });

      });
      setAllCartList(newUpdatedCartList)

      setEnableDatesPriceList(newEnableDatesPrice)
      setEnableDatesList(newEnableDatesList);

      if (enableDatesList && enableDatesPriceList) {
        setIsLoading(false);

      }
    }
  }, [data]);


  const renderDayContents = (day, date, index) => {
    const currentDate = new Date(date);
    const interval = enableDatesPriceList[index].enableDatesPrice.find((interval) => {
      return (
        currentDate >= interval.start && currentDate <= interval.end
      );
    });

    const price = interval && interval.price;

    if (currentDate < new Date() && currentDate.toDateString() !== new Date().toDateString() || !price) {
      return (
        <>
          <span>{day}</span>
        </>
      );
    }

    return (
      <>
        <span>{day}</span>
        <div className="dateNotes" style={{ fontSize: '0.5rem' }}>
          €{price.length > 6 ? price.substring(0, price.indexOf(".")) : price}
        </div>
      </>
    );
  };

  const handleCheckboxChange = (options, index) => {
    const uptadetOptions = allCartList[index]?.products.includes(options)
      ? allCartList[index]?.products.filter((opt) => opt !== options)
      : [...allCartList[index]?.products, options];
    const updatedAllCartList = [...allCartList];
    updatedAllCartList[index].products = uptadetOptions
    setAllCartList(updatedAllCartList)
  };


  function numberToWords(number) {
    const units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    if (number < 10) return units[number];
    if (number < 20) return teens[number - 10];
    if (number < 100) return tens[Math.floor(number / 10)] + (number % 10 !== 0 ? '-' + units[number % 10] : '');
    if (number < 1000) return units[Math.floor(number / 100)] + ' hundred' + (number % 100 !== 0 ? ' and ' + numberToWords(number % 100) : '');

    return 'Number is out of range'; // Bu basit fonksiyon sadece 0 ile 999 arasındaki sayıları işler
  }

  // *************************

  if (isLoading) {
    return (<LoadingPage />)
  };
  return (
    <div>
      <div>
        <div className="padProduct  pb-4 mqNoneProduct">
          <div className="baslik1">{t('cart.checkOut')}</div>
          <div>{t('cart.experienceQuick')}</div>


        </div>
        <div className="mqDblock pt-4" style={{ display: 'none' }}>
          <div className="row">
            <div className="col-4">
              <div className="padProduct pt-3">
                <div className="fnt12rem fw-bold">{t('cart.checkOut')}</div>
                <div className="fnt06rem">{t('cart.experienceQuick')}</div>
              </div>
            </div>
            {/* <div className="col-8">
              <div className="bgblue w-100 mt-3 overflow-hidden d-flex align-items-center justify-content-center" style={{ height: '2rem' }}><div className="uploadImg"></div><div className="pP"></div></div>
              <div align='right'>
                <div className="bgorange pt-2 pe-2 fnt08rem" style={{ height: '2rem', width: '80%' }}>Anuska Toryok</div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="bgblue w-100">

          <div className="row">
            <Col md={9} sm={12} xs={12}>
              <div className="text-white productNavDivL pe-md-5 me-md-5" style={{ display: 'flex', height: '64px', justifyContent: 'space-between' }}>
                <div className='cart'>
                  <div className="productBtn padProduct overflow-hidden d-flex align-items-center h-100" >
                    <div><NavbarImg type="cart" /></div>
                    <div className="ms-2 h1" style={{ color: 'white' }}>{t('cart.carT')}</div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>




      <div className='proCartDiv d-flex align-items-center padProduct' style={{ width: '500px', marginTop: '3rem' }}>
        <div className='mx-4 clrGray p-3'>{t('cart.thereAre')} {numberToWords(data?.data?.length)} {t('cart.itemsInYourCart')}.</div>
      </div>
      <div className='mediaQueryPadding commentDiv' style={{ padding: "80px 100px 80px 100px" }}>

        {data.data && data.data.length > 0 ?

          <div className='p-md-3'>
            {(data.data).map((item, key) => (
              <div className='borderbottomdashed  mt-1 pt-5 pb-1' key={key}>
                <div className='row'>
                  <Col md={7} sm={12} xs={12}>
                    <div className='d-flex justify-content-between align-items-center pe-md-5'>
                      <div className='d-flex align-items-center'>
                        <Link to={`/tourdetail/${item.tour.slug}`} className='custom-link'>
                          <div className='h2'>{item.tour.title}</div>
                        </Link>                      </div>
                      <div className='clrGray'>
                        <span><i className="fas fa-user me-2"></i></span>
                        {item.person} {t('person')}
                      </div>
                    </div>
                  </Col>
                  <Col md={5} sm={12} xs={12}>
                    <div className="paddingL8 pb-2" >
                      <div className='borderbottomdashed mqWauto d-flex align-items-center justify-content-between fnt12rem pb-2' >
                        <div className='fw-bold'>{t('transfer.amount')}</div>
                        <div>
                          <span className='fw-bold fnt16rem'>{item.calculator.data.total_price} EUR</span>
                          <span>
                            <button className='customButton'>

                              <i
                                className="fas fa-trash-alt clrGray ps-5"
                                onClick={() => deleteCart(item.tour_id)}

                              >
                              </i>
                            </button>

                          </span>
                          <span>
                            <button className='customButton'>
                              <i className="far fa-edit clrGray ps-5"
                                onClick={() => openCollapse(item.tour_id)}
                                aria-controls={`example-collapse-text-${item.tour_id}`}
                                aria-expanded={openCollapseIndex === item.tour_id}
                              >
                              </i>
                            </button>

                          </span>
                        </div>
                      </div>
                    </div>

                  </Col>
                </div>

                <Collapse in={openCollapseIndex === item.tour_id}>
                  <div id="example-collapse-text" className='row pt-3' >
                    {item.products.length > 0 ? <div className='externalProducts'>
                      <div className='borderbottomdashed radioOptions pt-4 pb-4' style={{ display: 'flex', flexWrap: 'wrap' }}>

                        {(item.tour.extra_products).map((item, index) => (
                          <div key={index} className=' pt-2 pb-2' style={{ width: '33%' }} >
                            <input
                              type="checkbox"
                              checked={allCartList[key]?.products && (allCartList[key]?.products).includes(item.id)}
                              onChange={() => handleCheckboxChange(item.id, key)}

                              id={item.id}
                              value={item.options}
                            />
                            <label className='ms-2 cpointer' htmlFor={item.id}>{item.name} {item.price} EUR</label>
                          </div>
                        ))}

                      </div>

                    </div>
                      :
                      ""}
                    <div className='col-4'>
                      <div className='fw-bold fnt08rem'><i className="fas fa-user me-2"></i>{t('person')}</div>
                      <select
                        className='w-100 mt-2 mb-4 personalizeTourselect modalselect'
                        value={allCartList[key]?.person ? allCartList[key]?.person : null}
                        onChange={(e) => handleChange(e, key, "person")}
                      >

                        {Array.from({ length: 10 }, (_, i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='col-4'>
                      <div className='fw-bold fnt08rem'><i className="fas fa-calendar me-2"></i>{t('date')}</div>

                      <DatePicker
                        className="w-100 personalizeTourselect mt-2 mb-4"
                        selected={allCartList[key]?.tour_date ? new Date(allCartList[key]?.tour_date) : null}
                        includeDateIntervals={enableDatesList[key]?.enableDates}
                        onFocus={(e) => e.target.blur()}
                        onChange={(date) => {
                          handeleReservation_date(key, date);
                          setStartDate(date);
                        }}
                        dateFormat="yyyy-MM-dd"
                        minDate={new Date()}
                        renderDayContents={(day, date) => renderDayContents(day, date, key)}
                      />

                    </div>
                    <div className='col-4'>
                      <div className='fw-bold fnt08rem'><i className="fas fa-clock me-2"></i>{t('time')}</div>
                      <select
                        className='w-100 mt-2 mb-4 personalizeTourselect modalselect'
                        value={allCartList[key]?.time ? allCartList[key]?.time : null}

                      >
                        {(item.tour.avaible_times).map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </select>
                    </div>
                    <div className='d-flex justify-content-center pb-2'>
                      <button className='btn bgorange personalizeBtn text-black w-50' style={{ borderRadius: "60px", fontWeight: "600", fontSize: "14px" }} onClick={postAllCartlist} >{t('registerForm.save')}</button>
                    </div>
                  </div>
                </Collapse>
              </div>
            ))}
            <div className='w-100'>
              <div align='right'>
                <div className='borderbottomdashed mqWauto d-flex align-items-center justify-content-between fnt18rem pb-2 pt-5 fw-bold mqFs14rem' style={{ width: '25vw' }} >
                  <div>{t('totalAmount')}</div>
                  <div>{data.infos.total_price} EUR</div>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: '5rem', paddingBottom: '10rem' }}><button className='btn bgblue personalizeBtn text-white w-100 p-3' style={{ borderRadius: "60px", fontWeight: "600", fontSize: "18px" }} onClick={handleShow}>{t('paymentForm.pay')}</button></div>

          </div>

          :
          <div className='pt-5 pb-5 d-flex justify-content-center align-items-center w-100 h-100'>
            {t('cart.toursNAInCart')}
          </div>
        }

      </div>
      <Modal show={showModal} onHide={handleClose} centered dialogClassName="modal-90w paymentmodal" aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header className='border-0' closeButton></Modal.Header>
        <Modal.Body className='pb-5'>
          <PaymentCartModal props={data} editFnc={handleClose} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default CartSection