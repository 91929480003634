import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../components/header';
import BannerBaloonTours from '../components/bannerBaloonTours';
import Featuresection from '../components/featuredsection';
import Nowopentours from '../components/nowopentours';
import Localfinds from '../components/localfindssection';
import Localhotels from '../components/localhotelsection';
import Footer from '../components/footer';
import Comment from '../components/comment';
import TakeYouSection from '../components/takeYouSection';
import BecomeMember from '../components/becomeMember';
import { useSelector, useDispatch } from 'react-redux'
import { getPageApi } from './api/pageApi/pageApiSlice';
import { useEffect } from 'react';
import LoadingPage from './loadingPage';


function BalloonToursPage() {
  document.title ="Balloon Tours | Cappadocia Visitor"
  const dispatch = useDispatch();
  const data = useSelector(state => state.pageApi.pageApi.data);
  const isLoading = useSelector(state => state.pageApi.isLoading);
  useEffect(()=>{
    dispatch(getPageApi("balloon-tours"))
    },[dispatch]);
    const renderSection = (section) => {
      switch (section.section_type.value) {
        case 'FEATURED_BANNER':
          return <BannerBaloonTours propsData={section}/>;
          case 'CARDS_REACTANGLE':
            return <Featuresection propsData={section} />;
          case 'CARDS_SQUARE':
            return <Nowopentours propsData={section} />;
          case 'BANNER_SECTION':
            return <TakeYouSection propsData={section}/>;
          case 'CARDS_BIG_REACTANGLE':
            return <Localfinds propsData={section}/>;
          case 'REGISTER_SECTION':
            return <BecomeMember propsData={section}/>;
          case 'CARDS_BIG_BG_REACTANGLE':
            return <Localhotels propsData={section}/>;
          default:
            return null;
        }
      };


  if(isLoading) {
      return (<LoadingPage/>);
  };
  return (
    <div className="container-fluid w-100 h-100 p-0">
      <Header/>
      {data.map((section, index) => (
      <div key={index} className={index === 0 ? '' : 'mediaQueryPadding'} style={{ padding: index === 0 ? '' : '0px 100px 50px 100px' }} >
      {renderSection(section)}
    </div>
      ))}
      <div className='mt-5'><Comment/></div>
      
      <Footer/>
    </div>
  );
}

export default BalloonToursPage;
