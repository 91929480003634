import React from 'react'
import RegisterCart from './registerCart';
import { useTranslation } from 'react-i18next';


const PaymentCartModal = (props) => {
    const { t } = useTranslation();
    const editModal = props.editFnc
    const data = props.props.data
    const totalPrice = props.props.infos

    return (
        <div className='row'>
            <div className='col-md-4 col-sm-12 col-xs-12 paymentModalMQCol'>
                <div className='bannersearch text-white bgblue p-5 h-100'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='fnt14rem'>{t('paymentModal.resDetail')}</div>
                        <div>
                            <button className='customButton'>
                                <i className="far fa-edit text-white ps-5" onClick={editModal}>
                                </i>
                            </button>
                        </div>

                    </div>

                    {data.map((item, index) => (
                        <div className='mt-4 borderdashed pb-4' key={index}>
                            <div className='row'>
                                <div className='col-7'>
                                    <div className='mqModalFsize'>{item.tour.featured_title}</div>

                                </div>
                                <div className='col-3'>
                                    <div className='fnt08rem mqModalFsizeSecond'>
                                        <span><i className="fas fa-user me-2"></i></span>
                                        {item.person} {t('person')}
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div className='fnt08rem mqModalFsizeSecond'>{item.calculator.data.total_price} €</div>

                                </div>

                            </div>

                            <div className='mt-4  fnt08rem' style={{ color: 'rgba(255,255,255,0.4)' }}>
                                <div className='d-flex align-items-center '>
                                    <div className='mqModalFsize' style={{ flexGrow: "1" }}>{t('date')}</div>
                                    <div><div className='mqModalFsizeSecond'>{item.tour_date}</div></div>
                                </div>
                            </div>

                        </div>
                    ))}
                    <div className='pt-5'>
                        <div className='d-flex align-items-center'>
                            <div className='mqModalFsize fnt12rem' style={{ flexGrow: "1" }}>{t('paymentModal.totalAmount')}</div>
                            <div><div className='fnt16rem mqModalFsizeSecond'>{totalPrice.total_price} EUR</div></div>
                        </div>
                    </div>




                </div>
            </div>
            <div className='col-md-8 col-sm-12 col-xs-12 paymentModalForm paymentModalMQCol'>
                <div className='w-100 h-100 borderlight borderrad12 p-5'>

                    <RegisterCart props={data} />

                </div>
            </div>
        </div>
    )
}

export default PaymentCartModal