import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HomeSlider = ({ data }) => {
    const breakpoint = '(max-width: 767px)';

    const isMobile = useMediaQuery({ query: breakpoint });
    const [currentId, setCurrentId] = useState(0);

    const handleNext = () => {
        setCurrentId(prevId => (prevId + 1) % data.length);
    };

    const handlePrev = () => {
        setCurrentId(prevId => (prevId - 1 + data.length) % data.length);
    };

    const currentData = data[currentId];


    const createIframe = (src, className, width = 315, height = 250, title = "Embedded Video") => {
        return (
            <iframe
                width={width}
                height={height}
                class={isMobile ? className : "homeVideo"}
                src={src}
                title={title}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            ></iframe>
        );
    };

    const fileRender = (dataGroup, fileData) => {
        switch (fileData.type) {
            case 'YOUTUBE':
                return createIframe(fileData.file, dataGroup);
                break;
            case 'IMAGE':
                return (
                    <Link to={fileData.link} className='custom-link' >
                        <LazyLoadImage className={dataGroup} src={fileData.file} />
                    </Link>
                );
            default:
                return <LazyLoadImage className={dataGroup} src={fileData.file} />;

        }
    };






    return (
        <div>
            <div className='h-100 bannerimagecontainer mediaQueryHiddenBanner'>
                <div className='d-flex align-items-center h-100'>
                    <div className='w-100'>
                        <div className='d-flex align-items-end'>
                            <div>
                                <div className='mt-3 homeVideoDiv' >{fileRender("bannerimg4", currentData.first_file)} </div>
                                <div className='pt-4 fnt12px d-flex align-items-centers justify-content-between '>
                                    <button type='button' onClick={handlePrev}><i className="fas fa-chevron-left"></i></button>
                                    <Link to={currentData.main_link} className='custom-link'><span className='px-4'><i className="fas fa-map-marker-alt"></i>&nbsp;&nbsp;{currentData.name}</span></Link>
                                    <button type='button' onClick={handleNext}><i className="fas fa-chevron-right" ></i></button>
                                </div>
                            </div>
                            <div className='px-3'>
                                <div className='mt-3 homeVideoDiv' style={{ width: '12vw' }}>
                                    {fileRender("bannerimg4", currentData.two_file)}
                                </div>
                                <div className='mt-3 homeVideoXsDiv'>
                                    {fileRender("bannerimg3", currentData.three_file)}
                                </div>

                            </div>

                            <div>
                                <div className='homeVideoDiv'>
                                    {fileRender("bannerimg1", currentData.four_file)}</div>
                                <div className=' mt-3 homeVideoDiv'>{fileRender("bannerimg2", currentData.five_file)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='h-100 bannerimagecontainer mediaQueryShowBanner' style={{ display: 'none' }}>
                <div className='d-flex align-items-center h-100'>
                    <div className='w-100'>
                        <div className='d-flex align-items-center'>
                            <div>
                                <div>{fileRender("bannerimg4", currentData.first_file)}</div>
                                <div className='mt-3'>{fileRender("bannerimg3", currentData.three_file)}</div>
                            </div>
                            <div className='px-3'>
                                {fileRender("bannervd1", currentData.two_file)}
                                <div className='pt-4 pb-4 fnt12px  d-flex align-items-centers justify-content-between' style={{ minWidth: '14rem' }}>
                                    <button type='button' onClick={handlePrev}><i className="fas fa-chevron-left"></i></button>
                                    <Link to={currentData.main_link} className='custom-link'>
                                        <div className='d-flex justify-content-center align-items-center overflow-hidden' style={{ maxWidth: '13rem' }}>
                                            <i className="fas fa-map-marker-alt"></i>
                                            <span className='text-center'>&nbsp;&nbsp;{currentData.name}</span>
                                        </div>
                                    </Link>
                                    <button type='button' onClick={handleNext}><i className="fas fa-chevron-right" ></i></button>
                                </div>

                            </div>
                            <div>
                                <div>{fileRender("bannerimg1", currentData.four_file)}</div>
                                <div className='mt-3'>{fileRender("bannerimg2", currentData.five_file)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeSlider



