import { createSlice } from '@reduxjs/toolkit'

const guestForm = createSlice({
  name: 'guestForm',
  initialState: {
    guests:[],
    },
  reducers: {

    setGuests: (state, action) => {
      state.products = action.payload;
    },

  },
});

export const { setGuests } = guestForm.actions;

export default guestForm.reducer;