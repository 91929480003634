import React from 'react'

const AboutContentSection = () => {
  return (
    <div className='aboutContentSection'>
            <div className='d-flex blueAndOrangeDiv'>
                <div className='bgblue ' style={{ flexGrow: "1", padding: "10px 100px" }}>
                    <div className='text-white baslik1'>Who We Are ?</div>
                        </div>
                        <div className='bgorange' style={{ padding: '0 180px 0 3rem' }}>
                            <div className='d-flex align-items-center h-100'>
                                <div className='w-100 '>
                                    <span>Since</span>
                                    <span className='px-2' style={{fontSize:'36px'}}>2020</span>
                                </div>
                            </div>

                    </div>
                </div>
        <div className='miniTitle'>
            <div className='bgorange w-25 p-2 mt-4'>
                <div className='pl6re fw-bold'>About Us</div>
            </div>
        </div>
        <div className='mediaQueryPadding' style={{ padding: "80px 100px 400px 100px" }}>
            <p style={{fontWeight:'550'}}>The company was founded in 2020 by Önder Barış Yıldırım. When the epidemic disease called Covid-19 appeared all over the world, tourism could not develop much because of epidemic. Nonetheless, our main aim is not to keep tourism alive for now, we want to prepare a long-term plan for the coming years. That's why, we took a risk and established our tourism agency during the pandemic period. Because if you don't take risk,you cannot reach your goals. In the first years of our establishment, we wanted to announce our name to a wide audience by establishing a website. Our aim was to guide future generations and emphasize that sometimes it is necessary to take risks. In this way, we will be able to appeal to people of all races and different cultures and enjoy the happiness of the good service we provide. We also wanted to introduce Turkish culture by harmonizing it with modernity without breaking away from our traditions. Today, the idea of serving better is at the heart of everything we do. We serve every region of Turkey. It has become our priority to organize various tours and activities to promote the region and satisfy our guests coming to the country. Only one perspective has never been among our preferences. The important thing is to always be open to novelty and have many point of views. As a result, we are happy to be a company that is open to innovations and appeals to everyone</p>
        </div>


    </div>
  )
}

export default AboutContentSection