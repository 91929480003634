import { configureStore } from '@reduxjs/toolkit'
import locationsReducer from './components/api/locations/locationsSlice.js'
import dailyTourCardReducer from './components/api/dailyTourCards/dailyTourCardsSlice.js'
import homeApiReducer from './components/api/home/homeApiSlice.js'
import tourDetailApiReducer from './components/api/tourdetail/tourdetailSlice.js'
import guestFormReducer from './components/api/guest/guestFormSlice.js'
import tourDetailInfoReducer from './components/api/tourDetailInfo/tourDetailInfoSlice.js'
import voucherApiReducer from './components/api/voucher/voucherApiSlice.js'
import tourListApiReducer from './components/api/tourlist/tourlistSlice.js'
import commentApiReducer from './components/api/comment/commentApiSlice.js'
import blogApiReducer from './components/api/blogdetail/blogApiSlice.js'
import settingsApiReducer from './components/api/settings/settingsApiSlice.js'
import transferApiReducer from './components/api/transfer/transferApiSlice.js'
import generalSettingsApiReducer from './components/api/settings/generalSettings/generalSettingsSlice.js'
import pageApiReducer from './components/api/pageApi/pageApiSlice.js'
import counterReducer from './components/api/counter/counterSlice.js'
import blogDetailApiReducer from './components/api/blogdetail/blogdetailSlice.js'
import blogCategoryApiReducer from './components/api/blogdetail/blogCategoryApiSlice.js'
import paginateApiReducer from './components/api/paginateTour/paginateApiSlice.js'
import tourCategoriesReducer from './components/api/paginateTour/tourCategoriesSlice.js'
import blogCategoriesTitleReducer from './components/api/blogdetail/blogCategoriesTitleSlice.js'
import searchApiReducer from './components/api/search/searchApiSlice.js'
import contentPageApiReducer from './components/api/contentPageApi/contentPageApiSlice.js'
import transferInfoReducer from './components/api/transfer/transferInfoSlice.js'
import formValueInfoReducer from './components/api/formValueState/formValueSlice.js'


export default configureStore({
  reducer: {
    locations: locationsReducer,
    dailyTourCard: dailyTourCardReducer,
    homeApi: homeApiReducer,
    settingsApi: settingsApiReducer,
    transferApi: transferApiReducer,
    tourDetailApi: tourDetailApiReducer,
    voucherApi: voucherApiReducer,
    tourDetailInfo: tourDetailInfoReducer,
    formValueInfo: formValueInfoReducer,
    guestForm: guestFormReducer,
    tourListApi: tourListApiReducer,
    commentApi: commentApiReducer,
    blogDetailApi:blogDetailApiReducer,
    blogApi: blogApiReducer,
    generalSettingsApi: generalSettingsApiReducer,
    pageApi: pageApiReducer,
    blogCategoryApi: blogCategoryApiReducer,
    paginateApi: paginateApiReducer,
    blogCategoriesTitle: blogCategoriesTitleReducer,
    tourCategories: tourCategoriesReducer,
    searchApi: searchApiReducer,
    contentPageApi: contentPageApiReducer,
    transferInfo: transferInfoReducer,
    counter: counterReducer,
  },
})