import { createSlice } from '@reduxjs/toolkit'

const tourDetailInfo = createSlice({
  name: 'tourDetailInfo',
  initialState: {
    person: '',
    tour_date:'',
    time:'',
    tour_id:'',
    products:[],
    },
  reducers: {

    setProducts: (state, action) => {
      state.products = action.payload;
    },

    setTour_id: (state, action) => {
      state.tour_id = action.payload;
    },

    setPerson: (state, action) => {
      state.person = action.payload;
    },
    
    setReservation_date: (state, action) => {
        state.tour_date = action.payload;
    },

    setTime: (state, action) => {
    state.time = action.payload;
    },


  },
});

export const { setPerson, setTime, setReservation_date, setProducts, setTour_id } = tourDetailInfo.actions;

export default tourDetailInfo.reducer;