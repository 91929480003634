import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';



const initialState = {
    voucherApi: [],
    isLoading: true
}

export const getVoucherApi = createAsyncThunk('getVoucherApi', async ({ slug, voucherType = null }) => {
    if (voucherType === "TRANSFER") {
        const { data } = await axios.get(process.env.REACT_APP_API_URL + `/transfers/reservation-info/${slug}`);
        return data;
    } else {
        const { data } = await axios.get(process.env.REACT_APP_API_URL + `/tours/reservation/${slug}`);
        return data;
    }
});


export const voucherApiSlice = createSlice({
    name: "voucherApi",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getVoucherApi.fulfilled, (state, action) => {
            state.voucherApi = action.payload;
            state.isLoading = false
        })
    }
})


export default voucherApiSlice.reducer