import React from 'react'
import Header from '../components/header';
import Footer from '../components/footer';
import Comment from '../components/comment';
import Banner from '../components/banner';
import AboutContentSection from '../components/aboutContentSection';
import BecomeMember from '../components/becomeMember';


const Aboutus = () => {
  document.title ="About"

  return (
    <div className="container-fluid w-100 h-100 p-0" >
      <Header/>
      <Banner type="about"/>
      <AboutContentSection/>
      {/* <BecomeMember/> */}
      <Comment/>
      <Footer/> 
    </div>
    
  )
}

export default Aboutus