import React from 'react';
import { useTranslation } from 'react-i18next';


const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <div className='w-100 d-flex justify-content-center align-items-center' style={{height:'100vh'}}>
        <div>
      <h2>{t('notFound.notFound')}</h2>
      <p>{t('notFound.pageNotFound')}</p>
      </div>
    </div>
  );
};

export default NotFoundPage;