import React from 'react'

const HeaderImg = ({ type }) => {
    if (type === "search") {
        return (

            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M20.0313 20.7901C20.4913 21.2501 21.2013 20.5401 20.7413 20.0901L16.9913 16.3301C18.3071 14.8748 19.0343 12.982 19.0313 11.0201C19.0313 6.63006 15.4613 3.06006 11.0713 3.06006C6.68133 3.06006 3.11133 6.63006 3.11133 11.0201C3.11133 15.4101 6.68133 18.9801 11.0713 18.9801C13.0513 18.9801 14.8813 18.2501 16.2813 17.0401L20.0313 20.7901ZM4.11033 11.0201C4.11033 7.18006 7.24033 4.06006 11.0703 4.06006C14.9103 4.06006 18.0303 7.18006 18.0303 11.0201C18.0303 14.8601 14.9103 17.9801 11.0703 17.9801C7.24033 17.9801 4.11033 14.8601 4.11033 11.0201Z" fill="black" />
            </svg>

        )
    } else if (type === "cart") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5.5 21C5.5 21.5304 5.71071 22.0391 6.08579 22.4142C6.46086 22.7893 6.96957 23 7.5 23C8.03043 23 8.53914 22.7893 8.91421 22.4142C9.28929 22.0391 9.5 21.5304 9.5 21M12.5 21C12.5 21.5304 12.7107 22.0391 13.0858 22.4142C13.4609 22.7893 13.9696 23 14.5 23C15.0304 23 15.5391 22.7893 15.9142 22.4142C16.2893 22.0391 16.5 21.5304 16.5 21M7 7.5H22.5V7.75L22.261 8.033C19.8317 10.918 18.4997 14.5684 18.5 18.34V18.5H5.5V16.62C5.5 14.54 5.434 12.462 5.114 10.408C4.56 6.852 3.337 1.5 1 1.5" stroke="black" strokeWidth="2" />
            </svg>

        )
    } else if (type === "contact") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21 15V12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12V15" stroke="black" strokeWidth="2" strokeLinecap="round" />
                <path d="M3 15V14.5H2.5V15H3ZM3 15.5H6V14.5H3V15.5ZM6.5 16V19H7.5V16H6.5ZM3.5 19V15H2.5V19H3.5ZM5 20.5C4.60218 20.5 4.22064 20.342 3.93934 20.0607C3.65804 19.7794 3.5 19.3978 3.5 19H2.5C2.5 19.663 2.76339 20.2989 3.23223 20.7678C3.70107 21.2366 4.33696 21.5 5 21.5V20.5ZM6.5 19C6.5 19.3978 6.34196 19.7794 6.06066 20.0607C5.77936 20.342 5.39782 20.5 5 20.5V21.5C5.66304 21.5 6.29893 21.2366 6.76777 20.7678C7.23661 20.2989 7.5 19.663 7.5 19H6.5ZM6 15.5C6.13261 15.5 6.25979 15.5527 6.35355 15.6464C6.44732 15.7402 6.5 15.8674 6.5 16H7.5C7.5 15.6022 7.34196 15.2206 7.06066 14.9393C6.77936 14.658 6.39782 14.5 6 14.5V15.5ZM21 15H21.5V14.5H21V15ZM18 15.5H21V14.5H18V15.5ZM20.5 15V19H21.5V15H20.5ZM17.5 19V16H16.5V19H17.5ZM19 20.5C18.6022 20.5 18.2206 20.342 17.9393 20.0607C17.658 19.7794 17.5 19.3978 17.5 19H16.5C16.5 19.663 16.7634 20.2989 17.2322 20.7678C17.7011 21.2366 18.337 21.5 19 21.5V20.5ZM20.5 19C20.5 19.3978 20.342 19.7794 20.0607 20.0607C19.7794 20.342 19.3978 20.5 19 20.5V21.5C19.663 21.5 20.2989 21.2366 20.7678 20.7678C21.2366 20.2989 21.5 19.663 21.5 19H20.5ZM18 14.5C17.6022 14.5 17.2206 14.658 16.9393 14.9393C16.658 15.2206 16.5 15.6022 16.5 16H17.5C17.5 15.8674 17.5527 15.7402 17.6464 15.6464C17.7402 15.5527 17.8674 15.5 18 15.5V14.5Z" stroke="black" strokeWidth="2"  />
            </svg>
        )
    } else if (type === "profile") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5 21V20C5 17.7909 6.79086 16 9 16H13C15.2091 16 17 17.7909 17 20V21" stroke="black" strokeWidth="2" strokeLinecap="round" />
                <path d="M11 13C9.34315 13 8 11.6569 8 10C8 8.34315 9.34315 7 11 7C12.6569 7 14 8.34315 14 10C14 11.6569 12.6569 13 11 13Z" stroke="black" strokeWidth="2" strokeLinecap="round" />
            </svg>
        )
    }
}

export default HeaderImg