import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
function Footer() {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);

    const generalSettingsData = useSelector(state => state.settingsApi.generalSettingsApi.data)
    const socialsData = useSelector(state => state.settingsApi.socialsSettingsApi.data)
    const footerMenu = useSelector(state => state.settingsApi.footerMenuSettingsApi.data)
    const scrollToContact = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        if (generalSettingsData && socialsData && footerMenu) {
            setIsLoading(false);
        }
    }, [generalSettingsData, socialsData, footerMenu]);
    const getValue = (key) => {
        if (!generalSettingsData) {
            return 'Loading...';
        }
        const foundItem = generalSettingsData.find((item) => item.key === key);
        return foundItem ? foundItem.value : 'Not found';
    };
    if (isLoading) {
        return ""
    };
    return (
<div>
      <div className="mediaQueryPadding" style={{ padding: "80px 100px 80px 100px" }}>
        <div className="row">
          {footerMenu.map((item, index) => (
            <div className="col-md-3 col-xs-12 col-sm-12 borderright1black lineheight15 mediaQueryBorder footerLink custom-link" key={index}>
              <div className="fnt20px mb-4"><strong>{item.menu_title}</strong></div>
              {item.data.map((dataItem, idx) => (
                <div key={idx} className="mb-2">
                  <a href={dataItem.url} title={dataItem.title}>{dataItem.title}
                    <strong className="fnt07rem mx-2"><i className="fas fa-chevron-right"></i></strong>
                  </a>
                </div>
              ))}
            </div>
          ))}
          <div className="col-md-2 col-xs-12 col-sm-12 lineheight15 pl3rem footerLink custom-link">
            <div className="fnt20px mb-4"><strong>{t('footer.quickMenu')}</strong></div>
            <div className="mb-2"><a href="/blog" title={t('footer.blog')}>{t('footer.blog')}<strong className="fnt07rem mx-2"><i className="fas fa-chevron-right"></i></strong></a></div>
            <div className="mb-2"><a href="/login" title={t('footer.login')}>{t('footer.login')}<strong className="fnt07rem mx-2"><i className="fas fa-chevron-right"></i></strong></a></div>
            <div className="mb-2"><a href="https://admin.cappadociavisitor.com" title={t('footer.b2bPanel')} target="_blank">{t('footer.b2bPanel')}<strong className="fnt07rem mx-2"><i className="fas fa-chevron-right"></i></strong></a></div>
            <div className="mb-2"><a href="/pages/bank-accounts" title={t('footer.bankAccount')}>{t('footer.bankAccount')}<strong className="fnt07rem mx-2"><i className="fas fa-chevron-right"></i></strong></a></div>
            <div className="mb-2"><button className='customButton ps-0' onClick={scrollToContact} title={t('footer.contact')}>{t('footer.contact')}<strong className="fnt07rem mx-2"><i className="fas fa-chevron-right"></i></strong></button></div>
          </div>
        </div>
      </div>
      <div className="bg-dark mediaQueryPadding" style={{ padding: "80px 100px 80px 100px" }}>
        <a className='custom-link' href='/tourlist' title={t('footer.tours')}>
          <div className="d-flex text-white pb-3 borderbottom1white">
            <div>{t('footer.tours')}</div>
            <div style={{ flexGrow: "1" }} align="right"><i className="fas fa-chevron-right"></i></div>
          </div>
        </a>
        <a className='custom-link' href='/review' title={t('footer.reviews')}>
          <div className="d-flex text-white pb-3 mt-3 borderbottom1white">
            <div>{t('footer.reviews')}</div>
            <div style={{ flexGrow: "1" }} align="right"><i className="fas fa-chevron-right"></i></div>
          </div>
        </a>
        <a className='custom-link' href='/pages/our-company' title={t('footer.ourCompany')}>
          <div className="d-flex text-white pb-3 mt-3 borderbottom1white">
            <div>{t('footer.ourCompany')}</div>
            <div style={{ flexGrow: "1" }} align="right"><i className="fas fa-chevron-right"></i></div>
          </div>
        </a>
        <div className="text-white pb-2 mt-md-5 mt-3 borderbottom1white socialIconSections" style={{ display: 'flex' }}>
          <div>{t('footer.followUs')}</div>
          <div style={{ flexGrow: "1" }} align="right">
            <div className="d-flex justify-content-end align-items-center socialIcon gap-md-3" style={{ justifyContent: 'end' }}>
            {socialsData.map((item) => (
  <div key={item.id}>
    <a href={item.value} title={item.title} target="_blank">
      <div className={item.type === "data" ? 'socialIconBigger text-white' : 'socialIcon text-white'}>
        {item.type === "data" ? (
          (() => {
            try {
              return <div dangerouslySetInnerHTML={{ __html: atob(item.icon) }} />;
            } catch (e) {
              return <div />;
            }
          })()
        ) : (
          <i className={item.icon}></i>
        )}
      </div>
    </a>
  </div>
))}

            </div>
          </div>
        </div>
        <div className="text-white mt-5" align="center">
          <div>La Liberte Turizm Limited Şirketi | TURSAB No: 9603</div>
          <div>{getValue("address")}</div>
          <div><a className='text-white' href={`tel:+9${getValue("phone")}`} target="_blank">{getValue("phone")}</a></div>
          <div><a className='text-white' href={`mailto:${getValue("support_email")}`} target="_blank">{getValue("support_email")}</a></div>
        </div>
      </div>
    </div>
    );
}

export default Footer;
