import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Comment from '../components/comment';
import Banner from '../components/banner';
import ProductNavbar from '../components/productNavbar';
import authRequest from './api/requests';
import LoadingPage from './loadingPage';
import TokenModal from './alerts/tokenModal';


const Profile = () => {

  document.title = "Profile | Cappadocia Visitor"

  const [isLoading, setIsLoading] = useState(true);
  const [errorData, setErrorData] = useState();
  const [showError, setShowError] = useState(false)
  const [data, setData] = useState([]);
  const closeModal = () => {
    localStorage.removeItem("token");
    setShowError(false);
    window.location.href = '/login';
  }

  useEffect(() => {
    setIsLoading(true);
    authRequest("/api/v1/my-accounts", "GET", null, setData, setIsLoading, setErrorData);

  }, []);

  useEffect(() => {
    if (errorData) {
      setShowError(true);
    }else {
      setShowError(false)
    }
  }, [errorData]);

  if (isLoading) {
    return (<LoadingPage />)
  };
  return (
    <div className="container-fluid w-100 h-100 p-0" >
      <Header />
      {errorData && <TokenModal props={errorData} status={showError} closeModal={closeModal} />}

      <ProductNavbar props={data} />
      <Comment />
      <Footer />
    </div>
  )
}

export default Profile