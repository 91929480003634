import React, { useEffect } from 'react';
import Header from './header'
import Comment from './comment'
import Footer from './footer'
import TourCategorySection from './tourCategorySection'



const TourCategoryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container-fluid w-100 h-100 p-0">
      <Header />
      <TourCategorySection />
      <Comment />
      <Footer />
    </div>

  )
}

export default TourCategoryPage