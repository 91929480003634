import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';



const initialState = {
    tourListApi: [],
    isLoading: true
}

export const getTourListApi = createAsyncThunk('getTourListApi', async() =>{
    const {data} = await axios.get("https://admin.cappadociavisitor.com/public/api/v1/tours/list")

    
    return data
})

export const tourListApiSlice = createSlice({
    name: "tourListApi",
    initialState,
    reducers :{

    },
    extraReducers: (builder) => {
        builder.addCase(getTourListApi.fulfilled, (state, action) => {
            state.tourListApi = action.payload;
            state.isLoading = false
        }) 
    }
})


export default tourListApiSlice.reducer