import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const token = localStorage.getItem("token") || "";
const reqHeader = token ? { Authorization: `Bearer ${token}` } : {};

const options = {
  headers: reqHeader
};



const initialState = {
    paginateApi: [],
    isLoading: true
}

export const getPaginateApi = createAsyncThunk('getPaginateApi', async ({ slug, currentPage }) => {
    const { data } = await axios.get(process.env.REACT_APP_API_URL + `/tours/list?category=${slug}&page=${currentPage}`, options);
    
    return data;
  });

export const paginateApiSlice = createSlice({
    name: "paginateApi",
    initialState,
    reducers :{

    },
    extraReducers: (builder) => {
        builder.addCase(getPaginateApi.fulfilled, (state, action) => {
            state.paginateApi = action.payload;
            state.isLoading = false
        }) 
    }
})


export default paginateApiSlice.reducer