import axios from 'axios';
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap';
import ForgotPwModal from './forgotPwModal';
import RegisterOptionModal from './registerOptionModal';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();

  document.title = "Login | Cappadocia Visitor"
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const handleFocus = () => {
    setErrors({
      email: '',
      password: '',
    });
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleClick = () => {
    let errorCount = 0;

    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];
      const type = key;

      if (value === '' || value === null) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [type]: t('validation.fieldEmpty'),
        }));
        errorCount++;
      } else if (type === 'email' && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [type]: t('validation.invalidEmail'),
        }));
        errorCount++;
      }
    });

    if (errorCount === 0) {
      axios.post(process.env.REACT_APP_API_URL + '/auth/login', formValues)
        .then(response => {
          console.log(localStorage.getItem("token"));

          const userInfo = response.data.data;
          delete userInfo.password;
          console.log('Başarıyla gönderildi:', response.data);
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("user", JSON.stringify(userInfo));

          window.location.href = '/';
        })
        .catch(error => {
          console.error('Hata oluştu:', error);
          setErrorMessage(error.response.data.err.message)

          setShowFailureModal(true);

        });
    }
  };
  const handleCloseFailureModal = () => {
    setShowFailureModal(false);
  };


  const showRegisterModal = () => setRegisterModal(true);
  const handleCloseRegisterModal = () => setRegisterModal(false);

  const [showForgotModal, setShowForgotModal] = useState(false);


  const handleCloseForgotModal = () => setShowForgotModal(false);


  return (
    <div className='bgLogin'>
      <div className='bgLoginLight'>
        <div className='d-flex align-items-center h-100'>
          <div className='w-100'>
            <Link to='/'><button className='customButton exitIcon'><i className="fas fa-arrow-left"></i></button></Link>
            <div className='loginPageDiv mediaQueryPadding'>
              <div className='fnt44px fw-bold'>{t('signUp.welcome')}</div>
              <div className='fnt14px mt-4'>
              {t('signUp.welcome')}   <br />{t('signUp.satisfaction')}
              </div>
              <div className='mt-3 fnt14px'>{t('registerForm.email')}</div>
              <div >
                <input type='text' placeholder={t('registerForm.enterEmail')} id="email" name="email" value={formValues.email} onChange={handleChange} onFocus={handleFocus} className={`w-100 whiteInput ${errors.email ? 'hata' : ''}`} />
                {errors.email && (
                  <div className='hata visible'>{errors.email}</div>
                )}
              </div>
              <div className='mt-3 fnt14px'>{t('password')}</div>
              <div>
                <input type='password' placeholder={t('signUp.enterPassword')} id="password" name="password" value={formValues.password} onChange={handleChange} onFocus={handleFocus} className={`w-100 whiteInput ${errors.password ? 'hata' : ''}`} />
                {errors.password && (
                  <div className='hata visible'>{errors.password}</div>
                )}
              </div>

              <div className='d-flex justify-content-end fnt08rem pt-1' >
                <button onClick={() => setShowForgotModal(true)} className='customButton text-white'>{t('signUp.forgotPassword')}</button>
              </div>
              <div className='mt-4'>
                <button className='btn bannerbtnwhite w-100 mt-1 fw-bold' style={{ borderRadius: "60px" }} onClick={handleClick}>{t('signUp.logIn')}</button>
              </div>
              <div className='mt-4 pb-2'>
                <button type="button" class="btn btn-danger w-100" style={{ borderRadius: "60px" }}>
                  <Link to={process.env.REACT_APP_API_URL + '/auth/google'} className='custom-link'>
                    <div className='position-relative'>
                      <div className='position-absolute'>
                        <div className='borderright1 ps-2'>
                          <i class="fa-solid fa-g pe-2"></i>
                        </div>
                      </div>
                      <div className='w-100 d-flex justify-content-center align-items-center'>
                      {t('signUp.googleLogin')}
                      </div>
                    </div>
                  </Link>
                </button>
              </div>
              <div className='mt-2 fnt12px' align='right'>{t('signUp.notMember')} <u><span onClick={() => showRegisterModal()} className=' p-0 text-white cpointer'>{t('signUp.signUp')}</span></u> {t('signUp.here')}</div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showFailureModal} onHide={handleCloseFailureModal} centered >
        <Modal.Header closeButton>
          <Modal.Title>{t('signUp.loginFailed')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button className='bgblue' onClick={handleCloseFailureModal}>
          {t('paymentForm.close')}
          </Button>
        </Modal.Footer>
      </Modal>
      <RegisterOptionModal show={registerModal} handleClose={handleCloseRegisterModal} />
      <ForgotPwModal show={showForgotModal} handleClose={handleCloseForgotModal} />
    </div>


  )
}

export default Login