import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const TokenModal = (props) => {

  const data = props.props.data;
  const status = props.status;
  const closeModal = props.closeModal
  return (
    <Modal show={status} centered >
      <Modal.Header>
        <Modal.Title>Session Timeout Error!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data.message}
      </Modal.Body>
      <Modal.Footer>
        <Button className='bgblue' onClick={closeModal} >
          Login
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TokenModal