import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';




const ForgotPwModal = ({ show, handleClose }) => {
const [email, setEmail] = useState('');
const { t } = useTranslation();


const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/forgot-password/send ', { email });
      console.log('Response:', response.data);
      handleClose();
    } catch (error) {
      console.error('Hata:', error);
    }
  };
  return (
    <Modal show={show} onHide={handleClose} centered >
        <Modal.Header  closeButton>
        <Modal.Title>{t('signUp.forgotPassword')}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
        <Modal.Body>
            
                <div>
                {t('registerForm.enterEmail')}
                <div>
                <input 
                className=' w-100 mt-2'
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('registerForm.enterEmail')}
                />
                </div>
                </div>
           

        </Modal.Body>

        <Modal.Footer>
            <Button type="submit" className='bgblue' >
            {t('signUp.send')}
            </Button>
        </Modal.Footer>
        </form>
    </Modal>
  )
}

export default ForgotPwModal



