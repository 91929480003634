
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import FeaturedCard from './featuredcard';
import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';


const Localfind = (propsData) => {
  const [isLoading, setIsLoading] = useState(true);

  const data= propsData.propsData;
  useEffect(() => {
    if (data.datas) {
      setIsLoading(false);
    }
  }, [data]);

  const breakpoints = {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    600: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    1150: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
  };


  // *******decode******
  const decodedData = atob(data.description);
  const textDecoder = new TextDecoder('utf-8');
  const decodedDescription = textDecoder.decode(new Uint8Array(decodedData.split('').map(char => char.charCodeAt(0))));
  // ************

  if(isLoading) {
    return ""
  };
  return (
            <div className='row'>
              <div className='col-md-12 col-xs-12 col-sm-12'>
                  <div className='d-flex align-items-center h-100 bigCard'>
                      <div className='w-100'>
                          <div className='baslik1'>{data.title}</div>
                          <div className='baslik2'>
                            <div className='d-flex pt-3'>
                               <div>{decodedDescription}</div>  
                               <div className='text-muted viewAllDiv z-3' style={{ flexGrow: 1 }} align="right">
                                <a href={`${data.section_button_link}`} className='custom-link'>
                                {data.section_button_text === null ? '' : <div><span className='px-1' style={{ fontWeight: "500" }}></span>{data.section_button_text} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><g fill="none" fillRule="nonzero"><path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z"/><path fill="#212529bf" d="m14.707 5.636 5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l3.95-3.95H4a1 1 0 1 1 0-2h13.243l-3.95-3.95a1 1 0 1 1 1.414-1.414Z"/></g></svg></div>}
                                </a>                
                              </div>
                            </div>  
                          </div>
                          <div className='mt-4'>
                            
                          <Swiper
                              slidesPerView={3}
                              spaceBetween={10}
                              pagination={true}
                              navigation={true}
                              mousewheel={true}
                              keyboard={true}
                              modules={[Navigation, Pagination]}
                              breakpoints={breakpoints}
                              className="featuredswiper"
                            >
                              {data.datas.map((card) => (
                                    <SwiperSlide key={card.id}>
                                        <FeaturedCard title={card.title} content={card.get_type.title} price={card.price} saleprice={card.total_price} star={card.star} image={card.featured_file} slug={card.slug} perType={card.per_type.per_text} type="localfind"/>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  );
};

export default Localfind;
  