import React, { useState, useEffect } from 'react';
import Header from './header'
import Footer from './footer'
import VoucherTransferSection from './voucherTransferSection'
import { useSelector, useDispatch } from 'react-redux'
import { getVoucherApi } from './api/voucher/voucherApiSlice';
import { useParams } from 'react-router-dom';
import LoadingPage from './loadingPage';
import authRequest from './api/requests';

const VoucherTransfer = () => {
    document.title ="Voucher | Cappadocia Visitor";
    const dispatch = useDispatch();
    const {slug} = useParams();
    const data = useSelector(state => state.voucherApi.voucherApi.data);
    const [cancelData, setCancelData] = useState([]);

    const isLoading = useSelector(state => state.voucherApi.isLoading);
    const [getLoading, setGetLoading] = useState(true);

    useEffect(()=>{
      dispatch(getVoucherApi({ slug: slug, voucherType: 'TRANSFER' }));

        setGetLoading(true);
        authRequest("/api/v1/tours/cancel_categories", "GET", null, setCancelData, setGetLoading);
     },[dispatch]);


    if (getLoading || isLoading) {
      return (<LoadingPage/>);
    }
    return (
      <div className="container-fluid w-100 h-100 p-0">
      <Header/>
      <VoucherTransferSection props={data} cancelData={cancelData}/>
      <Footer/>
      </div>
  
    )
}

export default VoucherTransfer