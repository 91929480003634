import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const TakeYouSection = (propsData) => {
  const [isLoading, setIsLoading] = useState(true);

  const data= propsData.propsData;
  console.log(data)
  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);
  


  function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}
console.log(data.datas[0].sub_medias[0].first_file.file)

  if(isLoading) {
    return ""
  };
  return (
    <div className='bg-dark mediaQueryPadding' style={{padding:"60px 100px 40px 100px", }}>

    <div className='row'>
    <div className='col-md-6 col-xs-6 col-sm-6'>
      <div className='d-flex align-items-center h-100'>
        <div className='w-100'>
          <div className='whereCanDiv' style={{maxWidth:"70%"}}>
            <div className='bigheadtext text-white'>{data.title}</div>
            <div className='text-white fnt16rem mt-4'>{b64DecodeUnicode(data.description)}</div>
            <div style={{paddingTop:"15%"}}><Link to={`${data.section_button_link}`} className='custom-link'>
              {data.section_button_text === null ? '' :<button className='btn p-3 px-5 bannerbtnwhite'><strong>{data.section_button_text}</strong></button>}
              </Link></div>
          </div>
        </div>
      </div>
    </div>
    

      <div className='col-md-4 col-xs-4 col-sm-4'>
      <div className='d-flex align-items-end justify-content-end h-100'>
        <div className='w-100' align="right">
            <div className='wecancard1 ' style={{width:'80%',backgroundImage: `url(${data.datas[0].sub_medias[0].first_file.file})`}}><div className='bgshadow'></div></div>
            <div className=' wecancard3 mt-4' style={{width:'100%',backgroundImage: `url(${data.datas[0].sub_medias[0].two_file.file})`}}><div className='bgshadow'></div></div>
        </div>
      </div>
    </div>
    
    

      <div className='col-md-2 col-xs-2 col-sm-2'>
      <div className='d-flex align-items-center h-100'>
        <div className='w-100' align="right">
            <div className='wecancard2' style={{width:'100%',backgroundImage: `url(${data.datas[0].sub_medias[0].three_file.file})`}}><div className='bgshadow'></div></div>

        </div>
      </div>
    </div>

  


  </div>
  </div>

  )
}

export default TakeYouSection