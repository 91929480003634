import React, { useEffect } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../components/header';
import ActivationsSection from '../components/activationsSection';
import Localfinds from '../components/localfindssection';
import Localhotels from '../components/localhotelsection';
import Footer from '../components/footer';
import Comment from '../components/comment';
import NewToursTourListSection from '../components/newToursTourListSection';
import BecomeMember from '../components/becomeMember';
import { useSelector, useDispatch } from 'react-redux'

import { getPageApi } from './api/pageApi/pageApiSlice';
import Activities from '../components/activities';
import BannerTourList from '../components/bannerTourList';
import LoadingPage from './loadingPage';
import Nowopentours from './nowopentours';
import Featuresection from './featuredsection';
import TakeYouSection from './takeYouSection';


function Routelist() {
  document.title = "Tour List | Cappadocia Visitor"
  const dispatch = useDispatch();
  const data = useSelector(state => state.pageApi.pageApi.data);
  const isLoading = useSelector(state => state.pageApi.isLoading);
  useEffect(() => {
    dispatch(getPageApi("tours"))
  }, [dispatch]);
  const renderSection = (section) => {
    switch (section.section_type.value) {
      case 'FEATURED_BANNER':
        return <BannerTourList propsData={section} />;
      case 'CARDS_REACTANGLE':
        return <div className='homeFeaturesection mediaQueryPadding' style={{ padding: "0px 100px 80px 100px" }}><Featuresection propsData={section} /></div>;
      case 'BANNER_SECTION':
        return <TakeYouSection propsData={section} />;
      case 'CARDS_BIG_REACTANGLE':
        return <div className='mediaQueryPadding' style={{ padding: "150px 100px 150px 100px" }}><Localfinds propsData={section} /></div>;
      case 'REGISTER_SECTION':
        return <BecomeMember propsData={section} />;
      case 'CARDS_BIG_BG_REACTANGLE':
        return <div className='mediaQueryPadding' style={{ padding: "120px 100px 120px 100px" }}><Localhotels propsData={section} /></div>;
      default:
        return null;
    }
  };
  // return <div><FiveNewOpen propsData={section} /></div> ;

  if (isLoading) {
    return (<LoadingPage />);
  }
  return (
    <div className="container-fluid w-100 h-100 p-0 tourListPageMain">
      <Header />
      {data.map((section, index) => (
        <div key={index} className={index === 1 ? 'pt-md-3 ' : ''}>
          {renderSection(section)}
        </div>
      ))}

      <Comment />
      <Footer />
    </div>
  );
}

export default Routelist;
