import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';



const initialState = {
    blogDetailApi: [],
    isLoading: true
}

export const getBlogDetailApi = createAsyncThunk('getBlogDetailApi', async(slug) =>{
    const {data} = await axios.get(`https://admin.cappadociavisitor.com/public/api/v1/blogs/${slug}`)

    
    return data
})

export const blogDetailApiSlice = createSlice({
    name: "blogDetailApi",
    initialState,
    reducers :{

    },
    extraReducers: (builder) => {
        builder.addCase(getBlogDetailApi.fulfilled, (state, action) => {
            state.blogDetailApi = action.payload;
            state.isLoading = false
        }) 
    }
})


export default blogDetailApiSlice.reducer