import axios from 'axios';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';




const ChangeForgotPswd = () => {
  const { t } = useTranslation();
    document.title ="ChangePassword | Cappadocia Visitor"
    const { token, email } = useParams();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [formdata, setFormData] = useState({
        token : token,
        email :email,
        password: '',
        password_confirmation: ''
    });

    const handlePasswordChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
        
    const postPasswdData = () => {
        axios.post(process.env.REACT_APP_API_URL + '/auth/forgot-password/reset-password', formdata)
        .then(response => {
            console.log('Başarıyla gönderildi:', response.data);
            setShowSuccessModal(true);
  
          })
        .catch(error => {
            console.error('Hata oluştu:', error);
            setErrorMessage(error.response.data.errorMessage)
            setShowFailureModal(true);
        });
    }




      const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.href = '/login';
      };
    
      const handleCloseFailureModal = () => {
        setShowFailureModal(false);
      };

  return (
    <div className='bgLogin'>
              <div className='bgLoginLight'>
                  <div className='d-flex align-items-center h-100'>
                      <div className='w-100'>
                      <Link to='/'><button className='customButton exitIcon'><i className="fas fa-arrow-left"></i></button></Link>
                          <div className='loginPageDiv mediaQueryPadding'> 
                            <div className='fnt44px fw-bold '>{t('changeForgotPswd.changePassword')}</div>
                            <div>
                                <div className='mt-3 fnt14px pb-2'>{t('password')}</div>
                              <div>
                                  <input 
                                    type="password"
                                    name="password"
                                    value={formdata.password}
                                    onChange={handlePasswordChange}
                                  className="w-100 whiteInput"/>
                              </div>
                            </div>
                            <div>
                                <div className='mt-3 fnt14px pb-2'>{t('signUp.passwordConfirm')}</div>
                              <div>
                                  <input 
                                    type="password"
                                    name="password_confirmation"
                                    value={formdata.password_confirmation}
                                    onChange={handlePasswordChange}
                                  className="w-100 whiteInput"/>
                              </div>
                              <div className='mt-4'>
                                  <button className='btn bannerbtnwhite w-100 mt-2 fw-bold' style={{borderRadius:"60px"}} onClick={postPasswdData} >{t('changeForgotPswd.changePassword')}</button>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
              <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered> 
        <Modal.Header closeButton>
          <Modal.Title>{t('mailVerify.mailVerifySuccess')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {t('mailVerify.mailVerifyCompleted')}
        </Modal.Body>
        <Modal.Footer>
          <Button className='bgblue' onClick={handleCloseSuccessModal}>
          {t('continue')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showFailureModal} onHide={handleCloseFailureModal} centered >
        <Modal.Header closeButton>
          <Modal.Title>{t('mailVerify.mailVerifyFailed')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button className='bgblue' onClick={handleCloseFailureModal}>
          {t('paymentForm.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ChangeForgotPswd