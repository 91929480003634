import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { setPerson, setReservation_date, setTime } from './api/tourDetailInfo/tourDetailInfoSlice';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';


const BannerTourDetail = (propsData) => {
    const { t } = useTranslation();

    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true);
    const personalizeData = useSelector(state => state.tourDetailInfo)

    const onTargetRef = propsData.onTargetRef;
    const data = propsData.propsData;
    const [classStyle, setClassStyle] = useState('col-md-4 col-xs-12 col-sm-12')
    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);

    // ******Datepicker*********



    const [startDate, setStartDate] = useState(new Date());

    const enableDates = (data.avaible_dates_calendar).map((item) => {
        // Virgüllerle ayrılmış string tarihleri parçalayarak diziye dönüştürüyoruz
        const startDateParts = item.start_date.split(',').map(part => parseInt(part, 10));
        const endDateParts = item.end_date.split(',').map(part => parseInt(part, 10));

        // Parçalanan dizileri kullanarak yeni Date nesneleri oluşturuyoruz
        const startDate = new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2]);
        const endDate = new Date(endDateParts[0], endDateParts[1] - 1, endDateParts[2]);

        return {
            start: startDate,
            end: endDate
        };
    });


    const enableDatesPrice = (data.avaible_dates).map((item) => ({
        start: new Date(item.start_date),
        end: new Date(item.end_date),
        price: item.total_price,
    }));
    enableDatesPrice.forEach(dateRange => {
        dateRange.start.setDate(dateRange.start.getDate() - 1);
    });
    const renderDayContents = (day, date) => {
        const currentDate = new Date(date);
        const interval = enableDatesPrice.find((interval) => {
            return (
                currentDate >= interval.start && currentDate <= interval.end
            );
        });

        const price = interval && interval.price;

        if (currentDate < new Date() && currentDate.toDateString() !== new Date().toDateString() || !price) {
            return (
                <>
                    <span>{day}</span>
                </>
            );
        }

        return (
            <>
                <span>{day}</span>
                <div className="dateNotes" style={{ fontSize: '0.5rem' }}>
                    €{price.length > 6 ? price.substring(0, price.indexOf(".")) : price}
                </div>
            </>
        );
    };
    // *************************


    // ******** time usabled olmadığı zamanlar için **********
    useEffect(() => {
        if (data.get_type.time_usabled !== "ACTIVE") {
            dispatch(setTime(data.avaible_times[data.avaible_times.length - 1]));
            setClassStyle('col-md-6 col-xs-12 col-sm-12')
        }
    }, [data]);
    // *************************


    // ***** set tourDetail ****



    const handlePersonChange = (e) => {
        const newAdult = e.target.value;
        dispatch(setPerson(newAdult));
    };

    const handleTimeChange = (e) => {
        const newTime = e.target.value;
        dispatch(setTime(newTime));
    };


    const handeleReservation_date = (date) => {
        const formattedDate = date.toLocaleDateString('en-CA'); // y-m-d formatında tarih dönüşümü
        dispatch(setReservation_date(formattedDate));
    };

    // ************





    if (isLoading) {
        return ""
    };
    return (
        <div className='tourDetailOption'>
            <div className="bannerbgvd tourDetailMain">
                <div className='bannerbgvideocontainer pt-3' style={{ backgroundImage: `url(${data.featured_file})` }}>
                    <div className="d-flex align-items-center h-100">
                        <div className="w-100">
                            <div className="bannersearchvd pt-3 pb-3 px-5" style={{ bottom: "-15%" }}>
                                <div className="row">
                                    <div className={`mb-2 ${classStyle}`}>
                                        <div className="mb-1 mt-2 text-white"><span className="fnt14px">{data.per_type.label}</span></div>
                                        <div>
                                            <select className='whiteborderinput' onChange={handlePersonChange} value={personalizeData.person ? personalizeData.person : null}>
                                                {personalizeData.person ? "" : <option disabled selected >{personalizeData.person ? "" : `${data.per_type.placeholder}`}  </option>}
                                                {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={i + 1} value={i + 1}>
                                                        {i + 1}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                    </div>
                                    <div className={classStyle}>
                                        <div className="mb-1 mt-2 text-white"><i className="fas fa-calendar"></i><span className="px-2 fnt14px">{t('date')}</span></div>
                                        <div>
                                            <DatePicker
                                                className="whiteborderinput datepickercss"
                                                selected={personalizeData.tour_date ? new Date(personalizeData.tour_date) : null}
                                                minDate={new Date()}
                                                includeDateIntervals={enableDates}
                                                onFocus={(e) => e.target.blur()}
                                                renderDayContents={renderDayContents}
                                                onChange={(date) => {
                                                    setStartDate(date);
                                                    handeleReservation_date(date);
                                                }}
                                                dateFormat="dd-MM-yyyy" 
                                                value={personalizeData.tour_date ? personalizeData.tour_date : `${t('validation.selectDate')}`}
                                            />
                                        </div>
                                    </div>

                                    {
                                        (data.get_type.time_usabled === "ACTIVE") &&
                                        <div className={classStyle}>
                                            <div className="mb-1 mt-2 text-white"><i className="fas fa-clock"></i><span className="px-2 fnt14px">{t('time')}</span></div>
                                            <div>
                                                <select className='whiteborderinput' onChange={handleTimeChange} value={personalizeData.time ? personalizeData.time : null} >
                                                    {personalizeData.time ? "" : <option disabled selected >{personalizeData.time ? "" : `${`${t('validation.selectTime')}`}`}  </option>}
                                                    {(data.avaible_times).map((item, index) => (
                                                        <option key={index}>{item}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    }

                                </div>

                                <div className='row mt-4'>
                                    <div className='col-md-12'>
                                        <button className='btn bg-white w-100 p-2' style={{ borderRadius: "60px", fontWeight: "600", fontSize: "18px" }} onClick={onTargetRef}>{t('bookNow')}</button>
                                    </div>
                                </div>

                            </div>
                            <div id='mqMarginTopId'>
                                <div className="bannerbaslik">{data.featured_title}</div>
                                <div className='mt-3 text-white fnt14px' style={{ maxWidth: "800px" }}>{data.featured_description}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className='mediaQueryTourDetail ' style={{ display: "none" }}>
                <div className="bannerbgvd">

                    <div className='bannerbgvideocontainer pt-3' style={{ backgroundImage: `url(${data.featured_file})` }}>
                        <div className="d-flex align-items-center h-100">
                            <div className="w-100">
                                <div>
                                    <div className="bannerbaslik">{data.featured_title}</div>
                                    <div className='mt-3 text-white fnt14px' style={{ maxWidth: "800px" }}>{data.featured_description}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mediaQueryPadding'>
                    <div className="bannersearchvd  pt-3 pb-3 px-5" style={{ bottom: "-15%" }}>
                        <div className="row">
                            <div className="col-md-4 col-xs-12 col-sm-12 mb-2">
                                <div className="mb-1 mt-2 text-white"><span className="fnt14px">{data.per_type.label}</span></div>
                                <select className='whiteborderinput' onChange={handlePersonChange} value={personalizeData.person ? personalizeData.person : null}>
                                                {personalizeData.person ? "" : <option disabled selected >{personalizeData.person ? "" : `${data.per_type.placeholder}`}  </option>}
                                                {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={i + 1} value={i + 1}>
                                                        {i + 1}
                                                    </option>
                                                ))}
                                            </select>
                                
                            </div>
                            <div >
                                <div className="mb-1 mt-2 text-white"><i className="fas fa-calendar"></i><span className="px-2 fnt14px">{t('date')}</span></div>
                                <div>
                                    <div>
                                        <DatePicker
                                            className="whiteborderinput"
                                            selected={personalizeData.tour_date ? new Date(personalizeData.tour_date) : null}
                                            includeDateIntervals={enableDates}
                                            minDate={new Date()}
                                            renderDayContents={renderDayContents} // you can use this prop
                                            onFocus={(e) => e.target.blur()}
                                            onChange={(date) => {
                                                setStartDate(date);
                                                handeleReservation_date(date);
                                            }}
                                            dateFormat="yyyy-MM-dd"
                                            value={personalizeData.tour_date ? personalizeData.tour_date : t('validation.selectDate')}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            (data.get_type.time_usabled === "ACTIVE") &&
                            <div className="mb-2">
                                <div className="mb-1 mt-2 text-white"><span className="fnt14px">{t('time')}</span></div>
                                <select className='whiteborderinput' onChange={handleTimeChange} value={personalizeData.time ? personalizeData.time : null} >
                                    {personalizeData.time ? "" : <option disabled selected >{personalizeData.time ? "" : t('validation.selectDate')}  </option>}
                                    {(data.avaible_times).map((item, index) => (
                                        <option key={index}>{item}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        <div className='row mt-4'>
                            <div className='col-md-12'>
                                <button className='btn bg-white w-100 p-2' style={{ borderRadius: "60px", fontWeight: "600", fontSize: "18px" }} onClick={onTargetRef}>{t('bookNow')}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default BannerTourDetail