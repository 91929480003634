import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const UpdateModal = (props) => {
    const { t } = useTranslation();
    const data = props.props;
    const status = props.status;
    const closeModal = props.close;

    return (
        <Modal show={status} onHide={closeModal} centered >
            <Modal.Header closeButton>
                <Modal.Title>{t('profilePage.changesSuccess')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {data.message}
            </Modal.Body>
            <Modal.Footer>
                <Button className='bgblue' onClick={closeModal}>
                {t('paymentForm.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    )

}

export default UpdateModal