import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import React from 'react';
import FeaturedCard from '../featuredcard';


const MiniCart = () => {
    const cards = [
        { id: 1, addclass: 'caricon', title: 'Add Destinations And Book For Your Transfer', content: 'Instant approval. If your plans change, you can cancel free of charge up to 24 hours before your scheduled pickup time.', image: "./components/images/f1.png" },
        { id: 2, addclass: 'policeicon', title: 'Meet Your Driver', content: 'You will be met on arrival and taken to your vehicle. Your driver will follow your flight and will wait for you if your flight lands early or is delayed.', image: "./components/images/f1.png" },
        { id: 3, addclass: 'mapicon', title: 'Arrive At Your Destination', content: 'Get to your destination quickly and safely. No waiting in line for a taxi or trying to figure out public transport.', image: "./components/images/f1.png" },
        { id: 4, addclass: 'caricon', title: 'Add Destinations And Book For Your Transfer', content: 'Instant approval. If your plans change, you can cancel free of charge up to 24 hours before your scheduled pickup time.', image: "./components/images/f1.png" },
        { id: 5, addclass: 'caricon', title: 'Add Destinations And Book For Your Transfer', content: 'Instant approval. If your plans change, you can cancel free of charge up to 24 hours before your scheduled pickup time.', image: "./components/images/f1.png" },
    ]
    const breakpoints = {
        0: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        770: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        1150: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
    };
  return (
    <div className='mediaQueryPadding' style={{ padding: "80px 100px 80px 100px" }}>
    <Swiper
        slidesPerView={3}
        spaceBetween={10}
        breakpoints={breakpoints}
        pagination={true}
        modules={[Pagination]}
        className="pb-5"
    >
        {cards.map((card) => (
            <SwiperSlide key={card.id}>
                <FeaturedCard title={card.title} addclass={card.addclass} content={card.content} price={card.price} saleprice={card.saleprice} star={card.star} image={card.image} type="transfer" />
            </SwiperSlide>
        ))}
    </Swiper>
</div>
  )
}

export default MiniCart