import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import authRequest from '../api/requests';
import LoadingPage from '../loadingPage';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';




const FinalThoughts = ({ props }) => {
    const { t } = useTranslation();
    const propsData = props;

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingTour, setIsLoadingTour] = useState(true);
    const [openedRez, setOpenedRez] = useState();
    const [typeRez, setTypedRez] = useState();
    const [responseData, setResponseData] = useState();
    const [dataTransfer, setDataTransfer] = useState([]);
    const [dataTour, setDataTour] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
        setResponseData()
    };
    const handleShow = (rezType, rezNo) => {
        const allData = [
            ...dataTransfer.data,
            ...dataTour.data
        ]
        const foundRez = allData.find((item) => item.rez_no === rezNo);
        setOpenedRez(foundRez)
        setTypedRez(rezType)
        setShow(true)
    };
    const [rating, setRating] = useState(5);
    const [hover, setHover] = useState(null);
    const [totalStars, setTotalStars] = useState(5);

    const [postData, setPostData] = useState({});
    useEffect(() => {

        authRequest("/api/v1/my-accounts/transfers", "GET", null, setDataTransfer, setIsLoading);
        authRequest("/api/v1/my-accounts/reservations", "GET", null, setDataTour, setIsLoadingTour);

    }, [responseData]);

    const changeForm = (e) => {
        const { value } = e.target;
        setPostData((prevValues) => ({
            ...prevValues,
            comment: value
        }))
    }

    // *****postComment******
    const postComment = () => {
        setIsLoading(true);
        const commentData = {
            ...postData,
            rate: rating,
            rez_no: openedRez.rez_no,
            type: typeRez
        }
        authRequest("/api/v1/my-accounts/review", "POST", commentData, setResponseData, setIsLoading);

    }
    // ********************

    if (isLoadingTour || isLoadingTour) {
        return (<LoadingPage />)
    };
    if (!dataTransfer.data || !dataTour.data) {
        return (<LoadingPage />)
    }


    return (
        <div style={{ paddingBottom: '35vh' }}>
            <div className='proCartDiv d-flex align-items-center padProduct' style={{ width: '60%', marginTop: '3rem' }}>
                <div className='h1 mqFs14rem'>{t('profilePage.final')}</div>
                <div className='mx-4 clrGray'>{t('profilePage.shareThoughts')}</div>
            </div>
            <div className='row'>
                <Col md={9} sm={12} xs={12}>
                    <div className='padProduct'>
                        <div className='tourListSection'>
                            <div className='h2 pt-5'>{t('profilePage.tourReservation')}</div>
                            {dataTour.data.length < 1 ?
                                <div>
                                    {t('profilePage.tourNotCompleted')}
                                </div>
                                :
                                <div>
                                    {dataTour.data.map((item, index) => (
                                        <div className='bordertopdashed mt-1 pt-3 pb-1'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <Link to={item.group_rez_no ? `/vouchergrp/${item.group_rez_no}` : `/voucher/${item.rez_no}`} className='custom-link'>
                                                    <div>
                                                        <div className='badge text-bg-primary'>{t('profilePage.rezNo')} {item.rez_no}</div>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='fw-bold'>{item.tour.title} </div>
                                                            <span className='clrGray fnt08rem ps-2'>{item.tour_date}</span>
                                                        </div>
                                                        <div className='d-flex  align-items-center'>
                                                            <div className='fnt08rem'>{item.tour.get_type.title}: </div>
                                                            <div className='clrGray ps-3 fnt08rem'>{item.tour.get_category.title}</div>
                                                        </div>
                                                    </div>
                                                </Link>


                                                {!(item.is_review) &&
                                                    <div>
                                                        <button className='productBtn' onClick={() => {
                                                            handleShow("TOUR", item.rez_no)
                                                        }}>
                                                            <div className='clrGray d-flex align-items-center'>
                                                            {t('profilePage.leaveComment')}
                                                                <span><i className="fas fa-comment-dots ps-3"></i></span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }

                        </div>
                        <div className='transferListSection'>
                            <div className='h2 pt-5'>{t('profilePage.transferReservation')}</div>
                            {
                                dataTransfer.data.length < 1 ?
                                    <div>
                                        {t('profilePage.transferNotCompleted')}
                                    </div>
                                    :
                                    <div>
                                        {dataTransfer.data.map((item, index) => (
                                            <div className='bordertopdashed mt-1 pt-3 pb-1'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <Link to={`/vouchertransfer/${item.rez_no}`} className='custom-link'>

                                                        <div>
                                                            <div className='badge text-bg-primary'>{t('profilePage.rezNo')} {item.rez_no}</div>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='fw-bold'>{item.pickup_location.name} - {item.return_location.name}</div>
                                                                <span className='clrGray ps-3 fnt08rem'>{item.reservation_date} {item.reservation_time}</span>
                                                            </div>
                                                            <div className='d-flex  align-items-center'>
                                                                <div className='fnt08rem'>{item.transfer_group.name}:</div>
                                                                <div className='clrGray ps-3 fnt08rem'>{item.car.name}</div>

                                                            </div>

                                                        </div>
                                                    </Link>
                                                    {!(item.is_review) &&
                                                        <div>
                                                            <button className='productBtn' onClick={() => {
                                                                handleShow("TRANSFER", item.rez_no)
                                                            }}>
                                                                <div className='clrGray d-flex align-items-center'>
                                                                {t('profilePage.leaveComment')}
                                                                    <span><i className="fas fa-comment-dots ps-3"></i></span>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    }


                                                </div>
                                            </div>
                                        ))}</div>


                            }

                        </div>


                    </div>
                </Col>
            </div>
            {openedRez?.rez_no &&
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    className='productModal'
                >
                    <Modal.Header closeButton>
                        <div className='pt-5'>
                            <div className="bgorange p-2" align='right'>
                                <div className='d-flex align-items-end clrGray fnt14rem fw-bold ps-md-4 ms-md-2' style={{ letterSpacing: '0.3rem' }}>
                                    {((typeRez) === "TRANSFER") &&
                                        <div className='pt-2 h4'>{openedRez.pickup_location.name} - {openedRez.return_location.name}</div>
                                    }
                                    {((typeRez) === "TOUR") &&
                                        <div className='pt-2 h4'>{openedRez.tour.title}</div>
                                    }
                                </div>
                                {/* <div className="ppModal ms-4"></div> */}
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='ps-4'>
                            <div>

                            </div>
                            <div>
                                <div className='pt-2'>{t('profilePage.comment')}</div>

                                <div className='clrGray pt-1'>
                                    <textarea
                                        type='text'
                                        className='productTextArea  w-100'
                                        placeholder={t('profilePage.leaveCommentHere')}
                                        onChange={changeForm}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12 col-xs-12 '>
                                    <div>{t('profilePage.rate')}</div>
                                    <div className='fnt10rem pt-1'>
                                        {[...Array(totalStars)].map((star, index) => {
                                            const currentRating = index + 1;

                                            return (
                                                <label key={index}>

                                                    <i
                                                        className={`fas fa-star cpointer pe-3 ${currentRating <= (hover || rating) ? 'text-warning' : 'text-secondary'
                                                            }`}
                                                        onMouseEnter={() => setHover(currentRating)}
                                                        onMouseLeave={() => setHover(null)}
                                                        onClick={() => setRating(currentRating)}
                                                    />

                                                </label>
                                            );
                                        })}

                                    </div>

                                </div>
                            </div>


                            <div className='pt-3'>
                                {responseData ?
                                    <div className='pt-3'>
                                        <div className='badge text-bg-success w-100 p-3'><span className='fnt12rem'>{t('profilePage.feedback')}</span></div>
                                    </div>
                                    :
                                    <div className='row'>
                                        <div className='col-md-7 col-sm-12 col-xs-12' />
                                        <div className='col-md-5 col-sm-12 col-xs-12'>
                                            <div className='pBtnDiv'>
                                                <button className='btn bgblue personalizeBtn text-white w-100 p-3' style={{ borderRadius: "60px", fontSize: "18px" }} onClick={() => postComment()}><i className="fas fa-paper-plane pe-2"></i>{t('signUp.send')}</button>
                                            </div>

                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>}


        </div>



    );




}

export default FinalThoughts
