import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';



const initialState = {
    blogCategoriesTitle: [],
    isLoading: true
}

export const getBlogCategoriesTitle = createAsyncThunk('getBlogCategoriesTitle', async() =>{
    const {data} = await axios.get(process.env.REACT_APP_API_URL + "/blog_categories")
    return data
})

export const blogCategoriesTitle = createSlice({
    name: "blogCategoriesTitle",
    initialState,
    reducers :{

    },
    extraReducers: (builder) => {
        builder.addCase(getBlogCategoriesTitle.fulfilled, (state, action) => {
            state.blogCategoriesTitle = action.payload;
            state.isLoading = false
        }) 
    }
})


export default blogCategoriesTitle.reducer