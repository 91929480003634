import React, { useEffect, useState } from 'react'
import Comment from '../components/comment';
import Modal from 'react-bootstrap/Modal';
import Header from '../components/header';
import Footer from '../components/footer';
import MiniCart from '../components/transferPage/miniCart';
import BannerTransfer from '../components/transferPage/bannerTransfer';
import MapSection from '../components/mapSection';
import TransferForm from './transferForm';
import CreditTransfer from './creditTransfer';
import authRequest from './api/requests';
import LoadingPage from './loadingPage';
import { useSelector, useDispatch } from 'react-redux'

function Payment() {
    document.title = "Transfer | Cappadocia Visitor"
    const dispatch = useDispatch()

    const [showModal, setShowModal] = useState(false);
    const [showCredit, setShowCredit] = useState(false);
    const [creditData, setCreditData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const handleShow = () => setShowModal(true);
    const handleClose = () =>{
        setShowModal(false);
        setShowCredit(false)
    } 
    const changeModal = () => setShowCredit(true);
    const allCars = useSelector(state => state.transferApi.transferCars)
    useEffect(() => {
        
        authRequest("/api/v1/transfers/info", "GET", null, setData, setIsLoading);

    }, []);
    if (isLoading) {
        return (<LoadingPage />)
    };
    if (!data.data) {
        return(<LoadingPage />)
    }
    return (
        <div className="container-fluid w-100 h-100 p-0">
            <Header />
            <BannerTransfer onShowModal={handleShow} data = {data} />
            {/* <MapSection /> */}
            <MiniCart />
            <Comment />
            <Footer />

            <Modal show={showModal} onHide={handleClose} centered dialogClassName="modal-90w paymentmodal"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header className='border-0' closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='container-fluid'>
                        {
                            showCredit ? <CreditTransfer data={data}/> : <TransferForm showCredit={changeModal} data={data}/>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Payment;
