import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  generalSettingsApi: [],
  socialsSettingsApi: [],
  footerMenuSettingsApi: [],
  api_servicesSettingsApi: [],
  interfaceSettingsApi: [],
  isLoading: true,
};
export const getSettingsApi = createAsyncThunk('getSettingsApi', async () => {
  const { data } = await axios.get(process.env.REACT_APP_API_URL + '/settings');
  const interfaceData = data.data.find(item => item.key === 'interface');
  const generalData = data.data.find(item => item.key === 'generals');
  const footerMenu = data.data.find(item => item.key === 'menu')
  const socialsData = data.data.find(item => item.key === 'socials');
  const api_servicesData = data.data.find(item => item.key === 'api_services');

  return { generalData, socialsData, api_servicesData, footerMenu, interfaceData };
});

export const settingsApiSlice = createSlice({
  name: 'settingsApi',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSettingsApi.fulfilled, (state, action) => {
      state.interfaceSettingsApi = action.payload.interfaceData;
      state.generalSettingsApi = action.payload.generalData;
      state.socialsSettingsApi = action.payload.socialsData;
      state.footerMenuSettingsApi = action.payload.footerMenu;
      state.api_servicesSettingsApi = action.payload.api_servicesData;
      state.isLoading = false;
    });
  },
});

export default settingsApiSlice.reducer;
