import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ShareModal from "./shareModal"
import authRequest from './api/requests';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import NavbarImg from "./navbarImg";
import { useTranslation } from 'react-i18next';

const FeaturedCard = ({ title, content, price, saleprice, star, image, type, addclass, commentUser, commentName, commentType, slug, description, createdTime, tourId, isFavorited, perType }) => {
  const [shareState, setShareState] = useState(false);
  const [favState, setFavState] = useState(isFavorited);
  const [favLoading, setFavLoading] = useState(false);
  const token = localStorage.getItem("token");
  const { t } = useTranslation();


  const shareCard = () => {
    setShareState(!shareState)
  }

  const toggleFav = (tourId) => {
    setFavLoading(true)
    authRequest(`/api/v1/my-accounts/favorite/${tourId}`, "POST", null, null, setFavLoading);
    setFavState(!favState)

  }

  const renderStars = () => {
    const totalStars = 5;
    const activeStars = star > 0 && star <= totalStars ? star : 0;
    const starIcons = Array.from({ length: totalStars }, (_, index) => (
      <i key={index} className={index < activeStars ? 'fas fa-star text-warning' : 'fas fa-star'} style={{ fontSize: "0.4rem" }}></i>
    ));
    return starIcons;
  };

  if (type === "feature") {

    return (
      <div className='position-relative'>
        <div className='shareIcon'>

          <button className='customButton' onClick={() => shareCard()}>
            {
              shareState ?
                <i className="fas fa-times pe-1" />
                :
                <NavbarImg type="share" />
            }
          </button>
        </div>
        {token &&
          <div className='favoriteIcon'>
            <button
              className='customButton'
              onClick={() => toggleFav(tourId)}
              disabled={favLoading}
            >
              <i className={favState ? "fa-solid fa-heart text-danger" : "fa-solid fa-heart text-white"} />
            </button>
          </div>
        }

        <div className="featuredcard">
          {
            shareState ?
              <ShareModal slug={slug} />
              :
              <Link to={`/tourdetail/${slug}`} className='custom-link'>
                <div>
                  <LazyLoadImage src={image} alt={title} className=' featuredcardheader w-100' />
                  <div className='featuredprice pt-2 pb-2 px-3'>
                    <div className='smallunderline'>{price} Eur</div>
                    <div className='text-white fnt10rem' style={{ lineHeight: "1.4rem" }}>{saleprice} Eur</div>
                    <div className='text-grey fnt06rem'>{perType}</div>
                    <div style={{ lineHeight: "0.8rem" }}>{renderStars()}</div>
                  </div>
                </div>
              </Link>
          }
          <Link to={`/tourdetail/${slug}`} className='custom-link'>
            <div className='px-4 pt-4 pb-4 feturedcarbody d-flex justify-content-between align-items-center' style={{ minHeight: '9rem' }}>
              <div className='leftSection'>
                <span style={{ fontWeight: '550' }} >{title}</span>
                <div className='fnt08rem'>{content}</div>
              </div>
              <div className='rightSection' style={{ fontSize: "1.5rem" }}>
                <i className="fas fa-chevron-right"></i>
              </div>
            </div>
          </Link>
        </div>
      </div>


    );

  } else if (type === "nowopen") {

    return (
      <div className='position-relative squareCard'>
        <div className='shareIcon'>
          <button className='customButton' onClick={() => shareCard()}>
            {
              shareState ?
                <i className="fas fa-times pe-1" />
                :
                <NavbarImg type="share" />
            }
          </button>
        </div>
        {token &&
          <div className='favoriteIcon'>
            <button
              className='customButton'
              onClick={() => toggleFav(tourId)}
              disabled={favLoading}
            >
              <i className={favState ? "fa-solid fa-heart text-danger" : "fa-solid fa-heart text-white"} />
            </button>
          </div>
        }

        {shareState ?
          <ShareModal slug={slug} />
          :

          <div className='nowopencard' >
            <LazyLoadImage src={image} alt={title} className=' nowOpenCardImg w-100 h-100' />
            <div className='nowopencardheader'>
              <div className='featuredprice pt-2 pb-2 px-3'>
                <div className='smallunderline'>{price} Eur</div>
                <div className='text-white fnt10rem' style={{ lineHeight: "1.4rem" }}>{saleprice} Eur</div>
                <div className='text-grey fnt06rem'>{perType}</div>
                <div style={{ lineHeight: "0.8rem" }}>{renderStars()}</div>
              </div>
            </div>
            <div className='cardfooter px-4 pt-4 pb-4'>
              <div>
                <strong style={{ fontSize: "1.7rem" }}>{title}</strong>
                <div className='w-100 rightButton'><Link to={`/tourdetail/${slug}`} className='custom-link'><button className="btn bg-white bannerbtnwhite" style={{ borderRadius: "60px", fontWeight: "500", minWidth: '10rem' }}>View Tours</button></Link></div>
              </div>
            </div>
          </div>
        }

      </div>

    );

  } else if (type === "localfind") {

    return (
      <div className='position-relative bigRectAngle'>
        <div className='shareIcon'>
          <button className='customButton' onClick={() => shareCard()}>
            {
              shareState ?
                <i className="fas fa-times pe-1" />
                :
                <NavbarImg type="share" />
            }
          </button>
        </div>
        {token &&
          <div className='favoriteIcon'>
            <button
              className='customButton'
              onClick={() => toggleFav(tourId)}
              disabled={favLoading}
            >
              <i className={favState ? "fa-solid fa-heart text-danger" : "fa-solid fa-heart text-white"} />
            </button>
          </div>
        }

        {shareState ?
          <ShareModal slug={slug} />
          :
          <Link to={`/tourdetail/${slug}`} className='custom-link'>
            <div className='localfindcard'>
              <div className='localfindcardheader' style={{ backgroundImage: `url(${image})` }}>
                <div className='featuredprice pt-2 pb-2 px-3'>
                  <div className='smallunderline'>{price} Eur</div>
                  <div className='text-white fnt10rem' style={{ lineHeight: "1.4rem" }}>{saleprice} Eur</div>
                  <div className='text-grey fnt06rem'>{perType}</div>
                  <div style={{ lineHeight: "0.8rem" }}>{renderStars()}</div>
                </div>
              </div>
              <div className='px-4 pt-4 pb-4' align="center" style={{ height: '13rem' }}>
                <div className='fnt20px text-muted pb-2'>{content}</div>
                <div className='fnt20px pb-2'><strong>{title}</strong></div>
                <div><hr style={{ width: "20%" }}></hr></div>
                <div><span className='px-1' style={{ fontWeight: "500" }}>{t('readMore')}</span> <i className="fas fa-long-arrow-alt-right"></i></div>
              </div>
            </div>
          </Link>
        }
      </div>

    );

  } else if (type === "localhotel") {

    return (
      <div className='position-relative bigBgRectAngle'>
        <div className='shareIcon'>
          <button className='customButton' onClick={() => shareCard()}>
            {
              shareState ?
                <i className="fas fa-times pe-1" />
                :
                <NavbarImg type="share" />
            }
          </button>
        </div>
        {token &&
          <div className='favoriteIcon'>
            <button
              className='customButton'
              onClick={() => toggleFav(tourId)}
              disabled={favLoading}
            >
              <i className={favState ? "fa-solid fa-heart text-danger" : "fa-solid fa-heart text-white"} />
            </button>
          </div>
        }

        {shareState ?
          <ShareModal slug={slug} />
          :
          <Link to={`/tourdetail/${slug}`} className='custom-link'>
            <div className='localhotelcard' style={{ backgroundImage: `url(${image})` }}>
              <div className='localhotelcardheader'>
                <div className='featuredprice pt-2 pb-2 px-3'>
                  <div className='smallunderline'>{price} Eur</div>
                  <div className='text-white fnt10rem' style={{ lineHeight: "1.4rem" }}>{saleprice} Eur</div>
                  <div className='text-grey fnt06rem'>{perType}</div>
                  <div style={{ lineHeight: "0.8rem" }}>{renderStars()}</div>
                </div>
              </div>
              <div className='cardfooter px-4 pt-4 pb-4 w-100'>
                <div className='d-flex'>
                  <div>
                    <strong style={{ fontSize: "1.3rem" }}>{title}</strong>
                    <div>{content}</div>
                  </div>
                  <div style={{ flexGrow: "1", fontSize: "1.5rem" }} align="right">
                    <div className='d-flex align-items-center h-100'>
                      <div className='w-100' align="right"><i className="fas fa-chevron-right"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        }
      </div>
    );

  } else if (type === "tourlistnowopen") {

    return (

      <div className='nowopencard nowopencardChng' style={{ backgroundImage: `url(${image})` }}>
        <div className='nowopencardheader'>
          <div className='featuredprice pt-2 pb-2 px-3'>
            <div className='smallunderline'>{price} Eur</div>
            <div className='text-white fnt10rem' style={{ lineHeight: "1.4rem" }}>{saleprice} Eur</div>
            <div className='text-grey fnt06rem'>{t('perPerson')}</div>
            <div style={{ lineHeight: "0.8rem" }}>{renderStars()}</div>
          </div>
        </div>
        <div className='cardfooter px-4 pt-4' style={{ paddingBottom: '1.5rem' }}>
          <div>
            <strong style={{ fontSize: "1.3rem" }}>{title}</strong>
            <div>{content}</div>
          </div>
        </div>
      </div>
    );

  } else if (type === "transfer") {

    return (
      <div className='transfercard' style={{ minHeight: '8rem', height: '8rem' }}>
        <div className='d-flex align-items-center h-100'>
          <div className='w-100'>
            <div className='row'>
              <div className='col-md-3 col-xs-12 col-sm-12 borderright2black transferCardImgDiv'>
                <div className='d-flex align-items-center h-100 '>
                  <div className='w-100' align="center">
                    <div className={addclass} id='transferCardImg'></div>
                  </div>
                </div>
              </div>
              <div className='col-md-9 col-xs-12 col-sm-12 transferCardBody d-flex justify-content-center align-items-center'>
                <div>
                  <div className='fnt10rem'><strong>{title}</strong></div>
                  <div className='fnt07rem'>{content}</div>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>
    );


  } else if (type === "modelCarTop") {

    return (
      <div className='modelCarTop' style={{ height: '16.2rem' }}>

        <LazyLoadImage src={image} alt={title} className=' modelCarTopheader w-100 h-100' />

      </div>
    );


  } else if (type === "productBanner") {
    return (
      <div className={addclass} style={{ padding: '8vw 10vw 5vw 10vw' }}>
        <div className="d-flex align-items-center h-100">
          <div className="w-100">
            <div className='becomeDiv'>
              <div className='bannerbaslik'>{title}</div>
              <div className='mt-4 text-white fnt12px' style={{ maxWidth: "800px" }}>{content}</div>
            </div>
          </div>
        </div>

      </div>
    );
  } else if (type === "activities") {
    return (
      <div className='activitiescard'>
        <div className='activitiescardheader' style={{ backgroundImage: `url(${image})` }}>
          <div className='activitiesprice pt-2 pb-2 px-3'>
            <div className='smallunderline'>{price} Eur</div>
            <div className='text-white fnt10rem' style={{ lineHeight: "1.4rem" }}>{saleprice} Eur</div>
            <div className='text-grey fnt06rem'>{perType}</div>
            <div style={{ lineHeight: "0.8rem" }}>{renderStars()}</div>
          </div>
        </div>
        <Link to={`/tourdetail/${slug}`} className='custom-link'>
          <div className='px-4 d-flex justify-content-between align-items-center activitiescarbody' style={{ minHeight: '134px', overflow: 'hidden' }}>
            <div>
              <strong style={{ fontSize: "1.3rem" }}>{title}</strong>
              <div>{content}</div>
            </div>
            <div style={{ fontSize: "1.5rem" }} >
              <div className='d-flex align-items-center h-100'>
                <div className='w-100' align="right"><i className="fas fa-chevron-right"></i></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );

  } else if (type === "comment") {
    return (
      <Link to={"/review"} className='custom-link'>
        <div className='commentcard p-3'>
          <div className='d-flex justify-content-between'>
            <div className='commentCardHeader'>
              <div className='d-flex align-items-center'>
                <LazyLoadImage src={image} alt={title} className=' commentavatar' />


                <div className='mx-2'>
                  <div className='text-muted'><strong><small>{commentUser}</small></strong></div>
                  <div className='fnt06rem' >{renderStars()}</div>
                </div>
              </div>
            </div>
            <div className='commentCardBody' align="right">
              <div className='d-flex align-items-center h-100'>
                <div className='w-100'>
                  <div className='fnt14px'>{commentName}</div>
                  <div className='fnt12px text-muted'><small>{commentType}</small></div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 mt-3 commentBody scrollY'>
            <div className='fnt12px commentText' style={{ lineHeight: "14px" }}>{content} </div>
          </div>
        </div>
      </Link>
    )
  } else if (type === "review") {
    return (
      <div className='commentcard p-3'>
        <div className='d-flex justify-content-between'>
          <div className='commentCardHeader'>
            <div className='d-flex align-items-center'>
              <div className='commentavatar' style={{ backgroundImage: `url(${image})` }}></div>
              <div className='mx-2'>
                <div className='text-muted'><strong><small>{commentUser}</small></strong></div>
                <div className='fnt06rem' >{renderStars()}</div>
              </div>
            </div>
          </div>
          <div className='' align="right">
            <div className='d-flex align-items-center h-100'>
              <div className='w-100'>
                <div className='fnt14px'>{commentName}</div>
                <div className='fnt12px text-muted'><small>{commentType}</small></div>
              </div>
            </div>
          </div>
        </div>
        <div className=' mt-3 reviewBody'>
          <div className='fnt12px commentText scrollY' style={{ lineHeight: "14px" }}>{content} </div>
        </div>
      </div>
    )
  } else if (type === "aboutBanner") {
    return (
      <div className={addclass} style={{ padding: '0vw 10vw 8vw 10vw' }}>
        <div className="d-flex align-items-center h-100">
          <div className="w-100">
            <div className='becomeDiv'>
              <div className='bannerbaslik'>{title}<br /></div>
              <div className='mt-4 text-white fnt12px' style={{ maxWidth: "800px" }}>{content}</div>
            </div>
          </div>
        </div>

      </div>
    );
  } else if (type === "activitiesBanner") {
    return (
      <div className="productbg" style={{ padding: '0vw 10vw 8vw 10vw', backgroundImage: `url(${image})` }}>
        <div className="d-flex align-items-center h-100">
          <div className="w-100">
            <div className='becomeDiv'>
              <div className='bannerbaslik'>{title}<br /></div>
              <div className='mt-4 text-white fnt12px' style={{ maxWidth: "800px" }}>{content}</div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === "balloonToursBanner") {
    return (
      <div className="productbg" style={{ padding: '0vw 10vw 8vw 10vw', backgroundImage: `url(${image})` }}>
        <div className="d-flex align-items-center h-100">
          <div className="w-100">
            <div className='becomeDiv'>
              <div className='bannerbaslik'>{title}</div>
              <div className='mt-4 text-white fnt12px' style={{ maxWidth: "800px" }}>{content}</div>
            </div>
          </div>
        </div>
      </div>
    );
    //     <div className='px-4 pt-4 pb-4 feturedcarbody d-flex justify-content-between align-items-center'>
    //     <div className='leftSection'>
    //       <span style={{fontWeight:'550'}} >{title}</span>
    //       <div className='fnt08rem'>{content}</div>
    //     </div>
    //     <div className='rightSection' style={{fontSize:"1.5rem"}}>
    //         <i className="fas fa-chevron-right"></i>
    //     </div>   
    // </div>
  } else if (type === "blogCard") {
    return (
      <a href={`/blogdetail/${slug}`} target="_blank" className='custom-link'>
        <div className='featuredcard' >
          <LazyLoadImage src={image} alt={title} className=' featuredcardheader w-100' />
          <div className='px-4 pt-4 pb-4 feturedcarbody'>
            <div className='d-flex justify-content-between align-items-center' style={{ minHeight: '72px' }}>
              <div>
                <span style={{ fontWeight: '550' }} >{title}</span>
              </div>
              <div className='ps-md-3'>
                <i className="fas fa-chevron-right"></i>
              </div>
            </div>
            <div className='pt-3'>
              <div className='fnt08rem clrGray blogDescSection'>{content}</div>
              <p className='fnt08rem pe-2 pt-2 text-secondary fw-500'>{description}</p>
            </div>
            <div className='d-flex justify-content-between align-items-center fnt08rem clrGray pt-2'>
              <div>
                {createdTime}
              </div>
              <div >
              {t('seeMore')}
              </div>
            </div>
          </div>
        </div>
      </a>
    );

  } else if (type === "blogDetailCard") {

    const decodedData = atob(content);
    const textDecoder = new TextDecoder('utf-8');
    const decodedContent = textDecoder.decode(new Uint8Array(decodedData.split('').map(char => char.charCodeAt(0))));

    return (
      <div className='featuredcard' >
        <LazyLoadImage src={image} alt={title} className=' featuredcardheader w-100' />
        <div className='px-4 pt-4 pb-4 feturedcarbody'>
          <div className='d-flex'>
            <div>
              <div className='d-flex align-items-center justify-content-between'>
                <div>
                  <span style={{ fontWeight: '550' }} >{title}</span>
                  <div className='fnt08rem clrGray mt-2'>{description}</div>
                </div>
                <div className='fnt08rem clrGray pe-2'>{createdTime}</div>
              </div>

              <p className='fnt08rem pe-2 pt-2 text-secondary fw-500'><div dangerouslySetInnerHTML={{ __html: decodedContent }} /></p>
            </div>

          </div>

        </div>
      </div>
    );

  } else if (type === "tourCard") {
    return (
      <Link to={`/blogdetail/${slug}`} className='custom-link'>
        <div className='featuredcard' >
          <LazyLoadImage src={image} alt={title} className=' featuredcardheader w-100' />
          <div className='px-4 pt-4 pb-4 feturedcarbody'>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <span style={{ fontWeight: '550' }} >{title}</span>
              </div>
              <div>
                <i className="fas fa-chevron-right"></i>
              </div>
            </div>
            <div className='pt-3'>
              <div className='fnt08rem clrGray blogDescSection'>{content}</div>
              <p className='fnt08rem pe-2 pt-2 text-secondary fw-500'>{description}</p>
            </div>
            <div className='d-flex justify-content-between align-items-center fnt08rem clrGray pt-2'>
              <div>
                {createdTime}
              </div>
              <div >
              {t('seeMore')}
              </div>
            </div>
          </div>
        </div>
      </Link>
    );

  } else if (type === "paginateCard") {

    return (
      <div className='position-relative'>
        <div className='shareIcon'>
          <button className='customButton' onClick={() => shareCard()}>
            {
              shareState ?
                <i className="fas fa-times pe-1" />
                :
                <NavbarImg type="share" />
            }
          </button>
        </div>
        {token &&
          <div className='favoriteIcon'>
            <button
              className='customButton'
              onClick={() => toggleFav(tourId)}
              disabled={favLoading}
            >
              <i className={favState ? "fa-solid fa-heart text-danger" : "fa-solid fa-heart text-white"} />
            </button>
          </div>
        }
        <div className="featuredcard " >
          {
            shareState ?
              <ShareModal slug={slug} />

              :
              <Link to={`/tourdetail/${slug}`} className='custom-link'>

                <div>
                  <LazyLoadImage src={image} alt={title} className=' featuredcardheader w-100' />

                  <div className='featuredprice pt-2 pb-2 px-3'>
                    <div className='smallunderline'>{price} Eur</div>
                    <div className='text-white fnt10rem' style={{ lineHeight: "1.4rem" }}>{saleprice} Eur</div>
                    <div className='text-grey fnt06rem'>{perType}</div>
                    <div style={{ lineHeight: "0.8rem" }}>{renderStars()}</div>
                  </div>
                </div>
              </Link>
          }



          <Link to={`/tourdetail/${slug}`} className='custom-link'>

            <div className='px-4 pt-4 pb-4 feturedcarbody d-flex justify-content-between align-items-center'>
              <div className='leftSection'>
                <span style={{ fontWeight: '550' }} >{title}</span>
                <div className='fnt08rem'>{content}</div>
              </div>
              <div className='rightSection' style={{ fontSize: "1.5rem" }}>
                <i className="fas fa-chevron-right"></i>
              </div>
            </div>
          </Link>

        </div>

      </div>

    );

  }


};

export default FeaturedCard;