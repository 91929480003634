import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const EmailVerify = () => {
    const { t } = useTranslation();

    document.title ="Verify | Cappadocia Visitor"

    const { token, email } = useParams();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const formdata = {
        verify_token : token,
        email :email
    };
        
    
    useEffect(() => {

        axios.post(process.env.REACT_APP_API_URL + '/auth/email-verify', formdata)
      .then(response => {
          console.log('Başarıyla gönderildi:', response.verifyData);
          setShowSuccessModal(true);

        })
      .catch(error => {
          console.error('Hata oluştu:', error);
          setErrorMessage(error.response.data.errorMessage)
          setShowFailureModal(true);
      });
    
      }, []);


      const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.href = '/login';
      };
    
      const handleCloseFailureModal = () => {
        setShowFailureModal(false);
      };
      

  return (
    <div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered> 
        <Modal.Header closeButton>
          <Modal.Title>{t('mailVerify.mailVerifySuccess')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {t('mailVerify.mailVerifyCompleted')}
        </Modal.Body>
        <Modal.Footer>
          <Button className='bgblue' onClick={handleCloseSuccessModal}>
          {t('continue')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showFailureModal} onHide={handleCloseFailureModal} centered >
        <Modal.Header closeButton>
          <Modal.Title>{t('mailVerify.mailVerifyFailed')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button className='bgblue' onClick={handleCloseFailureModal}>
          {t('paymentForm.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default EmailVerify