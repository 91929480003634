import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';



const initialState = {
    locations: [],
}

export const getLocations = createAsyncThunk('getLocations', async() =>{
    const {data} = await axios.get('http://localhost:3001/location')
    return data
})

export const locationsSlice = createSlice({
    name: "locations",
    initialState,
    reducers :{

    },
    extraReducers: (builder) => {
        builder.addCase(getLocations.fulfilled, (state, action) => {
            state.locations = action.payload;
        })
    }
})


export default locationsSlice.reducer