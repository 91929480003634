import React, { useState } from "react";
import { GoogleMap, DirectionsRenderer, LoadScript } from "@react-google-maps/api";

const locations = [
  { lat: 39.919, lng: 32.847 },
  { lat: 36.900, lng: 30.707 },

];
function MapComponents() {
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);

  const handleOnLoad = map => {
    setMap(map);

    const directionsService = new window.google.maps.DirectionsService();

    if (locations.length >= 2) {
      directionsService.route(
        {
          origin: new window.google.maps.LatLng(locations[0].lat, locations[0].lng),
          destination: new window.google.maps.LatLng(locations[locations.length - 1].lat, locations[locations.length - 1].lng),
          waypoints: locations.slice(1, -1).map(location => ({
            location: new window.google.maps.LatLng(location.lat, location.lng),
            stopover: true
          })),
          travelMode: window.google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.error(`Error fetching directions: ${status}`);
          }
        }
      );
    }
  };

  return (
    <LoadScript googleMapsApiKey="">
      <GoogleMap
        onLoad={handleOnLoad}
        mapContainerStyle={{ width: "100%", height: "100%" , borderRadius:'15px'}}
      >
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
    </LoadScript>
  );
}

export default MapComponents;
