import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import authRequest from '../api/requests';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const SecurityModal = ({ props }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [passwordData, setPasswordData] = useState();
    const [responsePw, setResponsePw] = useState();
    const [responseErr, setResponseErr] = useState();

    const [successModal, setSuccessModal] = useState(false);
    const [failedModal, setFailedModal] = useState(false);
    const closeModal = () => {
        setSuccessModal(false);
        setFailedModal(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPasswordData({
            name: props.name,
            surname: props.surname,
            ...passwordData,
            [name]: value,
        });
    };
    const postData = () => {
        authRequest("/api/v1/my-accounts/update", "POST", passwordData, setResponsePw, setIsLoading, setResponseErr);
    }
    useEffect(() => {
        if (responsePw?.error === false) {
            setSuccessModal(true);
            setFailedModal(false);
        } else if (responseErr?.data?.error === false) {
            setSuccessModal(false);
            setFailedModal(true);
        }
    }, [responsePw, responseErr]);

    return (
        <div>
            <div className='proCartDiv d-flex align-items-center padProduct' style={{ width: '700px', marginTop: '3rem' }}>
                <div className='h1 mqFs14rem'>{t('profilePage.security')}</div>
                <div className='mx-4 clrGray'>{t('profilePage.changePassword')}</div>
            </div>

            <div className='row '>
                <Col md={9} sm={12} xs={12}>
                    <div className='padProduct pe-3'>
                        <div className='row'>
                            <div className='bordertopdashed mt-1'>
                                <Col md={10} sm={12} xs={12}>

                                    <div className='mt-3'>
                                        <div>{t('profilePage.currentPassword')}</div>
                                        <div className='mt-2'>
                                            <input type='password' className='rezervationinput w-100' placeholder='*********' name='current_password' onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className='mt-3'>
                                        <div>{t('profilePage.newPassword')}</div>
                                        <div className='mt-2'>
                                            <input type='password' className='rezervationinput w-100' placeholder='*********' name='password' onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className='mt-3'>
                                        <div>{t('profilePage.confirmNewPassword')}</div>
                                        <div className='mt-2'>
                                            <input type='password' className='rezervationinput w-100' placeholder='*********' name='password_again' onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '3rem', paddingBottom: '10rem' }}><button className='btn bgblue personalizeBtn text-white w-100 p-3' style={{ borderRadius: "60px", fontWeight: "600", fontSize: "18px" }} onClick={postData}>Save</button></div>
                                </Col>
                            </div>
                        </div>
                    </div>

                </Col>
            </div>
            <Modal show={successModal} onHide={closeModal} centered >
                <Modal.Header closeButton>
                    <Modal.Title>{t('profilePage.passwordChangeSuccess')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{t('profilePage.passwordUpdateSuccess')}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='bgblue' onClick={closeModal}>
                    {t('paymentForm.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={failedModal} onHide={closeModal} centered >
                <Modal.Header closeButton>
                    <Modal.Title>{t('profilePage.passwordChangeFailed')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{responseErr?.data?.message}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='bgblue' onClick={closeModal}>
                    {t('paymentForm.close')}
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>
    );
}

export default SecurityModal