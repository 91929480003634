import React, { useEffect, useState } from 'react'
import authRequest from './api/requests';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { useSelector, useDispatch } from 'react-redux'
import { setProduct_id } from './api/transfer/transferInfoSlice';
import DatePicker from 'react-datepicker';
import FeaturedCard from './featuredcard';
import Select from 'react-select';
import LoadingPage from './loadingPage';
import { setTransferInfo } from './api/transfer/transferInfoSlice';
import { getTransferApi } from './api/transfer/transferApiSlice';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';


const TransferForm = ({ showCredit, data }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const transferData = useSelector(state => state.transferInfo)
    const returnLocations = useSelector(state => state.transferApi.returnLocations)
    const pickupLocations = useSelector(state => state.transferApi.pickupLocations)
    const prices = useSelector(state => state.transferApi.prices)
    const allCars = useSelector(state => state.transferApi.transferCars)
    const selectableProducts = useSelector(state => state.transferApi.products)
    const selectedTypeStatus = useSelector(state => state.transferApi.selectedType.type)
    const [selectedCarID, setSelectedCarID] = useState(allCars[0].id)
    const selectedCar = (allCars).find(item => item.id === selectedCarID);
    // ******id ile name esitleme ****
    const selectedType = (data.infos.transfer_groups).find(item => item.id === parseInt(transferData.transfer_group_id));
    const picupLocation = (pickupLocations).find(item => item.id === parseInt(transferData.pickup_location_id));
    const returnLocation = (returnLocations).find(item => item.id === parseInt(transferData.return_location_id));
    // ***********************
    const [isLoading, setIsLoading] = useState(true);
    const [geoCodes, setGeoCodes] = useState(null);
    const [countries, setCountries] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [errors, setErrors] = useState({});
    const [counts, setCounts] = useState({
        person_count: 1,
        car_count: 1,
        product_count: 1,

    });
    const [formData, setFormData] = useState({})
    const [postData, setPostData] = useState({
        ...counts,
        ...transferData,
        car_id: selectedCarID,
    })
    useEffect(() => {

        setPostData({
            ...counts,
            ...transferData,
            car_id: selectedCarID,
        })
    }, [transferData]);


    const increment = (type) => {
        if (type === "person_count" || type === "product_count") {
            setPostData((prevCounts) => ({
                ...postData,
                [type]: prevCounts[type] < (selectedCar.person) * (postData.car_count) ? prevCounts[type] + 1 : prevCounts[type],
            }));

        } else {
            setPostData((prevCounts) => ({
                ...postData,
                [type]: prevCounts[type] + 1,

            }));
        }


    };



    const decrement = (type) => {
        if (type === "car_count") {
            setPostData((prevCounts) => ({
                ...prevCounts,
                [type]: prevCounts[type] > 0 ? prevCounts[type] - 1 : 0,
                person_count: 0,
            }));

        } else {
            setPostData((prevCounts) => ({
                ...prevCounts,
                [type]: prevCounts[type] > 0 ? prevCounts[type] - 1 : 0,
            }));
        }

    };



    const changeCar = (carId) => {
        setSelectedCarID(carId)
        setPostData({
            ...postData,
            car_id: carId
        })

    }


    const handleCheckboxChange = (options) => {
        const uptadetOptions = (transferData.product_id).includes(options)
            ? (transferData.product_id).filter((opt) => opt !== options)
            : [...(transferData.product_id), options];

        dispatch(setProduct_id(uptadetOptions));


    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        authRequest("/api/v1/definations/countries", "GET", null, setCountries, setIsLoading);
        authRequest("/api/v1/definations/geo-codes", "GET", null, setGeoCodes, setIsLoading);
    }, []);

    const handleBirthDate = (date) => {
        const formattedDate = date.toLocaleDateString('en-CA'); // y-m-d formatında tarih dönüşümü
        setFormData({
            ...formData,
            birthdate: formattedDate,
        });
    };
    const handleSelectChange = (selectedOption, selectName) => {
        if (selectName === "country_id") {
            setFormData({
                ...formData,
                country_id: selectedOption.value,
            });
        } else if (selectName === "dual_code") {
            setFormData({
                ...formData,
                dual_code: selectedOption.value,
            });
        }
    };


    const handleOptionChange = (e) => {
        const { value } = e.target;

        setFormData({
            ...formData,
            gender: value,
        });

        setErrors({
            ...errors,
            gender: '',
        });
    };

    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleFocus = (name) => {
        setErrors({
            ...errors,
            [name]: '',
        });
    };
    useEffect(() => {
        dispatch(getTransferApi(postData))
    }, [dispatch, postData]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const fields = [
            { name: "name", message: t('validation.invalidName') },
            { name: "surname", message: t('validation.invalidSurname') },
            { name: "email", message: t('validation.invalidEmail') },
            { name: "gender", message: t('validation.invalidGender') },
            { name: "passaport_no", message: t('validation.invalidPassword') },
            { name: "birthdate", message: t('validation.invalidBirthDate') },
            { name: "country_id", message: t('validation.invalidCountry') },
            { name: "dual_code", message: t('validation.invalidDualCode') },
            { name: "flight_number", message: t('validation.invalidFlightNo') },
            { name: "phone", message: t('validation.invalidPhoneCode') },

        ];

        const newErrors = {};

        fields.forEach((field) => {
            if (!formData[field.name]) {
                newErrors[field.name] = field.message;
            }
        });
        if (selectedTypeStatus === "PRIVATE") {
            if (postData.person_count < 1) {
                newErrors.person_count = t('transfer.selectPersonNo')
            }
            if (postData.car_count < 1) {
                newErrors.car_count = t('transfer.selectCarNo')
            }
            if (postData.product_id.length > 0) {
                if (postData.product_count < 1) {
                    newErrors.product_count = t('transfer.selectProductNo')
                }
            }

        }





        if (!postData.car_id) {
            newErrors.car_id = t('transfer.selectVehicle')
        }

        if (formData.email) {
            if (!isEmailValid(formData.email)) {
                newErrors.email = t('validation.invalidEmail') ;
            }
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        const allData = {
            ...postData,
            ...formData,
        }
        dispatch(setTransferInfo(allData));
        showCredit();

    };

    if (!countries) {
        return (
            <LoadingPage />
        )
    }
    if (!geoCodes) {
        return (
            <LoadingPage />
        )
    }
    return (
        <div className='modelFirstBorder transferForm'>
            <div className='mb-2 fnt14rem fw-bold'>
                {selectedType.name}
            </div>
            <div className='row'>
                <div className='col-md-4 col-sm-12 col-xs-12 p-2 pt-0 paymentModalMQCol'>
                    <div className='pe-2 pb-4'>
                        <FeaturedCard image={selectedCar.image} type="modelCarTop" />
                    </div>
                    <div >
                        <div className='mb-2 fnt09rem'>{selectedCar.name}</div>
                        <div className='d-flex justify-content-between'>
                            <div className='text-muted fnt08rem'><i className="fas fa-user"></i><span className='mx-2'>{selectedCar.person} {t('transfer.people')}</span></div>
                            <div className='text-muted fnt08rem mt-1'><i className="fas fa-suitcase"></i><span className='mx-2'>{selectedCar.baggage} {t('transfer.suitcase')}</span></div>
                        </div>

                    </div>
                    <div className='d-flex justify-content-between align-items-center mt-4 pt-1 '>

                        {selectableProducts.length > 0 &&
                            <div className='radioOptions pb-3 position-relative'>
                                <div className='mb-2 fnt09rem'>{t('transfer.personalize')}</div>

                                {selectableProducts.map((item, index) => (
                                    <div className='position-relative'>
                                        <div className='text-muted fnt08rem d-flex '>
                                            <input type='checkbox'
                                                name='optionPayment'
                                                value={item.id}
                                                checked={(transferData.product_id).includes(item.id)}
                                                onChange={() => handleCheckboxChange(item.id)}
                                            />
                                            <span className='mx-2'>{item.name}  €{item.price}</span>
                                        </div>
                                        {
                                            postData.product_id.length > 0 &&
                                            <div className='productCounter'>
                                                <div className='fnt08rem text-muted d-flex align-items-center'>
                                                    <button className='bgblue text-white fnt07rem buttonTransfer' onClick={() => decrement('product_count')}><i className="fas fa-minus"></i></button>
                                                    <span className='px-1' style={{ minWidth: '1rem' }}>{postData.product_count}</span>
                                                    <button className='bgblue text-white fnt07rem buttonTransfer' onFocus={() => handleFocus('product_count')} onClick={() => increment('product_count')}><i className="fas fa-plus"></i></button>
                                                </div>

                                            </div>
                                        }

                                    </div>


                                ))}
                                {(transferData.product_id) &&
                                    <span className='d-flex' style={{ color: 'red', fontSize: '0.8rem' }}>{errors.product_count}</span>
                                }


                            </div>
                        }
                        {/* {
                            selectedTypeStatus === "PRIVATE" &&
                            <div className='pb-3 col-4'>
                                <div className='mb-2 fnt09rem'>Number Of Cars</div>
                                <div className='fnt08rem text-muted d-flex align-items-center'>
                                    <button className='bgblue text-white fnt07rem buttonTransfer' onClick={() => decrement('car_count')}><i className="fas fa-minus"></i></button>
                                    <span className='px-1' style={{ minWidth: '1rem' }}>{postData.car_count}</span>
                                    <button className='bgblue text-white fnt07rem buttonTransfer' onFocus={() => handleFocus('car_count')} onClick={() => increment('car_count')}><i className="fas fa-plus"></i></button>
                                    {errors.cars ? <span className='mx-2' style={{ color: 'red' }}>Cars*</span> : <span className='mx-2'>Cars</span>}
                                </div>
                                <span className='d-flex' style={{ color: 'red', fontSize: '0.8rem' }}>{errors.car_count}</span>

                            </div>

                        } */}


                        <div className='pb-3 '>
                            <div className='mb-2 fnt09rem'>{t('numPassenger')}</div>
                            <div className='fnt08rem text-muted d-flex align-items-center'  >
                                <button className='bgblue text-white fnt07rem buttonTransfer' onClick={() => decrement('person_count')}><i className="fas fa-minus"></i></button>
                                <span className='px-1' style={{ minWidth: '1rem' }}>{postData.person_count}</span>
                                <button className='bgblue text-white fnt07rem buttonTransfer' onFocus={() => handleFocus('person_count')} onClick={() => increment('person_count')}><i className="fas fa-plus"></i></button>
                                {errors.person_count ? <span className='mx-2' style={{ color: 'red' }}>Person*</span> : <span className='mx-2'>Person</span>}
                            </div>
                            <span className='d-flex' style={{ color: 'red', fontSize: '0.8rem' }}>{errors.person_count}</span>

                        </div>

                    </div>


                    {/* <div>
                        <button className='btn bgorange personalizeBtn text-white w-100 mt-4'
                            style={{ borderRadius: "60px", fontSize: "14px" }}
                            onClick={addToList}
                        >
                            Add To List
                        </button>
                    </div>
                    <div className='w-100 text-center'>
                        <span className='text-center w-100' style={{ color: 'red' }}>
                            {errors.selectedCar}
                        </span>
                    </div>


                    <div className='modelSearchFirst text-white mt-4 p-3'>
                        <div className='borderbottom1whiteDashed'>List of Vehicles</div>
                        <div className='text-grey mt-1 pb-5'>
                            {selectedCar.name &&
                                <div className='row'>
                                    <div className='col-4'>{selectedCar.name}</div>
                                    <div className='col-4 d-flex align-items-center justify-content-center'>Person: {postData.person} </div>
                                    <div className='col-4 d-flex align-items-center justify-content-end'>
                                        <button className='customButton' onClick={deleteCar}>
                                            <i className="fas fa-trash-alt  mx-2 text-warning" />
                                        </button>
                                    </div>
                                </div>
                            }

                        </div>
                        {selectedCar.saleprice &&
                            <div className='pt-5' align='right'>
                                <div className='borderbottom1whiteDashed mqWauto d-flex justify-content-between pb-2' style={{ width: '200px' }} >
                                    <div className='mt-1'>Amount</div>
                                    <div className='fnt20px'>{selectedCar.saleprice}</div>
                                </div>
                            </div>
                        }

                    </div> */}
                    <div className='mt-5'>
                        <div className='mb-2 '>{t('transfer.checkVehicle')}</div>
                        <Swiper
                            slidesPerView={2}
                            pagination={true}
                            modules={[Pagination]}
                            spaceBetween={10}
                        >
                            {allCars.map((item, index) => (
                                <SwiperSlide>
                                    <div className={item.id === selectedCarID ? "selectedModelCar" : "modelCarBottom"}>
                                        <button className='customButton w-100' onClick={() => changeCar(item.id)}>
                                            <div className=' vitoCarFlex' align="center">
                                                <LazyLoadImage src={item.image} alt={item.name} className=' vitocar' />

                                                <div className='mb-2'>
                                                    <div>{item.name}</div>
                                                    <div className='text-muted fnt08rem mt-2'><i className="fas fa-user"></i><span className='mx-2'>{item.person} {t('transfer.people')}</span></div>
                                                    <div className='text-muted fnt08rem'><i className="fas fa-suitcase"></i><span className='mx-2'>{item.baggage} {t('transfer.suitcase')}</span></div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </SwiperSlide>
                            ))}

                        </Swiper>
                    </div>

                </div>
                <div className='col-md-8 col-sm-12 col-xs-12 mqP2 paymentModalMQCol ' style={{ paddingLeft: '3rem' }}>
                    <div className='modelSearchFirst text-white p-3 position-relative' style={{ minHeight: '16.2rem' }}>
                        <div className='row mt-2'>
                            <div className='col-md-6 col-sm-12 col-xs-12'>
                                <div className='borderbottom1whiteDashed' style={{ minWidth: '200px' }}>{t('transfer.fromAirHot')}</div>
                                <div className='text-grey fnt08rem mt-1 mqMB1Rem'>{picupLocation.name}</div>
                            </div>
                            <div className='col-md-6 col-sm-12 col-xs-12'>
                                <div className='borderbottom1whiteDashed' style={{ minWidth: '200px' }}>{t('transfer.toAirHot')}</div>
                                <div className='text-grey fnt08rem mt-1 mqMB1Rem'>{returnLocation.name}</div>
                            </div>
                        </div>

                        <div className='pt-4 mt-3 transferRight1'>
                            <div className='borderbottom1whiteDashed mqWauto d-flex justify-content-between pb-2' style={{ minWidth: '200px' }} >
                                <div className='mt-1'>{t('transfer.amount')}</div>
                                <div className='fnt20px'>{prices.total_price} EUR</div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-3' style={{ color: '#00000091' }}>{t('transfer.chooseRoute')} </div>
                    <div className='fnt14px modalForm'>
                        <div className='row'>
                            <div className='col-md-6 col-sm-12 col-xs-12 mt-4'>
                                <div className='mt-1'>
                                    <div>{t('registerForm.name')} </div>
                                    <div className='mt-2'>
                                        <input type='text' className='rezervationinput w-100' placeholder='Thomas' name='name' onChange={handleChange} onFocus={() => handleFocus('name')} />
                                    </div>
                                    <span style={{ color: 'red' }}>{errors.name}</span>
                                </div>
                                <div className='mt-3'>
                                    <div>{t('registerForm.email')}</div>
                                    <div className='mt-2'>
                                        <input type='text' className='rezervationinput w-100' placeholder='Thomasedison@gmail.com' name='email' onChange={handleChange} onFocus={() => handleFocus('email')} />
                                    </div>
                                    <span style={{ color: 'red' }}>{errors.email}</span>
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12 col-xs-12 mt-4'>
                                <div className='mt-1'>
                                    <div>{t('registerForm.surname')} </div>
                                    <div className='mt-2'>
                                        <input type='text' className='rezervationinput w-100' placeholder='Edison' name='surname' onChange={handleChange} onFocus={() => handleFocus('surname')} />
                                    </div>
                                    <span style={{ color: 'red' }}>{errors.surname}</span>
                                </div>

                                <div className='mt-3'>{t('registerForm.birthDate')}</div>
                                <div className='mt-2 position-relative'>

                                    <DatePicker
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={100}
                                        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                                        onFocus={(e) => {
                                            handleFocus('birthdate')
                                            e.target.blur();
                                        }}
                                        selected={formData.birthdate && new Date(formData.birthdate)}
                                        className="modalSelectDate"
                                        onChange={(date) => {
                                            setStartDate(date);
                                            handleBirthDate(date);
                                        }}
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy"
                                        placeholderText={t('validation.selectDate')}
                                        value={formData.birthdate}
                                    />
                                    {!formData.birthdate &&
                                        <div className=" position-absolute top-50 end-0 translate-middle-y me-2 modalCalendar" >
                                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                                        </div>
                                    }

                                </div>
                                <span style={{ color: 'red' }}>{errors.birthdate}</span>

                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>{t('registerForm.mobile')}</div>
                            <div className='col-3'>
                                <Select
                                    className='w-100  mt-1 fnt08rem '
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, "dual_code")}
                                    onFocus={() => handleFocus('dual_code')}
                                    options={geoCodes.data.map((item, index) => ({
                                        value: item.code,
                                        label: item.value,
                                    }))}
                                />
                                <span style={{ color: 'red' }}>{errors.dual_code}</span>
                            </div>
                            <div className='col-9 ps-0'>
                                <input
                                    type='text'
                                    placeholder='555 55 55'
                                    className='w-100 rezervationinput mt-1'
                                    id='phone'
                                    name='phone'
                                    value={formData.phone}
                                    onFocus={() => handleFocus('phone')}
                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ""); }}
                                    onChange={handleChange} />
                                <span style={{ color: 'red' }}>{errors.phone}</span>
                            </div>

                        </div>
                        <div className='mt-1 '>{t('registerForm.country')}</div>
                        <Select
                            className='w-100 mt-2 fnt08rem '
                            onChange={(selectedOption) => handleSelectChange(selectedOption, "country_id")}
                            onFocus={() => handleFocus('country_id')}
                            options={countries.data.map((country, index) => ({
                                value: country.id,
                                label: country.country,
                            }))}
                        />
                        <span style={{ color: 'red' }}>{errors.country_id}</span>
                        <div className='mt-3 fnt14px'>{t('transfer.flightNo')}</div>
                        <div>
                            <input type='text' placeholder={t('transfer.enterFlightNo')} className='w-100 rezervationinput mt-1' name="flight_number" value={formData.flight_number} onChange={handleChange} onFocus={() => handleFocus('flight_number')} />
                        </div>
                        <span style={{ color: 'red' }}>{errors.flight_number}</span>
                        <div className='mt-3 fnt14px'>{t('registerForm.hotelName')}</div>
                        <div>
                            <input type='text' placeholder={t('registerForm.enterHotelName')} className='w-100 rezervationinput mt-1' name="hotel_name" value={formData.hotel_name} onChange={handleChange} />
                        </div>
                        <div className='mt-3'>
                            <div>{t('transfer.passportOrTCNo')}</div>
                            <div className='mt-2'>
                                <input type='text' className='rezervationinput w-100' placeholder='ABA9875413' name='passaport_no' onChange={handleChange} onFocus={() => handleFocus('passaport_no')} />
                            </div>
                            <span style={{ color: 'red' }}>{errors.passaport_no}</span>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-3 d-flex'>
                                <input
                                    type='radio'
                                    name='gender'
                                    value='MALE'
                                    checked={formData.gender === 'MALE'}
                                    onChange={handleOptionChange}
                                />
                                <div className='ms-2'>{t('registerForm.male')}</div>
                            </div>
                            <div className='col-9 d-flex ps-0'>
                                <input
                                    type='radio'
                                    name='gender'
                                    value='WOMAN'
                                    checked={formData.gender === 'WOMAN'}
                                    onChange={handleOptionChange}
                                />
                                <div className='ms-2'>{t('registerForm.female')}</div>
                            </div>
                        </div>
                        <span style={{ color: 'red' }}>{errors.gender}</span>

                        <div>
                            <div className='mt-3'>{t('registerForm.notes')}</div>
                            <div>
                                <textarea className='rezervationinput w-100 mt-2' placeholder='Notes' rows="4" name='comment' onChange={handleChange} onFocus={() => handleFocus('comment')} />
                            </div>
                        </div>
                        <div className='mt-4 '><button className='btn bgblue personalizeBtn text-white w-100 p-2 mt-1' style={{ borderRadius: "60px", fontWeight: "600", fontSize: "18px" }} onClick={handleSubmit}>{t('payNow')}</button></div>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default TransferForm