import React from 'react'
import Header from './header'
import Comment from './comment'
import Footer from './footer'
import BlogCategorySection from './blogCategorySection'
import { useParams } from 'react-router-dom'



const BlogCategoryPage = () => {
  const {slug} = useParams();
  document.title =`${slug} | Cappadocia Visitor`;
  return (
    <div className="container-fluid w-100 h-100 p-0">
    <Header/>
    <BlogCategorySection/>
    <Comment/>
    <Footer/>
    </div>

  )
}

export default BlogCategoryPage