import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux'
import { setProducts, setTour_id } from './api/tourDetailInfo/tourDetailInfoSlice';
import Modal from 'react-bootstrap/Modal';
import authRequest from './api/requests';
import { setPerson, setReservation_date, setTime } from './api/tourDetailInfo/tourDetailInfoSlice';
import { increment } from './api/counter/counterSlice';
import PaymentModal from './paymentModal';
import LoadingPage from './loadingPage';
import { initializeGuests } from './api/formValueState/formValueSlice';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const Personalizeyourtour = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const data = props.props;
    const [isLoading, setIsLoading] = useState(true);
    const personalizeData = useSelector(state => state.tourDetailInfo)
    const { products, tour_id } = useSelector((state) => state.tourDetailInfo);
    const [classStyle, setClassStyle] = useState('col-md-4 col-xs-12 col-sm-12')

    const [responseCart, setResponseCart] = useState([]);
    const [dataCalcPrice, setDataCalcPrice] = useState()
    const handleCheckboxChange = (options) => {
        const uptadetOptions = products.includes(options)
            ? products.filter((opt) => opt !== options)
            : [...products, options];
        dispatch(setProducts(uptadetOptions));
    };

    useEffect(() => {
        if (data) {
            dispatch(setTour_id(data.id))
        }
    }, [data]);

    const [errors, setErrors] = useState({
        person: '',
        tour_date: '',
        time: '',
    });

    const reservationDate = new Date(personalizeData.tour_date + ' ' + personalizeData.time);

    const [showModal, setShowModal] = useState(false);
    const handleShow = async (e) => {
        console.log("asdasd")
        e.preventDefault();
        let formIsValid = true;
        const newErrors = { ...errors };

        if (personalizeData.person.trim() === '') {
            formIsValid = false;
            newErrors.person = t('validation.selectPerson');
        }
        if (personalizeData.time.trim() === '') {
            formIsValid = false;
            newErrors.time = t('validation.selectTime');
        }
        if (personalizeData.tour_date.trim() === '') {
            formIsValid = false;
            newErrors.tour_date = t('validation.selectDate');
        } else {
            const today = new Date();

            if (reservationDate < today) {
                formIsValid = false;
                newErrors.tour_date = t('validation.invalidExpirationDate');
            }
        }
        setErrors(newErrors);

        if (formIsValid) {
            setShowModal(true);
        }

    }
    const handleFocus = (name) => {
        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleClose = () => {
        setShowModal(false)
        dispatch(initializeGuests([]))
    };

    const handeleReservation_date = (date) => {
        const formattedDate = date.toLocaleDateString('en-CA'); // y-m-d formatında tarih dönüşümü
        dispatch(setReservation_date(formattedDate));
    };

    const handleTimeChange = (e) => {
        const newTime = e.target.value;
        console.log(newTime)
        dispatch(setTime(newTime));
    };
    const handlePersonChange = (e) => {
        const newAdult = e.target.value;
        console.log(newAdult)
        dispatch(setPerson(newAdult));
    };

    const handleSelectChange = (selectedOption, selectName) => {
        if (selectName === "personCount") {
            const newAdult = selectedOption.value;
            dispatch(setPerson(newAdult));
        } else if (selectName === "tourTime") {
            const newTime = selectedOption.value;
            dispatch(setTime(newTime));
        }
    };

    useEffect(() => {
        setIsLoading(true);
        if (tour_id) {
            authRequest("/api/v1/tours/calc", "POST", personalizeData, setDataCalcPrice, setIsLoading);
        }
    }, [personalizeData]);

    const options = Array.from({ length: 10 }, (_, i) => ({
        value: i + 1,
        label: i + 1
    }));

    // ******Datepicker*********
    const [startDate, setStartDate] = useState(new Date());

    const enableDates = (data.avaible_dates_calendar).map((item) => {
        // Virgüllerle ayrılmış string tarihleri parçalayarak diziye dönüştürüyoruz
        const startDateParts = item.start_date.split(',').map(part => parseInt(part, 10));
        const endDateParts = item.end_date.split(',').map(part => parseInt(part, 10));

        // Parçalanan dizileri kullanarak yeni Date nesneleri oluşturuyoruz
        const startDate = new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2]);
        const endDate = new Date(endDateParts[0], endDateParts[1] - 1, endDateParts[2]);

        return {
            start: startDate,
            end: endDate
        };
    });

    const enableDatesPrice = (data.avaible_dates).map((item) => ({
        start: new Date(item.start_date),
        end: new Date(item.end_date),
        price: item.total_price,
    }));

    enableDatesPrice.forEach(dateRange => {
        dateRange.start.setDate(dateRange.start.getDate() - 1);
    });

    const renderDayContents = (day, date) => {
        const currentDate = new Date(date);
        const interval = enableDatesPrice.find((interval) => {
            return (
                currentDate >= interval.start && currentDate <= interval.end
            );
        });

        const price = interval && interval.price;

        if (currentDate < new Date() && currentDate.toDateString() !== new Date().toDateString() || !price) {
            return (
                <>
                    <span>{day}</span>
                </>
            );
        }

        return (
            <>
                <span>{day}</span>
                <div className="dateNotes" style={{ fontSize: '0.5rem' }}>
                    €{price.length > 6 ? price.substring(0, price.indexOf(".")) : price}
                </div>
            </>
        );
    };
    // *************************
    // ******localStorage********
    const handleSaveToLocalStorage = async (e) => {
        e.preventDefault();
        let formIsValid = true;
        const newErrors = { ...errors };

        if (personalizeData.person.trim() === '') {
            formIsValid = false;
            newErrors.person = 'Please Select Person';
        }
        if (personalizeData.time.trim() === '') {
            formIsValid = false;
            newErrors.time = 'Please Select Time';
        }
        if (personalizeData.tour_date.trim() === '') {
            formIsValid = false;
            newErrors.tour_date = 'Please Select Reservation Date';
        } else {
            const today = new Date();
            if (reservationDate < today) {
                formIsValid = false;
                newErrors.tour_date = 'Invalid Expiration Reservation Date';
            }
        }
        setErrors(newErrors);

        if (formIsValid) {
            const thisCart = {
                tour_id: data.id,
                ...personalizeData,
            };

            // localStorage'tan allCartList'i al
            let allCartList = JSON.parse(localStorage.getItem("allCartList")) || [];

            // thisCart'ın tour_id'si zaten allCartList içinde var mı kontrol et
            const existingCartItemIndex = allCartList.findIndex(cart => cart.tour_id === thisCart.tour_id);

            // Eğer tour_id zaten ekli ise mevcut öğeyi güncelle, aksi halde ekleyin
            if (existingCartItemIndex !== -1) {
                // Zaten mevcut olan öğeyi güncelle
                allCartList[existingCartItemIndex] = thisCart;
            } else {
                // thisCart'ı allCartList'e ekle
                allCartList.push(thisCart);
            }

            // Güncellenmiş allCartList'i localStorage'a geri yaz
            localStorage.setItem("allCartList", JSON.stringify(allCartList));

            // authRequest ile sunucuya gönder
            const postCartData = {
                allCartList: allCartList
            }
            setIsLoading(true)
            authRequest("/api/v1/carts/add", "POST", postCartData, setResponseCart, setIsLoading);;
        }

    };

    useEffect(() => {
        if (data.get_type.time_usabled !== "ACTIVE") {
            setClassStyle('col-md-6 col-xs-12 col-sm-12')
        }
    }, [data]);

    useEffect(() => {
        if (responseCart.error === false) {
            dispatch(increment())
        }
    }, [responseCart])
    if (isLoading) {
        return (<LoadingPage />)
    };

    return (
        <div className='mediaQueryPadding personalizeMq' style={{ padding: "80px 100px 80px 100px" }}>
            {data.extra_products.length > 0 ? <div className='externalProducts'>
                <div className='borderbottomdashed radioOptions pt-4 pb-4' style={{ display: 'flex', flexWrap: 'wrap' }}>

                    {(data.extra_products).map((item, index) => (
                        <div key={index} className=' pt-2 pb-2 extraWidth' style={{ width: '33%' }} >
                            <input
                                type="checkbox"
                                id={item.id}
                                value={item.options}
                                checked={products.includes(item.id)}
                                onChange={() => handleCheckboxChange(item.id)}
                            />
                            <label className='ms-2 cpointer' htmlFor={item.id}>{item.name} {item.price} EUR</label>
                        </div>
                    ))}

                </div>
                <div className='borderbottomdashed pt-5' align='right' style={{ paddingBottom: '4rem' }}>
                    <div className='borderbottomdashed mqWauto d-flex justify-content-between fnt12rem pt-4 pb-2' style={{ width: '400px' }} >
                        <div>{t('subAmount')}</div>
                        <div className='fw-bold'>{dataCalcPrice.data.total_product_price} EUR</div>
                    </div>
                </div>
            </div>
                :
                ""}

            <div className='row mt-5 '>

                <div className={`mqMT15 ${classStyle}`}>

                    <div className='text-muted'>{data.per_type.label === "Number of People" ? <i className="fa-solid fa-user me-2"></i> : <i className="fa-solid fa-car me-2"></i>}{data.per_type.label}</div>
                    <div>

                        <Select
                            className='w-100  mt-2 fnt08rem '
                            onChange={(selectedOption) => handleSelectChange(selectedOption, "personCount")}
                            onFocus={() => handleFocus('person')}
                            options={Array.from({ length: 10 }, (_, i) => ({
                                value: (i + 1).toString(),
                                label: (i + 1).toString()
                            }))}
                            placeholder={data.per_type.placeholder}
                            defaultValue={personalizeData.person ? { value: personalizeData.person, label: personalizeData.person } : null}
                        />
                    </div>
                    {errors.person && <span className="text-danger">{errors.person}</span>}

                </div>
                <div className={classStyle}>
                    <div className='text-muted'><i className="fas fa-calendar me-2"></i>{t('date')}</div>
                    <div className='position-relative mt-2'>
                        <DatePicker
                            className="w-100 modalSelectDate fnt08rem"
                            selected={personalizeData.tour_date ? new Date(personalizeData.tour_date) : null}
                            includeDateIntervals={enableDates}
                            onFocus={(e) => {
                                e.target.blur();
                                handleFocus('tour_date')
                            }}
                            minDate={new Date()}
                            renderDayContents={renderDayContents}
                            onChange={(date) => {
                                setStartDate(date);
                                handeleReservation_date(date);
                            }}
                            dateFormat="yyyy-MM-dd"
                            value={personalizeData.tour_date}
                            placeholderText='Please Select Date'
                        />
                        {!personalizeData.tour_date &&
                            <div className=" position-absolute top-50 end-0 translate-middle-y me-2 modalCalendar" >
                                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                            </div>
                        }

                    </div>

                    {errors.tour_date && <span className="text-danger">{errors.tour_date}</span>}
                </div>

                {
                    (data.get_type.time_usabled === "ACTIVE") &&
                    <div className={`mqMT15 ${classStyle}`}>
                        <div className='text-muted'><i className="fas fa-clock me-2"></i>{t('time')}</div>
                        <div>


                            <Select
                                className='w-100  mt-2 fnt08rem '
                                onChange={(selectedOption) => handleSelectChange(selectedOption, "tourTime")}
                                onFocus={() => handleFocus('time')}
                                options={(data.avaible_times).map((item, index) => ({
                                    value: item,
                                    label: item
                                }))
                                }
                                placeholder="Please Select Time"
                                defaultValue={personalizeData.time ? { value: personalizeData.time, label: personalizeData.time } : null}
                            />
                        </div>
                        {errors.time && <span className="text-danger">{errors.time}</span>}
                    </div>
                }
            </div>

            {dataCalcPrice.data.disabled ?
                <div className='tourDisabled text-center pt-5 pb-5 text-danger'>{t('notAvailableDate')}</div>
                :

                <div className='tourEnable'>
                    <div className='borderbottomdashed pt-5' align='right' style={{ paddingBottom: '4rem' }}>
                        <div className='borderbottomdashed mqWauto d-flex justify-content-between fnt12rem pt-4 pb-2' style={{ width: '400px' }} >
                            <div>{t('subAmount')}</div>
                            <div className='fw-bold'>{dataCalcPrice.data.total_person_price} EUR</div>
                        </div>
                    </div>
                    <div className='pt-5' align='right' style={{ paddingBottom: '4rem' }}>
                        <div className='borderbottomdashed mqWauto d-flex justify-content-between fnt12rem pt-4 pb-2' style={{ width: '400px' }} >
                            <div className='fnt16rem fw-bold'>{t('totalAmount')}</div>
                            <div className='fw-bold fnt16rem'>{dataCalcPrice.data.total_price} EUR</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'><button className='btn bgorange personalizeBtn text-white w-100 p-2 ' style={{ borderRadius: "60px", fontWeight: "600", fontSize: "18px" }} onClick={handleSaveToLocalStorage} >{t('addToCart')}</button> </div>
                        <div className='col-6'><button className='btn bgblue personalizeBtn text-white w-100 p-2' style={{ borderRadius: "60px", fontWeight: "600", fontSize: "18px" }} onClick={handleShow}>{t('payNow')}</button></div>
                    </div>
                </div>
            }

            <Modal show={showModal} onHide={handleClose} centered dialogClassName="modal-90w paymentmodal" aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header className='border-0' closeButton></Modal.Header>
                <Modal.Body className='pb-5'>
                    <PaymentModal props={data} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Personalizeyourtour